import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    Button,
    Tabs,
    Tab,
    Dialog,
    FormControlLabel,
    InputLabel,
    InputAdornment,
    IconButton,
    OutlinedInput,
    FormControl,
    MenuItem,
    Select,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    CardMedia,
    Popover,
    Checkbox,
    Divider,
    FormHelperText,
    Badge,
    CircularProgress,
    Tooltip
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles, styled } from "@material-ui/core/styles";
import { locationIcon, BlankCheckbox, checkedCheckBox, CalendarIcon } from "./assets";
import CloseIcon from "@material-ui/icons/Close";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import EditIcon from '@material-ui/icons/Edit'
import FilterListIcon from '@material-ui/icons/FilterList';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import SearchIcon from '@material-ui/icons/Search';
import AccessTimeTwoToneIcon from '@material-ui/icons/WatchLater';
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import Map from "./component/Map.web";
import "./NewsAndEvents.web.css"
import { configJSON } from "./NewsAndEventsController";
import AccordionSection from "./newsAndEvents/AccordionSection.web";
import InfiniteScroll from "react-infinite-scroll-component";

// Customizable Area End

import NewsAndEventsController, { Props } from "./NewsAndEventsController";
import CustomModal from "../../../components/src/customModal.web";
import LinkShare from "../../LinkShare/src/LinkShare.web";
import { isImage } from "../../../components/src/AdminConsoleHelper";
import { CustomChip } from "./newsAndEvents/filter.web";
import CheckCondition from "../../../components/src/condition.web";


export default class NewsAndEvents extends NewsAndEventsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    
    ImageFragment = ({ attachment, file, event_type }: { attachment?: any, file: any, event_type: string }) => {
        let imgPartial = attachment?.url || this.parseImages(attachment);

        const isImg = file?.type?.includes('image') || isImage(imgPartial || "")

        return attachment ? (
            <>
                <StyledBadge
                    overlap="rectangular"
                    badgeContent={
                        <Box>
                            <input
                                type="file"
                                style={webStyle.hide}
                                id="edit-icon-badge-file"
                                className="image-contained-class"
                                onChange={(e) => this.handleImageUpload(e, event_type)}
                                data-testid={"modal_image_handle_select"}
                                accept="image/*,video/*"
                            />
                            <label htmlFor="edit-icon-badge-file">
                                <IconButton
                                    data-testid={"modal_image_fragment_select"}
                                    component="span"
                                    aria-label="edit upload picture"
                                    disableRipple={true}
                                    style={webStyle.badgeButton}
                                >
                                    <EditIcon fontSize="small" htmlColor="#fff" />
                                </IconButton>
                            </label>
                        </Box>
                    }
                >
                    <Card variant="outlined" style={webStyle.badgeCardRoot}>
                        { imgPartial && ( isImg ? <CardMedia
                            component="img"
                            image={imgPartial}
                            src={attachment}
                            alt="cover-images"
                        />
                            :
                            <video src={imgPartial} width="100%" height="100%" controls />)
                        }
                    </Card>
                </StyledBadge>
            </>
        ) : (
            <>
                <Box style={webStyle.formImageContainer}>
                    <input
                        accept="image/*,video/*"
                        type="file"
                        className="image-empty-container-class"
                        style={webStyle.hide}
                        id="icon-button-file"
                        onChange={(e) => this.handleImageUpload(e, event_type)}
                        data-testid={"modal_image_handle_select"}
                    />
                    <label htmlFor="icon-button-file" style={{ display: "flex", alignItems: "center" }}>
                        <IconButton aria-label="upload image" component={"span"}>
                            <PhotoCamera style={webStyle.photoCamera} />
                        </IconButton>
                    </label>
                </Box >
            </>
        );
    };

    // Customizable Area End

    render() {

        // Customizable Area Start
        const { navigation, id } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <NavigationMenu navigation={navigation} id={id}>
                    <CustomModal
                        open={!!this.state.deletePopup}
                        onClose={() => this.toggleDeletePopup(null)}
                        confirmHandler={() => this.state.deletePopup && this.deleteEvent(this.state.deletePopup)}
                        confirmTitle="Delete"
                        title="Delete Entry"
                        description="Are you sure. You want to delete entry."
                    />

                    <CustomModal
                        open={!!this.state.errorPopup}
                        onClose={() => this.toggleErrorPopup(null)}
                        title={this.state.popupTitle}
                        description={this.state.errorPopup}
                    />
                    <Grid container spacing={2} className="news_and_events_container">
                        <Grid item md={4} lg={4} sm={12} xs={12}>
                            <Typography style={{
                                fontWeight: 600,
                                fontSize: "2.2vw",
                                lineHeight: '3.3vw',
                                marginBottom: '35px',
                            }} className="news-event-title">{this.state.newsAndEventsTitle}</Typography>
                            <Typography style={{
                                fontWeight: 500,
                                fontSize: '16px',
                                color: '#1C2324',
                                marginBottom: '12px',
                            }}>{this.state.eventsSubtitle}</Typography>
                            {this.state.errorMsg === "" ?
                                <div id="scrollableEventsDiv" style={{ height: '400px', overflow: "auto" }}>
                                <InfiniteScroll
                                        dataLength={this.state.eventsData.length}
                                        next={this.getEvents}
                                        scrollableTarget="scrollableEventsDiv"
                                        hasMore={true}
                                        loader={<></>}
                                    >
                                        {this.state.eventsData?.map((data: any, index: number) => (
                                            <AccordionSection
                                                key={data.id}
                                                data={data?.attributes?.events_news?.data?.filter(({ attributes }: { attributes: { language: string } }) => attributes?.language === "English")?.[0]}
                                                selected={this.state.selectedId}
                                                handleAccordion={(id) => this.handleAccordion(id)}
                                                deleteEvent={(id) => this.toggleDeletePopup(id)}
                                                editSection={() => this.editSection(data.id, configJSON.events)}
                                                handleShareLink={this.handleShareLink}
                                            />
                                        )
                                        )}
                                    </InfiniteScroll>
                                </div>
                                :
                                <Typography style={{ textAlign: 'center', padding: '1rem' }}>No Data Found!</Typography>
                            }
                            <Box style={{ marginTop: '20px', marginBottom: '12px'}}>
                                <Typography style={{
                                    fontWeight: 500,
                                    fontSize: '16px',
                                    color: '#1C2324',
                                }}>{this.state.newsSubtitle}</Typography>
                            </Box>
                            {this.state.errorMsg === "" ?
                                <div id="scrollableNewsDiv" style={{ height: '400px', overflow: "auto" }}>
                                <InfiniteScroll
                                        dataLength={this.state.newsData.length}
                                        next={this.getNews}
                                        scrollableTarget="scrollableNewsDiv"
                                        hasMore={true}
                                        loader={<></>}
                                    >
                                    {this.state.newsData?.map((data: any, index: number) =>
                                    (<AccordionSection
                                        key={data.id}
                                        data={data?.attributes?.events_news?.data?.filter(({ attributes }: { attributes: { language: string } }) => attributes?.language === "English")?.[0]}
                                        selected={this.state.selectedId}
                                        handleAccordion={(id) => this.handleAccordion(id)}
                                        deleteEvent={(id) => this.toggleDeletePopup(id)}
                                        editSection={() => this.editSection(data.id, configJSON.news)}
                                        handleShareLink={this.handleShareLink}
                                    />)
                                    )}
                                    </InfiniteScroll>
                                </div>
                                :
                                <Typography style={{ textAlign: 'center', padding: '1rem' }}>No Data Found!</Typography>}

                        </Grid>
                        <Grid item md={8} lg={8} sm={12} xs={12}>
                            <Box style={{
                                boxShadow: '2px 8px 32px 4px rgba(0, 0, 0, 0.06)',
                                borderRadius: '12px',
                                padding: '1rem',
                                marginBottom: "24px"
                            }}>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography style={{
                                        fontSize: '18px',
                                        fontWeight: 700,
                                        marginBottom: '20px',
                                        color: '#1C2324',
                                        textTransform: "uppercase",
                                    }}>{configJSON.addeventsTitle(this.state.isEdit)}</Typography>
                                    {this.state.isEdit && <IconButton style={webStyle.bgTransparent}  className="cancel-update-icon-class" onClick={() => this.cancelUpdate()} ><CloseIcon /></IconButton>}
                                </Box>
                                <AntTabs
                                    className="ant-tabs-class"
                                    aria-label="ant example"
                                >
                                    <Grid container>
                                        <Grid item xs={4} style={{
                                            borderBottom: this.handleTabEnglishBorder(),
                                            textAlign: "center",
                                        }}>
                                            <AntTab className="ant-tab-class1" onClick={() => this.handleTabChange('English')} selected={this.state.languageTab === "English"} value="English" label="English" />
                                        </Grid>
                                        <Grid item xs={4} style={{
                                            borderBottom: this.handleTabGujaratiBorder(),
                                            textAlign: "center",
                                        }}>
                                            <AntTab className="ant-tab-class2" onClick={() => this.handleTabChange('Gujarati')} selected={this.state.languageTab === "Gujarati"} value="Gujarati" label="Gujarati" />
                                        </Grid>
                                        <Grid item xs={4} style={{
                                            borderBottom: this.handleTabHindiBorder(),
                                            textAlign: "center",
                                        }}>
                                            <AntTab className="ant-tab-class3" onClick={() => this.handleTabChange('Hindi')} selected={this.state.languageTab === "Hindi"} value="Hindi" label="Hindi" />
                                        </Grid>
                                    </Grid>
                                </AntTabs>

                                <Box>
                                    <Typography style={webStyle.lable}>
                                        News/Event
                                    </Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            disableUnderline
                                            disabled={!!this.state.isEdit}
                                            data-testid="tableSelectionMenuTestid"
                                            className="table-select-menuitem"
                                            value={this.state.selectedEvent}
                                            onChange={(e: any) => this.handleEventChange(e)}
                                            variant="outlined"
                                            IconComponent={(props) => (
                                                <ExpandMoreIcon {...props} sx={{ marginRight: "15px" }} />
                                            )}
                                        >
                                            <MenuItem value={"events"}>Events</MenuItem>
                                            <MenuItem value={"news"}>
                                                News
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                    {/* on based of events form change */}
                                    {this.state.selectedEvent === "events" ?
                                        <>
                                            <Typography style={webStyle.lable}>
                                                Event Name
                                            </Typography>
                                            <FormControl fullWidth>
                                                <TextField
                                                    data-testId="eventNameFeild"
                                                    placeholder="Enter name of event"
                                                    type="text"
                                                    className="title-text-field-class"
                                                    name="name"
                                                    variant="outlined"
                                                    color="secondary"
                                                    id="name"
                                                    value={this.handleEventsFormValue('name')}
                                                    helperText={this.handleHelperText('name', 'event')}
                                                    error={this.handleErrorText('name', 'event')}
                                                    onChange={(e) => {
                                                        e?.persist();
                                                        this.handleEventTextFieldChange(e, "name", "event", false)
                                                    }}
                                                />
                                            </FormControl>
                                            <Box style={webStyle.secondaryUploadImageBox}>
                                                <Box component="form" style={webStyle.secondaryRootBox}>
                                                    <Typography
                                                        variant="subtitle2"
                                                        style={webStyle.modalCardContentEditModalTypography}
                                                    >
                                                        Upload Image/Video
                                                    </Typography>
                                                    <FormControl
                                                        fullWidth
                                                        style={webStyle.modalFormControl}
                                                    >
                                                        <InputLabel htmlFor="my-input" />
                                                        {this.ImageFragment({ attachment: this.state.formData.events_news[3].image, file: this.state.formData.events_news[3].image.file, event_type: "event" })}



                                                        <FormHelperText error id="accountId-error">
                                                            {this.handleImageErrorText("event")}
                                                        </FormHelperText>

                                                    </FormControl>
                                                </Box>

                                            </Box >
                                            <Typography style={webStyle.lable}>
                                                Description
                                            </Typography>
                                            <FormControl fullWidth>
                                                <EventField
                                                    data-testId="eventDescription"
                                                    placeholder="Enter description here...."
                                                    id="description"
                                                    className="description-textfield-class"
                                                    type="text"
                                                    value={this.handleEventsFormValue('description')}
                                                    onChange={(e) => {
                                                        e?.persist();
                                                        this.handleEventTextFieldChange(e, "description", "event", false)
                                                    }}
                                                    error={this.handleErrorText('description', 'event')}
                                                    name="description"
                                                    color="secondary"
                                                    multiline
                                                    rows={4}
                                                />
                                                <FormHelperText error id="accountId-error">
                                                    {this.handleHelperText('description', 'event')}
                                                </FormHelperText>
                                            </FormControl>
                                            <Grid container spacing={4}>
                                                <Grid item xs={12} md={6} lg={6}>
                                                    <Typography style={webStyle.lable1}>
                                                        Event Date
                                                    </Typography>
                                                    <FormControl fullWidth>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <KeyboardDatePicker
                                                                required
                                                                className="customDatePicker customPicker1"
                                                                clearable
                                                                data-testid="dateOfBirthAdminTestid-1"
                                                                //need 
                                                                value={this.handleEventsFormValue('event_date')}
                                                                placeholder="DD/MM/YYYY"
                                                                helperText={this.handleHelperText('event_date', 'event')}
                                                                error={this.handleErrorText('event_date', 'event')}
                                                                onChange={(e) => {
                                                                    this.handleEventDateChange(e, "event_date", "event", true)
                                                                }}
                                                                format="DD/MM/yyyy"
                                                                inputVariant="outlined"
                                                                color="secondary"
                                                                name="event_date"

                                                                keyboardIcon={
                                                                    <img src={CalendarIcon} alt="calendarAdornment" style={{ opacity: 0.6 }} />
                                                                }
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={6} lg={6}>
                                                    <Typography style={webStyle.lable1}>
                                                        Event Time
                                                    </Typography>
                                                    <FormControl fullWidth>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <KeyboardTimePicker
                                                                required
                                                                className="customDatePicker customPicker2"
                                                                clearable
                                                                data-testid="time-field-1"
                                                                placeholder="Time"
                                                                id="time-picker"
                                                                name="event_time"
                                                                inputVariant="outlined"
                                                                color="secondary"
                                                                value={this.handleEventsFormValue('event_time')}
                                                                helperText={this.handleHelperText('event_time', 'event')}
                                                                error={this.handleErrorText('event_time', 'event')}
                                                                onChange={(e) => { this.handleEventTimeChange(e, 'event_time', true) }}
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "change time",
                                                                }}
                                                                keyboardIcon={<AccessTimeTwoToneIcon />}
                                                            />

                                                        </MuiPickersUtilsProvider>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Typography style={webStyle.lable}>
                                                Location
                                            </Typography>
                                            <EventField
                                                data-testId="txtInputPassword"
                                                type="text"
                                                placeholder={"Enter location of event"}
                                                fullWidth={true}
                                                value={this.handleEventsLocationFormValue()}
                                                className="password-textfield-class"
                                                error={Boolean(this.handleMapErrorText())}
                                                onChange={(e) => {
                                                    e?.persist();
                                                    this.handleEventTextFieldChange(e, "location", "event", false)
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            data-testId="showPassword"
                                                            className="map-adornment-class"
                                                            aria-label="toggle password visibility"
                                                            edge="end"
                                                            onClick={() => {
                                                                this.setState({ mapDialogOpen: true })
                                                            }}
                                                        >
                                                            <img src={locationIcon} />

                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            <FormHelperText error id="accountId-error">
                                                {this.handleMapErrorText()}
                                            </FormHelperText>
                                            <Dialog open={this.state.mapDialogOpen} className="map-class">
                                                <Box className="map-dialog-title">
                                                    <Box>
                                                        <Typography>
                                                            Select Location from map
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <IconButton
                                                            aria-label="close"
                                                            data-testid="mapModalCloseBtnTestid"
                                                            className="close_btn_map"
                                                            onClick={() => {
                                                                this.closeMapDialog()
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                                {/* handleGetDestinationList,handleMapClick,location */}
                                                <Map handleGetDestinationList={this.handleGetDestinationList} handleMapClick={this.handleMapClick} location={this.state.location} google={this.props.google} />
                                            </Dialog>
                                            <div style={webStyle.baselineAlign}>
                                            <Typography style={webStyle.lable}>
                                                Send to
                                                </Typography>
                                                {
                                                    Boolean(this.state.chips.length) && (
                                                        <Button variant="text" id="clear_all_btn" onClick={() => this.setState({ chips: [] })}>
                                                            Clear All
                                                        </Button>
                                                    )
                                                }

                                            </div>
                                            <Box style={webStyle.sendToContainer}>
                                                <Grid container alignItems="center" style={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                                                    <Grid md={11} >
                                                        {
                                                            !!this.state.chips.length ?
                                                                <div
                                                                    className="chips-container"
                                                                    style={{ maxWidth: '100%', overflowX: 'auto' }}
                                                                >
                                                                    {this.state.chips.map((chip: any, index: number) =>
                                                                        <CustomChip
                                                                            key={`chip-${index}`}
                                                                            label={chip[this.getTypeChip(chip.role_id)]}
                                                                            onDelete={this.handleChipDelete(chip)}
                                                                            roleType={this.getTypeChip(chip.role_id, true)}
                                                                            index={index}
                                                                        />
                                                                    )}
                                                                </div> :
                                                                <div style={webStyle.placeHolderLabel}>
                                                                    <SearchIcon />
                                                                    <div  >Search by name of person, groups</div>
                                                                </div>
                                                        }
                                                    </Grid>
                                                    <Grid md={1} >
                                                        <IconButton
                                                            data-testId="showPassword"
                                                            className="filter-icon-class"
                                                            aria-label="toggle password visibility"
                                                            edge="end"
                                                            onClick={(event: any) => {
                                                                this.setState({
                                                                    isFilterOpen: true,
                                                                    anchorEl: event.currentTarget,
                                                                });
                                                            }}
                                                        >
                                                            <FilterListIcon style={{ color: '#1C2324' }} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Box>

                                            <Popover
                                                className="filterPopover"
                                                open={this.state.isFilterOpen}
                                                anchorEl={this.state.anchorEl}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "right",
                                                }}
                                                onClose={() => this.closeFilter()}
                                            >
                                                <Box
                                                    style={{
                                                        padding: "21px 16px 16px 16px",
                                                        fontFamily: "IBM Plex Sans",
                                                        fontStyle: "normal",
                                                        overflow: "inherit !important",
                                                    }}
                                                >
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography
                                                            style={{
                                                                fontSize: "16px",
                                                                lineHeight: "24px",
                                                                color: "#1C2324",
                                                                fontWeight: 500,
                                                            }}
                                                        >
                                                            Filter
                                                        </Typography>
                                                        <IconButton
                                                            aria-label="close"
                                                            data-testid="filterModalCloseBtnTestid"
                                                            className="close_btn"
                                                            style={{ padding: "0px", paddingRight: "5px" , ...webStyle.bgTransparent }}
                                                            onClick={() => {
                                                                this.closeFilter()
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </Box>
                                                    <Box
                                                        className="checkbox-container"
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            marginTop: "6px",
                                                        }}
                                                    >
                                                        <FormControlLabel
                                                            className="temp-checkbox all-checkbox"
                                                            control={
                                                                <Checkbox
                                                                    color="secondary"
                                                                    disableRipple
                                                                    className="azcheckbox"
                                                                    style={webStyle.bgTransparent}
                                                                    icon={<img src={BlankCheckbox} />}
                                                                    checkedIcon={
                                                                        <img src={checkedCheckBox} className="checkboxImgCustom" />
                                                                    }
                                                                    onChange={(e) => {
                                                                        this.handleAllCheck()
                                                                    }}
                                                                    checked={this.state.sortAll}
                                                                />
                                                            }
                                                            label="All"
                                                            labelPlacement="start"
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Grid container style={{ display: "grid", gridGap: "12px" }}>
                                                            <Grid item container md={12} lg={12}>
                                                                <Grid item xs={3}>
                                                                    <Box>
                                                                        <Typography
                                                                            style={{
                                                                                transform: "translateY(4px)",
                                                                                display: "flex",
                                                                                fontSize: "14px",
                                                                                lineHeight: "22px",
                                                                                color: "#1C2324",
                                                                                fontWeight: 400,
                                                                            }}
                                                                        >
                                                                            State
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    className="accordion-grid"
                                                                    xs={9}
                                                                    style={{
                                                                        position: "relative",
                                                                        zIndex: 10,
                                                                        paddingLeft: "12px",
                                                                    }}
                                                                >
                                                                    <Accordion
                                                                        style={{
                                                                            position: "absolute",
                                                                            backgroundColor: "#FCFAF7",
                                                                            border: "1px solid #F0EBE0",
                                                                            borderRadius: "8px",
                                                                            width: "176px",
                                                                        }}
                                                                        disabled={this.state.isDisabled.state}
                                                                        expanded={this.state.expanded === "panel1"}
                                                                        onChange={this.handleChangePanel("panel1")}
                                                                    >
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                            style={{
                                                                                minHeight: "28px",
                                                                                height: "28px",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                style={{
                                                                                    fontSize: "12px",
                                                                                    lineHeight: "18px",
                                                                                    color: "#1C2324",
                                                                                    fontWeight: 400,
                                                                                    opacity: this.opacityStateCalculator()

                                                                                }}
                                                                            >
                                                                                {this.state.selectedState.name}
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <Box style={{ display: "flex", justifyContent: "center" }}>
                                                                            <Divider
                                                                                style={{
                                                                                    width: "80%",
                                                                                    height: "1px",
                                                                                    background: "#F0EBE0",
                                                                                    borderRadius: "4px",
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                        <AccordionDetails className="accordionDetails">
                                                                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                                                                <TextField
                                                                                    placeholder="Search State"
                                                                                    variant="outlined"
                                                                                    color="secondary"
                                                                                    data-testid="stateTextfieldTestid"
                                                                                    className="state-field-class"
                                                                                    value={this.state.stateInputValue}
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <InputAdornment
                                                                                                position="start"
                                                                                                style={{ background: "#ffffff" }}
                                                                                            >
                                                                                                <SearchIcon
                                                                                                    fontSize="small"
                                                                                                    style={{ opacity: "0.32", color: "#1C2324" }}
                                                                                                />
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                    style={{ marginBottom: "7px" }}
                                                                                    onChange={(e: any) => {
                                                                                        this.handleStateSearch(e);
                                                                                    }}
                                                                                    id="state-textfield"
                                                                                />
                                                                                <Box style={{ maxHeight: "50px", overflowY: "scroll" }}>
                                                                                    <CheckCondition condition={this.state.filteredStates?.length > 0} >
                                                                                        <>
                                                                                            {this.state.filteredStates.map((stateData: any) => (
                                                                                                <Tooltip title={stateData.name}>
                                                                                                    <MenuItem
                                                                                                        key={`state-${stateData.id}`}
                                                                                                        className="state-menu-items"
                                                                                                        value={stateData.name}
                                                                                                        style={{
                                                                                                            fontWeight: 400,
                                                                                                            fontSize: "12px",
                                                                                                            lineHeight: "18px",
                                                                                                            padding: "0px",
                                                                                                            marginBottom: "2px",
                                                                                                            color: "#1C2324",
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            this.handleChangeStateField()
                                                                                                            this.setState({
                                                                                                                selectedState: stateData,
                                                                                                                expanded: false,
                                                                                                            }, () => console.log(this.state.selectedState, "selectedState"));
                                                                                                        }}
                                                                                                    >
                                                                                                        {stateData.name}
                                                                                                    </MenuItem>
                                                                                                </Tooltip>
                                                                                            ))
                                                                                            }
                                                                                        </>
                                                                                    </CheckCondition>

                                                                                </Box>
                                                                            </Box>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container md={12} lg={12} style={{ marginTop: "6px" }}>
                                                                <Grid item xs={3}>
                                                                    <Box>
                                                                        <Typography
                                                                            style={{
                                                                                transform: "translateY(4px)",
                                                                                display: "flex",
                                                                                fontSize: "14px",
                                                                                lineHeight: "22px",
                                                                                color: "#1C2324",
                                                                                fontWeight: 400,
                                                                            }}
                                                                        >
                                                                            District
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={9}
                                                                    className="accordion-grid"
                                                                    style={{
                                                                        position: "relative",
                                                                        zIndex: 9,
                                                                        paddingLeft: "12px",
                                                                    }}
                                                                >
                                                                    <Accordion
                                                                        style={{
                                                                            position: "absolute",
                                                                            backgroundColor: "#FCFAF7",
                                                                            border: "1px solid #F0EBE0",
                                                                            borderRadius: "8px",
                                                                            width: "176px",
                                                                        }}
                                                                        disabled={this.state.isDisabled.district}
                                                                        expanded={
                                                                            this.state.expanded === "panel2"
                                                                        }
                                                                        onChange={this.handleChangePanel("panel2")}
                                                                    >
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                            style={{
                                                                                minHeight: "28px",
                                                                                height: "28px",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                style={{
                                                                                    fontSize: "12px",
                                                                                    lineHeight: "18px",
                                                                                    color: "#1C2324",
                                                                                    fontWeight: 400,
                                                                                    opacity: this.opacityDistrictCalculator()

                                                                                }}
                                                                            >
                                                                                {this.state.selectedDistrict.name}
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <Box style={{ display: "flex", justifyContent: "center" }}>
                                                                            <Divider
                                                                                style={{
                                                                                    width: "80%",
                                                                                    height: "1px",
                                                                                    background: "#F0EBE0",
                                                                                    borderRadius: "4px",
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                        <AccordionDetails className="accordionDetails">
                                                                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                                                                <TextField
                                                                                    placeholder="Search District "
                                                                                    className="district-field-class"
                                                                                    data-testid="districtTextFieldTestid"
                                                                                    variant="outlined"
                                                                                    value={this.state.districtInputValue}
                                                                                    id="district-textfield"
                                                                                    style={{ marginBottom: "7px" }}
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <InputAdornment position="start">
                                                                                                <SearchIcon
                                                                                                    fontSize="small"
                                                                                                    style={{ opacity: "0.32", color: "#1C2324" }}
                                                                                                />
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        this.handleDistrictSearch(e);
                                                                                    }}
                                                                                />
                                                                                <Box style={{ maxHeight: "50px", overflowY: "scroll" }}>
                                                                                    <CheckCondition condition={this.state.filteredDistricts?.length > 0} >
                                                                                        <>
                                                                                            {this.state.filteredDistricts?.map((districtData: any) => (
                                                                                                <Tooltip title={districtData.name}>
                                                                                                    <MenuItem
                                                                                                        key={`distance-${districtData.id}`}
                                                                                                        value={districtData.name}
                                                                                                        className="district-menu-items"
                                                                                                        style={{
                                                                                                            fontWeight: 400,
                                                                                                            fontSize: "12px",
                                                                                                            lineHeight: "18px",
                                                                                                            padding: "0px",
                                                                                                            marginBottom: "2px",
                                                                                                            color: "#1C2324",
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            this.handleChangeDistrictField()
                                                                                                            this.setState({
                                                                                                                selectedDistrict: districtData,
                                                                                                                expanded: false,
                                                                                                            });
                                                                                                        }}
                                                                                                    >
                                                                                                        {districtData.name}
                                                                                                    </MenuItem>
                                                                                                </Tooltip>
                                                                                            ))
                                                                                            }
                                                                                        </>
                                                                                    </CheckCondition>

                                                                                </Box>
                                                                            </Box>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container md={12} lg={12} style={{ marginTop: "6px" }}>
                                                                <Grid item xs={3}>
                                                                    <Box>
                                                                        <Typography
                                                                            style={{
                                                                                transform: "translateY(4px)",
                                                                                display: "flex",
                                                                                fontSize: "14px",
                                                                                lineHeight: "22px",
                                                                                color: "#1C2324",
                                                                                fontWeight: 400,
                                                                            }}
                                                                        >
                                                                            Taluka
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={9}
                                                                    className="accordion-grid"
                                                                    style={{
                                                                        position: "relative",
                                                                        zIndex: 8,
                                                                        paddingLeft: "12px",
                                                                    }}
                                                                >
                                                                    <Accordion
                                                                        style={{
                                                                            position: "absolute",
                                                                            backgroundColor: "#FCFAF7",
                                                                            border: "1px solid #F0EBE0",
                                                                            borderRadius: "8px",
                                                                            width: "176px",
                                                                        }}
                                                                        disabled={this.state.isDisabled.taluka}
                                                                        expanded={this.state.expanded === "panel3"}
                                                                        onChange={this.handleChangePanel("panel3")}
                                                                    >
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                            style={{
                                                                                minHeight: "28px",
                                                                                height: "28px",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                style={{
                                                                                    fontSize: "12px",
                                                                                    lineHeight: "18px",
                                                                                    color: "#1C2324",
                                                                                    fontWeight: 400,
                                                                                    opacity: this.opacityTalukaCalculator()
                                                                                }}
                                                                            >
                                                                                {this.state.selectedTaluka.name}
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <Box style={{ display: "flex", justifyContent: "center" }}>
                                                                            <Divider
                                                                                style={{
                                                                                    width: "80%",
                                                                                    height: "1px",
                                                                                    background: "#F0EBE0",
                                                                                    borderRadius: "4px",
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                        <AccordionDetails className="accordionDetails">
                                                                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                                                                <TextField
                                                                                    placeholder="Search Taluka"
                                                                                    className="taluka-field-class"
                                                                                    data-testid="talukaTextFieldTestid"
                                                                                    variant="outlined"
                                                                                    value={this.state.talukaInputValue}
                                                                                    id="taluka-textfield"
                                                                                    style={{ marginBottom: "7px" }}
                                                                                    onChange={(e: any) => {
                                                                                        this.handleTalukaSearch(e);
                                                                                    }}
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <InputAdornment
                                                                                                position="start"
                                                                                                style={{ background: "#ffffff" }}
                                                                                            >
                                                                                                <SearchIcon
                                                                                                    fontSize="small"
                                                                                                    style={{ opacity: "0.32", color: "#1C2324" }}
                                                                                                />
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                />
                                                                                <Box style={{ maxHeight: "50px", overflowY: "scroll" }}>
                                                                                    <CheckCondition condition={this.state.filteredTalukas?.length > 0} >
                                                                                        <>
                                                                                            {this.state.filteredTalukas.map((talukaData: any) => (
                                                                                                <Tooltip title={talukaData.name}>
                                                                                                    <MenuItem
                                                                                                        key={talukaData.name}
                                                                                                        value={talukaData.name}
                                                                                                        className="taluka-menu-items"
                                                                                                        style={{
                                                                                                            fontWeight: 400,
                                                                                                            fontSize: "12px",
                                                                                                            lineHeight: "18px",
                                                                                                            padding: "0px",
                                                                                                            marginBottom: "2px",
                                                                                                            color: "#1C2324",
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            this.handleChangeTalukaField()
                                                                                                            this.setState({
                                                                                                                selectedTaluka: talukaData,
                                                                                                                expanded: false,
                                                                                                            });
                                                                                                        }}
                                                                                                    >

                                                                                                        {talukaData.name}
                                                                                                    </MenuItem>
                                                                                                </Tooltip>
                                                                                            ))
                                                                                            }
                                                                                        </>
                                                                                    </CheckCondition>

                                                                                </Box>
                                                                            </Box>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container md={12} lg={12} style={{ marginTop: "6px" }}>
                                                                <Grid item xs={3}>
                                                                    <Box>
                                                                        <Typography
                                                                            style={{
                                                                                transform: "translateY(4px)",
                                                                                display: "flex",
                                                                                fontSize: "14px",
                                                                                lineHeight: "22px",
                                                                                color: "#1C2324",
                                                                                fontWeight: 400,
                                                                            }}
                                                                        >
                                                                            Village
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={9}
                                                                    className="accordion-grid"
                                                                    style={{
                                                                        position: "relative",
                                                                        zIndex: 7,
                                                                        paddingLeft: "12px",
                                                                    }}
                                                                >
                                                                    <Accordion
                                                                        style={{
                                                                            position: "absolute",
                                                                            backgroundColor: "#FCFAF7",
                                                                            border: "1px solid #F0EBE0",
                                                                            borderRadius: "8px",
                                                                            width: "176px",
                                                                        }}
                                                                        disabled={this.state.isDisabled.village}
                                                                        expanded={this.state.expanded === "panel4"}
                                                                        onChange={this.handleChangePanel("panel4")}
                                                                    >
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                            style={{
                                                                                minHeight: "28px",
                                                                                height: "28px",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                style={{
                                                                                    fontSize: "12px",
                                                                                    lineHeight: "18px",
                                                                                    color: "#1C2324",
                                                                                    fontWeight: 400,
                                                                                    opacity: this.opacityVillageCalculator()
                                                                                }}
                                                                            >
                                                                                {this.state.selectedVillage.name}
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <Box style={{ display: "flex", justifyContent: "center" }}>
                                                                            <Divider
                                                                                style={{
                                                                                    width: "80%",
                                                                                    height: "1px",
                                                                                    background: "#F0EBE0",
                                                                                    borderRadius: "4px",
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                        <AccordionDetails className="accordionDetails">
                                                                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                                                                <TextField
                                                                                    placeholder="Search Village "
                                                                                    className="village-field-class"
                                                                                    data-testid="villageTextFieldTestid"
                                                                                    id="village-textfield"
                                                                                    value={this.state.villageInputValue}
                                                                                    variant="outlined"
                                                                                    style={{ marginBottom: "7px" }}
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <InputAdornment
                                                                                                position="start"
                                                                                                style={{ background: "#ffffff" }}
                                                                                            >
                                                                                                <SearchIcon
                                                                                                    fontSize="small"
                                                                                                    style={{ opacity: "0.32", color: "#1C2324" }}
                                                                                                />
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                    onChange={(e: any) => {
                                                                                        this.handleVillageSearch(e);
                                                                                    }}
                                                                                />
                                                                                <Box style={{ maxHeight: "50px", overflowY: "scroll" }}>
                                                                                    <CheckCondition condition={this.state.filteredVillages?.length > 0} >
                                                                                        <>
                                                                                            {this.state.filteredVillages.map((villageData: any) => (
                                                                                                <Tooltip title={villageData.name}>
                                                                                                    <MenuItem
                                                                                                        key={villageData.name}
                                                                                                        value={villageData.name}
                                                                                                        className="village-menu-items"
                                                                                                        style={{
                                                                                                            fontWeight: 400,
                                                                                                            fontSize: "12px",
                                                                                                            lineHeight: "18px",
                                                                                                            padding: "0px",
                                                                                                            marginBottom: "2px",
                                                                                                            color: "#1C2324",
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            this.handleChangeVillageField()
                                                                                                            this.setState({
                                                                                                                selectedVillage: villageData,
                                                                                                                expanded: false,
                                                                                                            });
                                                                                                        }}
                                                                                                    >
                                                                                                        {villageData.name}
                                                                                                    </MenuItem>
                                                                                                </Tooltip>
                                                                                            ))
                                                                                            }
                                                                                        </>
                                                                                    </CheckCondition>

                                                                                </Box>
                                                                            </Box>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container md={12} lg={12} style={{ marginTop: "6px" }}>
                                                                <Grid item xs={3}>
                                                                    <Box>
                                                                        <Typography
                                                                            style={{
                                                                                transform: "translateY(4px)",
                                                                                display: "flex",
                                                                                fontSize: "14px",
                                                                                lineHeight: "22px",
                                                                                color: "#1C2324",
                                                                                fontWeight: 400,
                                                                            }}
                                                                        >
                                                                            Groups
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={9}
                                                                    className="accordion-grid"

                                                                    style={{
                                                                        position: "relative",
                                                                        zIndex: 6,
                                                                        paddingLeft: "12px",
                                                                    }}
                                                                >
                                                                    <Accordion
                                                                        style={{
                                                                            position: "absolute",
                                                                            backgroundColor: "#FCFAF7",
                                                                            border: "1px solid #F0EBE0",
                                                                            borderRadius: "8px",
                                                                            width: "176px",
                                                                        }}
                                                                        disabled={this.state.isDisabled.group}
                                                                        expanded={
                                                                            this.state.expanded === "panel5"
                                                                        }
                                                                        onChange={this.handleChangePanel("panel5")}
                                                                    >
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                            style={{
                                                                                minHeight: "28px",
                                                                                height: "28px",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                style={{
                                                                                    fontSize: "12px",
                                                                                    lineHeight: "18px",
                                                                                    color: "#1C2324",
                                                                                    fontWeight: 400,
                                                                                    opacity: this.opacityGroupCalculator()

                                                                                }}
                                                                            >
                                                                                {this.state.selectedGroup.name}
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <Box style={{ display: "flex", justifyContent: "center" }}>
                                                                            <Divider
                                                                                style={{
                                                                                    width: "80%",
                                                                                    height: "1px",
                                                                                    background: "#F0EBE0",
                                                                                    borderRadius: "4px",
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                        <AccordionDetails className="accordionDetails">
                                                                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                                                                <TextField
                                                                                    placeholder="Search Group "
                                                                                    className="group-field-class"
                                                                                    data-testid="groupTextFieldTestid"
                                                                                    variant="outlined"
                                                                                    value={this.state.groupInputValue}
                                                                                    id="group-textfield"
                                                                                    style={{ marginBottom: "7px" }}
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <InputAdornment position="start">
                                                                                                <SearchIcon
                                                                                                    fontSize="small"
                                                                                                    style={{ opacity: "0.32", color: "#1C2324" }}
                                                                                                />
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        this.handleGroupSearch(e);
                                                                                    }}
                                                                                />
                                                                                <Box style={{ maxHeight: "50px", overflowY: "scroll" }}>
                                                                                    <CheckCondition condition={this.state.filteredGroups?.length > 0} >
                                                                                        <>
                                                                                            {this.state.filteredGroups?.map((groupData: any) => (
                                                                                                <Tooltip title={groupData.name}>
                                                                                                    <MenuItem
                                                                                                        key={groupData.name}
                                                                                                        value={groupData.name}
                                                                                                        className="group-menu-items"
                                                                                                        style={{
                                                                                                            fontWeight: 400,
                                                                                                            fontSize: "12px",
                                                                                                            lineHeight: "18px",
                                                                                                            padding: "0px",
                                                                                                            marginBottom: "2px",
                                                                                                            color: "#1C2324",
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            this.handleChangeGroupField()
                                                                                                            this.setState({
                                                                                                                selectedGroup: groupData,
                                                                                                                expanded: false,
                                                                                                            });
                                                                                                        }}
                                                                                                    >
                                                                                                        {groupData.name}
                                                                                                    </MenuItem>
                                                                                                </Tooltip>
                                                                                            ))
                                                                                            }
                                                                                        </>
                                                                                    </CheckCondition>

                                                                                </Box>
                                                                            </Box>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container md={12} lg={12} style={{ marginTop: "6px" }}>
                                                                <Grid item xs={3}>
                                                                    <Box>
                                                                        <Typography
                                                                            style={{
                                                                                transform: "translateY(4px)",
                                                                                display: "flex",
                                                                                fontSize: "14px",
                                                                                lineHeight: "22px",
                                                                                color: "#1C2324",
                                                                                fontWeight: 400,
                                                                            }}
                                                                        >
                                                                            F.E.
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={9}
                                                                    className="accordion-grid"
                                                                    style={{
                                                                        position: "relative",
                                                                        zIndex: 5,
                                                                        paddingLeft: "12px",
                                                                    }}
                                                                >
                                                                    <Accordion
                                                                        style={{
                                                                            position: "absolute",
                                                                            backgroundColor: "#FCFAF7",
                                                                            border: "1px solid #F0EBE0",
                                                                            borderRadius: "8px",
                                                                            width: "176px",
                                                                        }}
                                                                        disabled={this.state.isDisabled.fe}
                                                                        expanded={this.state.expanded === "panel6"}
                                                                        onChange={this.handleChangePanel("panel6")}
                                                                    >
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                            style={{
                                                                                minHeight: "28px",
                                                                                height: "28px",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                style={{
                                                                                    fontSize: "12px",
                                                                                    lineHeight: "18px",
                                                                                    color: "#1C2324",
                                                                                    fontWeight: 400,
                                                                                    opacity: this.opacityFieldExecutiveCalculator()
                                                                                }}
                                                                            >
                                                                                {`${this.state.selectedFieldExecutive.first_name} ${this.state.selectedFieldExecutive.last_name}`}
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <Box style={{ display: "flex", justifyContent: "center" }}>
                                                                            <Divider
                                                                                style={{
                                                                                    width: "80%",
                                                                                    height: "1px",
                                                                                    background: "#F0EBE0",
                                                                                    borderRadius: "4px",
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                        <AccordionDetails className="accordionDetails">
                                                                            <Box style={{ display: "flex", flexDirection: "column", width: '100%' }}>
                                                                                <TextField
                                                                                    placeholder="Search F.E. "
                                                                                    className="fieldExecutive-field-class"
                                                                                    data-testid="fieldExectiveTestId"
                                                                                    id="fieldExective-textfield"
                                                                                    value={this.state.fieldExecutiveInputValue}
                                                                                    variant="outlined"
                                                                                    style={{ marginBottom: "7px" }}
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <InputAdornment
                                                                                                position="start"
                                                                                                style={{ background: "#ffffff" }}
                                                                                            >
                                                                                                <SearchIcon
                                                                                                    fontSize="small"
                                                                                                    style={{ opacity: "0.32", color: "#1C2324" }}
                                                                                                />
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                    onChange={(e: any) => {
                                                                                        this.handleFieldExectiveSearch(e);
                                                                                    }}
                                                                                />
                                                                                <Box style={{ maxHeight: "50px", overflowY: "scroll", width: '100%' }}>
                                                                                    <CheckCondition condition={this.state.filteredFieldExecutive.length > 0} >
                                                                                        <>
                                                                                            {
                                                                                                this.state.filteredFieldExecutive.map(
                                                                                                    (fieldExectiveData: any) => (
                                                                                                        <Tooltip title={`${fieldExectiveData.first_name} ${fieldExectiveData.last_name}`}>
                                                                                                            <MenuItem
                                                                                                                key={`fe-${fieldExectiveData.id}`}
                                                                                                                value={fieldExectiveData.user_name}
                                                                                                                className="fe-menu-items"
                                                                                                                style={{
                                                                                                                    fontWeight: 400,
                                                                                                                    fontSize: "12px",
                                                                                                                    lineHeight: "18px",
                                                                                                                    padding: "0px",
                                                                                                                    marginBottom: "2px",
                                                                                                                    color: "#1C2324",
                                                                                                                }}
                                                                                                                onClick={() => {
                                                                                                                    this.handleChangeFieldExecutiveField()
                                                                                                                    this.setState({
                                                                                                                        expanded: false,
                                                                                                                        selectedFieldExecutive: fieldExectiveData,
                                                                                                                    });
                                                                                                                }}
                                                                                                            >
                                                                                                                {`${fieldExectiveData.first_name} ${fieldExectiveData.last_name}`}
                                                                                                            </MenuItem>
                                                                                                        </Tooltip>
                                                                                                    )
                                                                                                )
                                                                                            }
                                                                                        </>
                                                                                    </CheckCondition>
                                                                                </Box>
                                                                            </Box>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item container md={12} lg={12} style={{ marginTop: "6px" }}>
                                                                <Grid item xs={3}>
                                                                    <Box>
                                                                        <Typography
                                                                            style={{
                                                                                transform: "translateY(4px)",
                                                                                display: "flex",
                                                                                fontSize: "14px",
                                                                                lineHeight: "22px",
                                                                                color: "#1C2324",
                                                                                fontWeight: 400,
                                                                            }}
                                                                        >
                                                                            Farmer
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={9}
                                                                    className="accordion-grid"
                                                                    style={{
                                                                        position: "relative",
                                                                        zIndex: 4,
                                                                        paddingLeft: "12px",
                                                                    }}
                                                                >
                                                                    <Accordion
                                                                        style={{
                                                                            position: "absolute",
                                                                            backgroundColor: "#FCFAF7",
                                                                            border: "1px solid #F0EBE0",
                                                                            borderRadius: "8px",
                                                                            width: "176px",
                                                                        }}
                                                                        disabled={this.state.isDisabled.farmer}
                                                                        expanded={
                                                                            this.state.expanded === "panel7"
                                                                        }
                                                                        onChange={this.handleChangePanel("panel7")}
                                                                    >
                                                                        <AccordionSummary
                                                                            expandIcon={<ExpandMoreIcon />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                            style={{
                                                                                minHeight: "28px",
                                                                                height: "28px",
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                style={{
                                                                                    fontSize: "12px",
                                                                                    lineHeight: "18px",
                                                                                    color: "#1C2324",
                                                                                    fontWeight: 400,
                                                                                    opacity: this.opacityFarmerCalculator()

                                                                                }}
                                                                            >
                                                                                {this.state.selectedFarmer.first_name}
                                                                            </Typography>
                                                                        </AccordionSummary>
                                                                        <Box style={{ display: "flex", justifyContent: "center" }}>
                                                                            <Divider
                                                                                style={{
                                                                                    width: "80%",
                                                                                    height: "1px",
                                                                                    background: "#F0EBE0",
                                                                                    borderRadius: "4px",
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                        <AccordionDetails className="accordionDetails">
                                                                            <Box style={{ display: "flex", flexDirection: "column" }}>
                                                                                <TextField
                                                                                    placeholder="Search Farmer "
                                                                                    className="farmer-field-class"
                                                                                    data-testid="farmerTextFieldTestid"
                                                                                    variant="outlined"
                                                                                    value={this.state.farmerInputValue}
                                                                                    id="farmer-textfield"
                                                                                    style={{ marginBottom: "7px" }}
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <InputAdornment position="start">
                                                                                                <SearchIcon
                                                                                                    fontSize="small"
                                                                                                    style={{ opacity: "0.32", color: "#1C2324" }}
                                                                                                />
                                                                                            </InputAdornment>
                                                                                        ),
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        this.handleFarmerSearch(e);
                                                                                    }}
                                                                                />
                                                                                <Box style={{ maxHeight: "50px", overflowY: "scroll" }}>
                                                                                    <CheckCondition condition={this.state.filteredFarmers.length > 0} >
                                                                                        <>
                                                                                            {this.state.filteredFarmers.map((farmerData: any) => (
                                                                                                <Tooltip title={farmerData.first_name}>
                                                                                                    <MenuItem
                                                                                                        key={`farmers-${farmerData.id}`}
                                                                                                        value={farmerData.first_name}
                                                                                                        className="farmer-menu-items"
                                                                                                        style={{
                                                                                                            fontWeight: 400,
                                                                                                            fontSize: "12px",
                                                                                                            lineHeight: "18px",
                                                                                                            padding: "0px",
                                                                                                            marginBottom: "2px",
                                                                                                            color: "#1C2324",
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            this.handleChangeFarmerField()
                                                                                                            this.setState({
                                                                                                                selectedFarmer: farmerData,
                                                                                                                expanded: false,
                                                                                                            });
                                                                                                        }}
                                                                                                    >
                                                                                                        {farmerData.first_name}
                                                                                                    </MenuItem>
                                                                                                </Tooltip>
                                                                                            ))
                                                                                            }
                                                                                        </>
                                                                                    </CheckCondition>

                                                                                </Box>
                                                                            </Box>
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                container
                                                                md={12}
                                                                lg={12}
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    flexFlow: "row",
                                                                    marginTop: "10px",
                                                                }}
                                                            >
                                                                <Button
                                                                    onClick={(e: any) => {
                                                                        this.handleSubmitAccordion();
                                                                        this.setState({ isFilterOpen: false })
                                                                    }}
                                                                    className="submit_accordion_btn"
                                                                    variant="contained"
                                                                    style={{
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        lineHeight: "22px",
                                                                        textTransform: "uppercase",
                                                                        background: "#DF5200",
                                                                        padding: "5px 40px",
                                                                        borderRadius: "16px",
                                                                        color: "#FFFFFF",
                                                                    }}
                                                                >
                                                                    Save
                                                                </Button>
                                                                <Button
                                                                    style={{
                                                                        fontWeight: 500,
                                                                        fontSize: "14px",
                                                                        lineHeight: "22px",
                                                                        textTransform: "uppercase",
                                                                        border: "1px solid #DF5200",
                                                                        borderRadius: "16px",
                                                                        background: "#FFFFF",
                                                                        padding: "5px 40px",
                                                                        marginLeft: "12px",
                                                                        color: "#1C2324",
                                                                    }}
                                                                    variant="outlined"
                                                                    id="clear_filter"
                                                                    onClick={() => this.handleClear(true)}
                                                                >
                                                                    Clear
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </Popover>
                                        </>
                                        : <>
                                            <Typography style={webStyle.lable}>
                                                Title
                                            </Typography>
                                            <FormControl fullWidth>
                                                <TextField
                                                    data-testId="eventNameFeild"
                                                    //   style={webStyle.customInputField}
                                                    className="title-field-class"
                                                    placeholder="Enter title"
                                                    id="name"
                                                    type="text"
                                                    name="firstnameFarmer"
                                                    variant="outlined"
                                                    color="secondary"
                                                    value={this.handleNewsFormValue('name')}
                                                    helperText={this.handleHelperText('name', 'news')}
                                                    error={this.handleErrorText('name', 'news')}
                                                    onChange={(e) => {
                                                        e?.persist();
                                                        this.handleEventTextFieldChange(e, "name", "news", false)
                                                    }}
                                                />
                                            </FormControl>

                                            <Box style={webStyle.secondaryUploadImageBox}>
                                                <Box component="form" style={webStyle.secondaryRootBox}>
                                                    <Typography
                                                        variant="subtitle2"
                                                        style={webStyle.modalCardContentEditModalTypography}
                                                    >
                                                        Upload Image/Video
                                                    </Typography>
                                                    <FormControl
                                                        fullWidth
                                                        style={webStyle.modalFormControl}
                                                    >
                                                        <InputLabel htmlFor="my-input" />
                                                        {this.ImageFragment({ attachment: this.state.formData.events_news[0].image, file: this.state.formData.events_news[0].image?.file, event_type: "news" })}



                                                        <FormHelperText error id="accountId-error">
                                                            {this.handleImageErrorText("news")}
                                                        </FormHelperText>

                                                    </FormControl>
                                                </Box>

                                            </Box >

                                            <Typography style={webStyle.lable}>
                                                Description
                                            </Typography>
                                            <FormControl fullWidth >
                                                <EventField
                                                    data-testId="eventDescription"
                                                    value={this.handleNewsFormValue('description')}
                                                    id="description"
                                                    onChange={(e) => {
                                                        e?.persist();
                                                        this.handleEventTextFieldChange(e, "description", "news", false)
                                                    }}
                                                    className="description-news-textfield-class"
                                                    error={this.handleErrorText('description', 'news')}
                                                    placeholder="Enter description here...."
                                                    type="text"
                                                    name="firstnameFarmer"
                                                    color="secondary"
                                                    multiline
                                                    rows={11}
                                                />
                                            </FormControl>
                                            <FormHelperText error id="accountId-error">
                                                {this.handleHelperText('description', 'news')}
                                            </FormHelperText>
                                            <Grid container spacing={4}>
                                                <Grid item md={6} lg={6}>
                                                    <Typography style={webStyle.lable1}>
                                                        Written by
                                                    </Typography>
                                                    <FormControl fullWidth>
                                                        <EventField
                                                            data-testId="writtenByFeild"
                                                            value={this.handleNewsFormValue('written_by')}
                                                            onChange={(e) => {
                                                                e?.persist();
                                                                this.handleEventTextFieldChange(e, "written_by", "news", false)
                                                            }}
                                                            error={this.handleErrorText('written_by', 'news')}
                                                            //   style={webStyle.customInputField}
                                                            placeholder="Enter name of author"
                                                            type="text"
                                                            name="authorName"
                                                            color="secondary"
                                                        />
                                                        <FormHelperText error id="accountId-error">
                                                            {this.handleHelperText('written_by', 'news')}
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item md={6} lg={6}>
                                                    <Typography style={webStyle.lable1}>
                                                        Date
                                                    </Typography>

                                                    <FormControl fullWidth>
                                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                                            <KeyboardDatePicker
                                                                required
                                                                className="customDatePicker"
                                                                clearable
                                                                data-testid="dateOfBirthAdminTestid-2"
                                                                //need 
                                                                value={this.handleNewsFormValue('event_date')}
                                                                onChange={(e) => {

                                                                    this.handleEventDateChange(e, "event_date", "news", true)
                                                                }}
                                                                placeholder="DD/MM/YYYY"
                                                                error={this.handleErrorText('event_date', 'news')}
                                                                helperText={this.handleHelperText('event_date', 'news')}
                                                                format="DD/MM/yyyy"
                                                                inputVariant="outlined"
                                                                color="secondary"
                                                                keyboardIcon={
                                                                    <img src={CalendarIcon} alt="calendarAdornment" style={{ opacity: 0.6 }} />

                                                                }
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </>}
                                </Box>

                                <Button fullWidth
                                    style={{
                                        background: '#DF5200',
                                        borderRadius: '28px',
                                        color: '#fff',
                                        fontSize: '18px',
                                        lineHeight: '28px',
                                        fontWeight: 600,
                                        fontFamily: 'IBM Plex Sans',
                                        marginTop: '30px',
                                    }}
                                    className="button-add"
                                    type="button"

                                    disabled={this.state.isLoading}
                                    onClick={() => {
                                        this.handleSubmitNewsEventsForm()
                                    }}
                                >
                                    { this.state.isEdit ? "Update" : "Add"} {this.state.isLoading && <CircularProgress style={{ color: "#FFF", width: "18px", height: "18px", marginLeft: "10px" }} />}
                                </Button>

                            </Box>
                        </Grid>
                    </Grid>
                </NavigationMenu>
                {this.state.isShare && <LinkShare isShare={this.state.isShare}
                    handleShareLink={this.handleShareLink} linkShare={this.state.linkShare}
                    messageHeading={this.state.messageHeading} />}
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#DF5200",
            contrastText: "#fff"
        },
        secondary: {
            main: "#DF5200",
            contrastText: "#fff",
        },

    },
    typography: {
        fontFamily: 'IBM Plex Sans',
        h6: {
            fontWeight: 500
        },
        subtitle1: {
            margin: "20px 0px"
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    }
});

const StyledBadge = styled(Badge)(() => ({
    "& .MuiBadge-badge": {
        right: "10%",
        top: "15%",
        // borderRadius: "2px",
        width: "25px",
        height: "20px",
        padding: ".8rem",
    },
}));


const EventField = withStyles({
    root: {
        background: "#FCFAF7",
        border: "1px solid #F0EBE0",
        borderRadius: "12px",
        fontFamily: "IBM Plex Sans",
        fontWeight: 400,
        padding: '0px',
        fontSize: "16px",
        lineHeight: "24px",
        "& .Mui:focused": {
            borderColor: "1px solid #1C2324",
        },
    },
})(OutlinedInput);

const AntTabs = withStyles({
    root: {
        textTransform: 'capitalize',
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#DF5200',
        textTransform: 'none',
        '& > span': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: 'black',
        },
    },
})(Tabs);
const AntTab = withStyles({
    root: {
        '&:hover': {
            color: '#DF5200',
            opacity: 1,
        },
        '&$selected': {
            color: '#DF5200',
        },
        '&:focus': {
            color: '#DF5200',
        },

    },
    selected: {
        color: '#DF5200',
    },
})(Tab);

const webStyle = {
    noResult: {
        fontSize: '12px',
    },
    lable: {
        fontSize: '14px',
        color: '#1C2324',
        opacity: 0.64,
        lineHeight: '22px',
        marginBottom: '8px',
        marginTop: '16px',
    },
    hide: {
        display: "none"
    },
    badgeButton: {
        backgroundColor: "rgba(28, 35, 36, .56)",
        padding: "0.375rem",
    },
    badgeCardRoot: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#FCFAF7",
        border: "1px solid #F0EBE0",
        borderRadius: "0.75rem",
        height: '200px'
    },
    formImageContainer: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#FCFAF7",
        border: "1px solid #F0EBE0",
        borderRadius: "0.75rem",
        width: "100%",
        height: "200px"
    },
    photoCamera: {
        width: 60,
        height: 60,
        color: "#1C2324",
        opacity: .5
    },
    modalFormControl: {
        height: "100%"
    } as React.CSSProperties,
    modalCardContentEditModalTypography: {
        paddingBlockEnd: 1,
        fontSize: ".875rem",
        marginTop: "16px",
        marginBottom: "8px",
        fontWeight: 400,
        color: "#1C2324",
        opacity: 0.64
    } as React.CSSProperties,
    secondaryRootBox: { width: "100%" },
    lable1: {
        fontSize: '14px',
        color: '#1C2324',
        opacity: 0.64,
        lineHeight: '22px',
        marginBottom: '8px',
        marginTop: '16px',
    },
    shareText: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '1.5rem',
        color: '#006A77',
    },
    baselineAlign:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    bgTransparent: { background: 'transparent' },
    secondaryUploadImageBox: { display: 'flex', gap: "1rem", },
    sendToContainer: {
        padding: '0px 2px 0px 17px',
        border: '1px solid #f0ebe0',
        borderRadius: '12px',
        background: '#fcfaf7',
        color: '#aaabaa'
    },
    placeHolderLabel: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px'
    }
};
// Customizable Area End
