import React from "react";
import {Box,Typography,Button} from "@material-ui/core";
import {Link} from "react-router-dom"
const PageNotFound: React.FC = () => {

	return (
		<Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Typography variant="h1" style={{fontFamily:"IBM Plex Sans"}}>
        404
      </Typography>
      <Typography variant="h6" style={{fontFamily:"IBM Plex Sans",textAlign:"center"}}>
        The page you’re looking for doesn’t exist.
      </Typography>
      <Link to="/">
      <Button style={{marginTop:"10px",background:"#DF5200",color:"#fff"}}  variant="contained">Back Home</Button>
      </Link>
    </Box>
	);
}

export default PageNotFound;