import React from "react";

import {
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Box } from "@material-ui/core";
import moment from "moment";

const theme = createTheme({
  spacing: 4,
  typography: {
    h4: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "10px", 
      textTransform: 'uppercase'  
    },
    subtitle2: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "24px",
      padding: "5px 16px"
    },
    body1: {
      fontSize: "12px",
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: "18px",
      color: "#1C2324",
      padding: "2px 16px"
    },
    body2: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#1C2324",
      padding: "2px 16px",
      marginTop:'10px',
      paddingBottom:'10px'
    },
    fontFamily: "IBM Plex Sans",
  },
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#DF5200",
      contrastText: "#fff",
    },
  },
});


interface MaterrapediaLibraryContent {
  handleShareLinkPopup: (linkType?: string,linkId?:any,accountId?:any,type?:any) => void;
  isLinkPopup: boolean;
  librariesObj: any
}

const MaterrapediaLibraryChatContent = (props: MaterrapediaLibraryContent) => {
  const { handleShareLinkPopup, isLinkPopup,librariesObj } = props
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={isLinkPopup}
        maxWidth={"md"}
        PaperProps={{
          style: { borderRadius: 12, width: "528px"},
        }}
      >
         <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <DialogTitle className="modalDialogTitle">
                <Typography variant="subtitle1">
                  {"Library"}
                </Typography>
              </DialogTitle>
            </div>
            <div style={{ marginRight: 20, color: '#1C2324' }}>
              <IconButton
                aria-label="close"
                data-testid="modalCloseBtnTestid"
                 onClick={(e)=>{
                const linkType = 'materrapedialibraries'
                handleShareLinkPopup(linkType)
              }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        <DialogContent dividers className="modalBodyWrapper">
          <Box style={{ width: '480px', borderRadius: '12px', boxSizing: 'border-box', background:'#F0EBE0',marginLeft:'22px', marginBottom:'15px' }}>
           <div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
           <Typography variant="subtitle2"> 
             {librariesObj?.topic}
            </Typography>
            <Typography variant="body1"> 
            {moment(librariesObj?.updated_at).format('D MMMM YYYY')}
            </Typography>
           </div>
            <Box>
            {librariesObj?.image_or_video === 'Image' &&
              <img src={librariesObj?.image?.url}
                alt=""
                style={{ height: '150px', width: '448px',borderRadius: '6px', boxSizing: 'border-box', marginTop:'5px',marginLeft: '17px' }} />}
            {librariesObj?.image_or_video === "Video" && 
            <video src={librariesObj?.image?.url} style={{ objectFit: "cover", height: '150px', width: '448px',borderRadius: '6px', boxSizing: 'border-box', marginTop:'5px',marginLeft: '17px' }} controls />}
            </Box>
            <Typography variant="body2">
            {librariesObj?.description}
           </Typography>
          </Box>         
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  )
}


// Customizable Area Start

// Customizable Area End

export default MaterrapediaLibraryChatContent;