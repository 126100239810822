import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  Tabs,
  Tab,
  FormControl,
  Select,
  TextField,
  MenuItem,
  FormHelperText,
  InputLabel,
  Badge,
  IconButton,
  Card,
  CardMedia,
  OutlinedInput,
  Button,
  Popover,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  Divider,
  AccordionDetails,
  InputAdornment,
  Tooltip,
  CircularProgress,
  Chip,
  Avatar
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { isImage } from "../../../components/src/AdminConsoleHelper";
import {
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import {
  createTheme,
  ThemeProvider,
  withStyles,
  styled,
  makeStyles
} from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import {
  ExpandMore,
  Edit,
  PhotoCamera,
  FilterList,
  Search,
  Close,
  ExpandLess,
  Share, 
  Delete,
  Visibility,
  PlayArrow
} from "@material-ui/icons";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import CheckCondition from "../../../components/src/condition.web";
import { blankCheckbox, checkedCheckBox } from "./assets";
import moment from "moment";
import CustomModal from "../../../components/src/customModal.web";
import LinkShare from "../../LinkShare/src/LinkShare.web";
import NotificationChatContent from "../../../components/src/NotificationChatContent";
import InfiniteScroll from "react-infinite-scroll-component";


const useStyles = makeStyles({
  playVideo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    margin: 0,
    color: "white",
    height: "32px",
    width: "32px",
    borderRadius: "50%",
    background: "rgb(28 35 36 / 56%)",
    textAlign: "center"
  },
  videoContainer:{
    position: "relative"
  },
    customChip:{
        color: '#1C2324',
        fontSize: '16px',
        fontWeight: 400,
        backgroundColor: '#F0EBE0',
        marginTop: '5px',
        marginRight: '7px',
        marginBottom: '5px'
    },
    roleType:{
        marginLeft: '5px',
        fontSize: '12px',
        color: 'gray'
    },
    root: {
          display: "flex",
          padding: ".5rem",
          background: "#F8F6F1",
          borderRadius: "12px",
          marginTop: '15px',
          position: "relative",
          transition: "all 2s",
          flexDirection:  "column",
        },
        cardContainer :{
            display: "flex",
            flexDirection:  "row",
            gap: "10px",
        },
        container: {
          flexGrow: (isOpen) => (isOpen ? 0 : 0.1),
        },
        notImgContainer:{
          display: 'flex',
        justifyContent:'center', 
        alignItems:'center', 
        border: '1px solid rgb(240, 235, 224)',
        borderRadius: '0.75rem',
        gap: '10px',
        width: (isOpen) => isOpen ? "48px":"82px",
          height: (isOpen) => isOpen ? "48px": "82px",
        },
        boxContainer: {
          width: "100%",
          padding: (isOpen) => (isOpen ? "0 0px" : "0 6px"),
        },
        img: {
          borderRadius: '6px  0px  0px  6px',
          transition: "all 0.2s",
          height: (isOpen) => isOpen ? "48px": "98px",
          objectFit: 'cover',
        },
        between: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: 'center'
        },
        fontStyle: {
          fontStyle: "IBM Plex Sans",
          fontWeight: 500,
          fontSize: "16px",
          color: "#1C2324",
          margin: (isOpen) => (isOpen ? "4px 0px" : "0px"),
        },
        dateContainer: {
          color: "#006A77",
          fontSize: "14px",
          padding: ".4rem 0",
        },
        shareBtn: {
          color: "#006A77",
          fontSize: "18px",
        },
        editBtn: { color: "#EB8F06", fontSize: "18px",cursor: "pointer" },
        deleteBtn: { color: "#B3261E", fontSize: "18px",cursor: "pointer"},
        expandBtn: {
          position: "absolute",
          right: "10px",
          top: "10px" ,
        },
        location: {
          fontFamily: "IBM Plex Sans",
          fontWeight: 400,
          fontSize: "14px",
          color: "rgba(28, 35, 36, 0.8)",
          width: '100%',
          marginBottom:'8px',
        },
  
        ellipsis: {
          overflow: "hidden",
          lineClamp: 1,
          boxOrient: "vertical",
          maxWidth: "80%",
          display: "-webkit-inline-box",
        },
        description:{
            display: "-webkit-box", 
            "-webkit-line-clamp": (isOpen) => isOpen ? "inherit": "2",
            "-webkit-box-orient": "vertical" as const,  
            overflow: "hidden",
            fontSize: '12px',
            color :"rgba(28, 35, 36, 0.64)",
            maxHeight: (isOpen) =>  isOpen ? 'none' : '40px',
            height: (isOpen) =>  isOpen ? 'auto' : '40px',
            wordBreak: 'break-all',
        },
        gap15:{
            gap : "15px"
        },
        notificationType:{
            fontSize: "12px"
        },
        timeSection:{
            fontSize: "10px",
            color : "rgba(28, 35, 36, 0.64)"
        },
        m10:{
            marginTop : '10px',
        }     
  });
export const CustomChip = (props: any) => {
    const classes = useStyles();
    const { label, roleType, index } = props
    const colors =  ["#6a7d31", "#EB8F06", "#108b88"]
    const nameArray = label?.split(' ');
    const tag = nameArray && (nameArray?.length > 2 ? `${nameArray[0][0]} ${nameArray[1][0]}` : `${nameArray[0][0]}`);
    return (
        <Chip
            className={classes.customChip}
            {...props}
            avatar={
                <Avatar className='autocompleteAvatart' style={{ textTransform: "uppercase", width: "30px", height: "30px", marginLeft: "2px", color: "#fff", background: colors[(index + 1) % colors.length] }}>
                    {tag}
                </Avatar>
            }
            label={
                <div >
                    <span>{label}</span>
                    <span className={classes.roleType} >{roleType}</span>
                </div>
            }
            deleteIcon={<Close />}
        />
    );
};
const colorCard = (type : string) => {
    if(type?.toLowerCase().includes('alert')){
      return "#B3261E"
    }
    if(type?.toLowerCase().includes('chat')){
      return "#108B88"
    }
    return "#EB8F06"
 } 
  
  export const AccordionSection = (props: any) => {
    const { data, selected, handleAccordion , editSection, type, deleteHandler, customNotification,handleShareLink,handleShowNotificationPopup} = props;

    const expandTab = data?.id === selected;  
    const classes = useStyles(expandTab);
    const [isPlaying, setIsPlay] =  React.useState(false)
    const videoRef: any = React.useRef();

    const playVideo = () => {
      if (isPlaying) {
        videoRef.current?.pause();
        setIsPlay(false);
      } else {
        setIsPlay(true);
        videoRef.current?.play();
      }
    }
  
    if(!data){
      return null;
    }

    const cardData =  customNotification  &&   data.attributes?.language_notifications.find((item: any) => item.attributes?.language === "English");

    const title = customNotification ? cardData?.attributes.title : data.attributes.remarks_english
    const description = customNotification ? cardData?.attributes.description :  data.attributes.description_english
    const notifiytype = customNotification ? data.attributes?.notification_type.attributes.name  : data.attributes.notify_type
  
    return (
      <Box className={classes.root} key={data.id}>
        <Box className={classes.cardContainer}>
        
          {
            !data.attributes?.image?.url &&
            <Box className={classes.notImgContainer}>
                <PhotoCamera  style={{opacity: 0.5}}/>
            </Box>
          }
          
          {data.attributes?.image?.url &&
            <Box className={classes.container}>
              {(!isImage(data.attributes.image.url) ?
              <div className={classes.videoContainer} id="videoContainer" onClick={() => playVideo && playVideo()} >
                <video src={data.attributes.image.url} ref={videoRef} width="100%" height="100%"  className={classes.img} controls={isPlaying} />
                { !isPlaying && <PlayArrow id="play-arrow" className={classes.playVideo} onClick={() => playVideo()}/>}
                </div>
                : <img
                  src={data.attributes.image.url}
                  alt="No image"
                  className={classes.img}
                />)}
            </Box>
          }
        
        <Box className={classes.boxContainer}>
          <Box className={classes.between}>
            <Tooltip title={title}> 
            <Typography
              className={`${classes.fontStyle} ${classes.ellipsis}`}
            >
             {title} 
            </Typography>
            </Tooltip>
            {expandTab ? (
              <ExpandLess
                className={`${classes.expandBtn}`}
                id="expandMore"   
                onClick={() => handleAccordion(data.id)}
              />
            ) : (
              <ExpandMore
                className={classes.expandBtn}
                id="expandLess"
                onClick={() => handleAccordion(data.id)}
              />
            )}
          </Box>
       { !expandTab &&   <Typography
              className={classes.description}
            >
              {description}
            </Typography>}
  
          <Box className={classes.between}>
            <Typography
              className={classes.notificationType}
              style={{color: colorCard(notifiytype)}}
            >
               {notifiytype?.toUpperCase()}
            </Typography>

           { !expandTab && <Typography
              className={classes.timeSection}
            >
                <i>{moment(data.attributes?.created_at).fromNow()}</i>
            </Typography>}
          </Box>
  
        </Box>
        </Box>
        { expandTab &&  
        <> <Typography
              className={`${classes.description} ${classes.m10}`}
            >
              {description }
            </Typography>

          <Box className={`${classes.between} ${classes.m10}`}>
            <Typography className={classes.timeSection}><i>{moment(data.attributes.created_at).fromNow()}</i></Typography>
            <Box className={`${classes.between} ${classes.gap15}`}>
              <Share className={classes.shareBtn} style={{ cursor: 'pointer' }} id="share-btn" onClick={() => handleShareLink(data)} />
              {type === "sent" && <Edit className={classes.editBtn} onClick={() => editSection()} id="edit-btn" />}
              <Visibility className={classes.editBtn} id="preview-btn" onClick={() =>  handleShowNotificationPopup('notification', data?.id, data, data?.type)}
              />
              {customNotification && type === "sent" && <Delete
                className={classes.deleteBtn}
                onClick={() => deleteHandler()}
                id="delete-btn"
              />}
      </Box>
    </Box>
 
            </>
            }
      </Box>
    );
  };

// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  ImageFragment = ({
    attachment,
    isImage,
    error,
    setData,
  }: {
    attachment?: any;
    isImage: boolean;
    error?: string | false;
    setData: (value: any) => void;
  }) => {
    let imgPartial = attachment || null;

    return attachment ? (
      <>
        <StyledBadge
          overlap="rectangular"
          badgeContent={
            <Box>
              <input
                type="file"
                style={webStyle.hide}
                id="edit-icon-badge-file"
                className="image-contained-class"
                onChange={(event) => setData(event.target?.files?.[0] || "")}
                data-testid={"modal_image_handle_select"}
                accept="image/*,video/*"
              />
              <label htmlFor="edit-icon-badge-file">
                <IconButton
                  data-testid={"modal_image_fragment_select"}
                  component="span"
                  aria-label="edit upload picture"
                  disableRipple={true}
                  style={webStyle.badgeButton}
                >
                  <Edit fontSize="small" htmlColor="#fff" />
                </IconButton>
              </label>
            </Box>
          }
        >
          <Card variant="outlined" style={webStyle.badgeCardRoot}>
            {isImage ? (
              <CardMedia
                component="img"
                image={typeof (imgPartial) === "string" ? imgPartial : URL.createObjectURL(imgPartial)}
                alt="cover-images"
              />
            ) : (
              <video src={imgPartial} width="100%" height="100%" controls />
            )}
          </Card>
        </StyledBadge>
      </>
    ) : (
      <>
        <Box
          style={{
            ...webStyle.formImageContainer,
            borderColor: error ? "#ff0000" : "#f0ebe0",
          }}
        >
          <input
            accept="image/*,video/*"
            type="file"
            className="image-empty-container-class"
            style={webStyle.hide}
            id="icon-button-file"
            onChange={(event) => setData(event.target?.files?.[0] || "")}
            data-testid={"modal_image_handle_select"}
          />
          <label
            htmlFor="icon-button-file"
            style={{ display: "flex", alignItems: "center" }}
          >
            <IconButton aria-label="upload image" component={"span"}>
              <PhotoCamera style={webStyle.photoCamera} />
            </IconButton>
          </label>
        </Box>
      </>
    );
  };

 filterSection =  (label: string, variable: string ,  currentState: any, list: any, zIndex: number ) => (
  <Grid item container md={12} lg={12}>
                                  <Grid item xs={3}>
                                    <Box>
                                      <Typography
                                        style={{
                                          transform: "translateY(4px)",
                                          display: "flex",
                                          fontSize: "14px",
                                          lineHeight: "22px",
                                          color: "#1C2324",
                                          fontWeight: 400,
                                        }}
                                      >
                                        {label}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    className="accordion-grid"
                                    xs={9}
                                    style={{
                                      position: "relative",
                                      zIndex: zIndex,
                                      paddingLeft: "12px",
                                    }}
                                  >
                                    <Accordion
                                      style={{
                                        position: "absolute",
                                        backgroundColor: "#FCFAF7",
                                        border: "1px solid #F0EBE0",
                                        borderRadius: "8px",
                                        width: "176px",
                                      }}
                                      disabled={this.checkDisabled(label)}
                                      expanded={this.state.expanded === label.toLowerCase()}
                                      onChange={() =>
                                        this.handleChangePanel(label.toLowerCase())
                                      }
                                      id={`${label.toLowerCase()}_accordion`}
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`${label.toLowerCase()}-content`}
                                        id={`${label}-header`}
                                        style={{
                                          minHeight: "28px",
                                          height: "28px",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            fontSize: "12px",
                                            lineHeight: "18px",
                                            color: "#1C2324",
                                            fontWeight: 400,
                                            opacity: this.opacityCalculator(
                                              currentState.name
                                            ),
                                          }}
                                        >
                                          {currentState.name}
                                        </Typography>
                                      </AccordionSummary>
                                      <Box
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Divider
                                          style={{
                                            width: "80%",
                                            height: "1px",
                                            background: "#F0EBE0",
                                            borderRadius: "4px",
                                          }}
                                        />
                                      </Box>
                                      <AccordionDetails className="accordionDetails">
                                        <Box
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <TextField
                                            placeholder="Search State"
                                            variant="outlined"
                                            color="secondary"
                                            data-testid="stateTextfieldTestid"
                                            className="state-field-class"
                                            value={this.state.inputField}
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment
                                                  position="start"
                                                  style={{
                                                    background: "#ffffff",
                                                  }}
                                                >
                                                  <Search
                                                    fontSize="small"
                                                    style={{
                                                      opacity: "0.32",
                                                      color: "#1C2324",
                                                    }}
                                                  />
                                                </InputAdornment>
                                              ),
                                            }}
                                            style={{ marginBottom: "7px" }}
                                            onChange={(event) =>
                                              this.setState({
                                                inputField: event.target.value,
                                              })
                                            }
                                            id="state-textfield"
                                          />
                                          <Box
                                            style={{
                                              maxHeight: "40px",
                                              overflowY: "scroll",
                                            }}
                                          >
                                            <CheckCondition
                                              condition={
                                                list.length > 0
                                              }
                                            >
                                              <>
                                                {list
                                                  .filter((data: any) =>
                                                    this.handleSearch(data.name)
                                                  )
                                                  .map((data: any) => (
                                                    <Tooltip
                                                      title={data.name}
                                                      key={`${label.toLowerCase()}-${data.id}`}
                                                    >
                                                      <MenuItem
                                                        className="state-menu-items"
                                                        value={data.name}
                                                        style={{
                                                          fontWeight: 400,
                                                          fontSize: "12px",
                                                          lineHeight: "18px",
                                                          padding: "0px",
                                                          marginBottom: "2px",
                                                          color: "#1C2324",
                                                        }}
                                                        onClick={() =>
                                                          {
                                                            const update =  {[variable]:data,
                                                              expanded: null,
                                                              inputField: "",}
                                                            this.setState(update as any)
                                                          }
                                                        }
                                                      >
                                                        {data.name}
                                                      </MenuItem>
                                                    </Tooltip>
                                                  ))}
                                              </>
                                            </CheckCondition>
                                          </Box>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                </Grid>
 )
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavigationMenu navigation={this.props.navigation} id={this.props.id}>
          <Box >
            <CustomModal
              open={!!this.state.deletePopup}
              onClose={() => this.toggleDeletePopup(null)}
              confirmHandler={() => this.state.deletePopup && this.deleteEvent()}
              confirmTitle="Delete"
              title="Delete Entry"
              description="Are you sure you want to delete entry?"
            />
            <CustomModal
              open={!!this.state.error}
              onClose={() => this.setState({ error: '', popupTitle: "Error" })}
              title={this.state.popupTitle}
              description={this.state.error}
            />

            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography style={webStyle.mainNotificationHeading}>
                  Notification
                </Typography>
                <ToggleButtonGroup
                  value={this.state.activeTabs}
                  exclusive
                  onChange={(_, tabs) => this.toggleBtn(tabs)}
                  style={webStyle.w100}
                >
                  <StyledToggleButton
                    value="sent"
                    aria-label="sent"
                    style={webStyle.leftToggle}
                  >
                    Sent
                  </StyledToggleButton>
                  <StyledToggleButton
                    value="received"
                    aria-label="received"
                    style={webStyle.rightToggle}
                  >
                    Received
                  </StyledToggleButton>
                </ToggleButtonGroup>
                <div id="notificationScrollableDiv" style={{ overflowY: "auto",maxHeight:"815px" }}>
                      <InfiniteScroll
                        dataLength={this.state.notification?.length}
                        scrollableTarget="notificationScrollableDiv"
                        next={()=>{
                          this.getNotificationList()
                        }}
                        hasMore={true}
                        loader={<></>}
                      >
                        {
                  this.state.notification?.map((notificationData: any, index: number) =>
                    <AccordionSection
                      data={notificationData.data}
                      selected={this.state.expandedAccordion}
                      handleAccordion={this.toggleAccordion}
                      editSection={() => this.editHandle(notificationData.data)}
                      deleteHandler={() => this.toggleDeletePopup(notificationData.data.id)}
                      key={`sent-${index}`}
                      customNotification={notificationData.is_custom_notification}
                      type={this.state.activeTabs}
                      handleShareLink={this.handleShareLink}
                      handleShowNotificationPopup={this.handleShowNotificationPopup}
                    />
                  )
                }
                      
                      </InfiniteScroll>
                    </div>
                
              </Grid>
              <Grid item xs={12} md={8}>
                <Box style={webStyle.rightSection}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography style={webStyle.addNotificationHeading}>
                      {this.state.isEdit ? "Edit" : "Add"} Notification
                    </Typography>
                    {
                      this.state.isEdit &&
                      <IconButton id="closeBtn" onClick={() => this.removeEdit()} style={{ background: 'transparent' }}>
                        <Close />
                      </IconButton>
                    }
                  </div>
                  <Formik
                    initialValues={this.state.initalvalues}
                    validationSchema={this.validationSchema}
                    onSubmit={(values, { resetForm }) =>
                      this.submitHandle(values, resetForm)
                    }
                    enableReinitialize
                  >
                    {({
                      values,
                      setFieldValue,
                      setFieldError,
                      setFieldTouched,
                      errors,
                      touched,
                    }) => (
                      <Form>
                        <AntTabs
                          className="ant-tabs-class"
                          aria-label="ant example"
                        >
                          <Grid container style={{ flexWrap: "nowrap" as const }}>
                            <Grid
                              item
                              xs={4}
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <AntTab
                                className="ant-tab-class1"
                                onClick={() => setFieldValue("tab", 0)}
                                selected={values.tab === 0}
                                value="English"
                                label="English"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <AntTab
                                className="ant-tab-class2"
                                onClick={() => setFieldValue("tab", 2)}
                                selected={values.tab === 2}
                                value="Gujarati"
                                label="Gujarati"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <AntTab
                                className="ant-tab-class3"
                                onClick={() => setFieldValue("tab", 1)}
                                selected={values.tab === 1}
                                value="Hindi"
                                label="Hindi"
                              />
                            </Grid>
                          </Grid>
                        </AntTabs>
                        <Box style={webStyle.formBox}>
                          <Typography style={webStyle.label}>
                            Notification type
                          </Typography>
                          <FormControl fullWidth>
                            <Select
                              disableUnderline
                              disabled={!!this.state.isEdit}
                              data-test-id="tableSelectionMenuTestid"
                              className="table-select-menuitem"
                              value={values.notification_type_id}
                              onChange={(event) =>
                                setFieldValue(
                                  "notification_type_id",
                                  event.target.value
                                )
                              }
                              variant="outlined"
                              IconComponent={(props) => (
                                <ExpandMore
                                  {...props}
                                  sx={{ marginRight: "15px" }}
                                />
                              )}
                              error={
                                !!(
                                  errors.notification_type_id &&
                                  touched.notification_type_id
                                )
                              }
                            >
                              <MenuItem value="none">
                                <span style={webStyle.placeHolder}>
                                  {" "}
                                  Select type of notification
                                </span>
                              </MenuItem>
                              {this.state.notificationType?.map(
                                (data, index) => (
                                  <MenuItem
                                    value={data.id}
                                    key={`option-notification-type-${index}`}
                                  >
                                    {
                                      data.attributes[
                                      this.selectedLanguage(values.tab)
                                      ]
                                    }
                                  </MenuItem>
                                )
                              )}
                            </Select>

                            <FormHelperText error id="accountId-error">
                              {touched.notification_type_id &&
                                errors.notification_type_id}
                            </FormHelperText>
                          </FormControl>
                          <Typography style={webStyle.label}>Title</Typography>
                          <FormControl fullWidth>
                            <TextField
                              data-test-id="nameField"
                              placeholder="Enter title of notification"
                              type="text"
                              name="name"
                              variant="outlined"
                              color="secondary"
                              id="name"
                              value={values.data[values.tab].title}
                              onChange={(event) =>
                                setFieldValue(
                                  `data[${values.tab}].title`,
                                  event.target.value
                                )
                              }
                              error={
                                !!this.getErrors(
                                  touched,
                                  errors,
                                  values.tab,
                                  "title"
                                )
                              }
                              helperText={this.getErrors(
                                touched,
                                errors,
                                values.tab,
                                "title"
                              )}
                            />
                          </FormControl>
                          <Box style={webStyle.secondaryUploadImageBox}>
                            <Box
                              component="form"
                              style={webStyle.secondaryRootBox}
                            >
                              <Typography
                                variant="subtitle2"
                                style={
                                  webStyle.modalCardContentEditModalTypography
                                }
                              >
                                Upload Image/Video
                              </Typography>
                              <FormControl
                                fullWidth
                                style={webStyle.modalFormControl}
                              >
                                <InputLabel htmlFor="my-input" />
                                {this.ImageFragment({
                                  attachment: values.image,
                                  isImage: values.isImage,
                                  error:  this.errorImg(touched, errors),
                                  setData: (value: any) => this.setField(value, setFieldValue, setFieldError)
                                })}
                                <FormHelperText error id="accountId-error">
                                  {this.errorImg(touched, errors)}
                                </FormHelperText>
                              </FormControl>
                              
                            </Box>
                          </Box>
                          <Typography style={webStyle.label}>
                            Description
                          </Typography>
                          <FormControl fullWidth>
                            <EventField
                              style={webStyle.decription}
                              data-testId="eventDescription"
                              placeholder="Enter description here...."
                              id="description"
                              className="description-textfield-class"
                              type="text"
                              value={values.data[values.tab].description}
                              onChange={(event) =>
                                setFieldValue(
                                  `data[${values.tab}].description`,
                                  event.target.value
                                )
                              }
                              name="description"
                              color="secondary"
                              multiline
                              rows={4}
                              error={
                                !!this.getErrors(
                                  touched,
                                  errors,
                                  values.tab,
                                  "description"
                                )
                              }
                            />
                            <FormHelperText error id="accountId-error">
                              {this.getErrors(
                                touched,
                                errors,
                                values.tab,
                                "description"
                              )}
                            </FormHelperText>
                          </FormControl>
                          <Box style={webStyle.headerBox}>
                            <Typography style={webStyle.label}>
                              Send to
                            </Typography>
                            {
                              Boolean(this.state.chips.length)  &&
                              <Button variant="text" onClick={() => this.setState({ chips: [] })}>
                                Clear All
                              </Button>
                            }
                          </Box>
                          <Box style={webStyle.sendToContainer}>
                            <Grid container alignItems="center" style={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                              <Grid md={11}>
                                {this.state.chips.length ? (
                                  <div
                                    className="chips-container"
                                    style={{
                                      maxWidth: "100%",
                                      overflowX: "auto",
                                    }}
                                  >
                                    {this.state.chips.map(
                                      (chip: any, index: number) => (
                                        <CustomChip
                                          key={`chip-${index}`}
                                          label={
                                            chip[this.getTypeChip(chip.role_id)]
                                          }
                                          onDelete={this.handleChipDelete(chip)}
                                          roleType={this.getTypeChip(
                                            chip.role_id,
                                            true
                                          )}
                                          index={index}
                                        />
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <div style={webStyle.placeHolderLabel}>
                                    <Search />
                                    <div style={{
                                      fontFamily: 'IBM Plex Sans',
                                      fontSize: '16px',
                                      fontWeight: 400
                                    }}>
                                      Search by name of person, groups
                                    </div>
                                  </div>
                                )}
                              </Grid>
                              <Grid md={1}>
                                <IconButton
                                  data-test-id="filterBtn"
                                  className="filter-icon-class"
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={(event) =>
                                    this.setState({
                                      anchorEl: event.currentTarget,
                                    })
                                  }
                                >
                                  <FilterList style={{ color: "#1C2324" }} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Box>

                          <Button
                            fullWidth
                            style={{
                              background: "#DF5200",
                              borderRadius: "28px",
                              color: "#fff",
                              fontSize: '18px',
                              lineHeight: '28px',
                              fontWeight: 600,
                              fontFamily: "IBM Plex Sans",
                              marginTop: "40px",
                            }}
                            className="button-add"
                            type="submit"
                            disabled={this.state.isLoading}
                            onClick={() => {
                              this.errorCheck(errors, setFieldValue)
                            }}
                          >
                            { this.state.isEdit ? "Edit" : "Add"}
                            {this.state.isLoading && (
                              <CircularProgress
                                style={{
                                  color: "#FFF",
                                  width: "18px",
                                  height: "18px",
                                  marginLeft: "10px",
                                }}
                              />
                            )}
                          </Button>
                        </Box>

                        <Popover
                          className="filterPopover"
                          open={Boolean(this.state.anchorEl)}
                          anchorEl={this.state.anchorEl}
                          onClose={() => this.clearFilter() }
                          style={{
                            overflowY: 'auto' as const
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <Box
                            style={{
                              padding: "21px 16px 16px 16px",
                              fontFamily: "IBM Plex Sans",
                              fontStyle: "normal",
                              overflow: "inherit !important",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                style={{
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#1C2324",
                                  fontWeight: 500,
                                }}
                              >
                                Filter
                              </Typography>
                              <IconButton
                                aria-label="close"
                                data-testid="filterModalCloseBtnTestid"
                                className="close_btn"
                                style={{ padding: "0px", paddingRight: "5px" }}
                                onClick={() => this.clearFilter()}
                              >
                                <Close />
                              </IconButton>
                            </Box>
                            <Box
                              className="checkbox-container"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "6px",
                              }}
                            >
                              <FormControlLabel
                                className="temp-checkbox all-checkbox"
                                control={
                                  <Checkbox
                                    color="secondary"
                                    disableRipple
                                    className="azcheckbox"
                                    style={{ background: "transparent" }}
                                    icon={<img src={blankCheckbox} />}
                                    checkedIcon={
                                      <img
                                        src={checkedCheckBox}
                                        className="checkboxImgCustom"
                                      />
                                    }
                                    onChange={(event) =>
                                      this.setState((preState) => ({
                                        allFilter: !preState.allFilter,
                                      }))
                                    }
                                    checked={this.state.allFilter}
                                  />
                                }
                                label="All"
                                labelPlacement="start"
                              />
                            </Box>
                            <Box >
                              <Grid
                                container
                                style={{ display: "grid", gridGap: "18px" }}
                              >

                      {this.filterSection("State", "selectedState" ,  this.state.selectedState, this.state.stateList , 10)}
                      {this.filterSection('District', "selectedDistrict" ,  this.state.selectedDistrict, this.state.districtList , 9 )}
                      {this.filterSection('Taluka', "selectedTaluka" ,  this.state.selectedTaluka, this.state.talukaList , 8 )}
                      {this.filterSection('Village', "selectedVillage" ,  this.state.selectedVillage, this.state.villageList , 7 )}
                      {this.filterSection('Groups', "selectedGroup" ,  this.state.selectedGroup, this.state.groupList , 6 )}
                                <Grid
                                  item
                                  container
                                  md={12}
                                  lg={12}
                                >
                                  <Grid item xs={3}>
                                    <Box>
                                      <Typography
                                        style={{
                                          transform: "translateY(4px)",
                                          display: "flex",
                                          fontSize: "14px",
                                          lineHeight: "22px",
                                          color: "#1C2324",
                                          fontWeight: 400,
                                        }}
                                      >
                                        F.E.
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={9}
                                    className="accordion-grid"
                                    style={{
                                      position: "relative",
                                      zIndex: 5,
                                      paddingLeft: "12px",
                                    }}
                                  >
                                    <Accordion
                                      style={{
                                        position: "absolute",
                                        backgroundColor: "#FCFAF7",
                                        border: "1px solid #F0EBE0",
                                        borderRadius: "8px",
                                        width: "176px",
                                      }}
                                      disabled={this.checkDisabled("fe")}
                                      expanded={this.state.expanded === "fe"}
                                      onChange={() =>
                                        this.handleChangePanel("fe")
                                      }
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                          minHeight: "28px",
                                          height: "28px",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            fontSize: "12px",
                                            lineHeight: "18px",
                                            color: "#1C2324",
                                            fontWeight: 400,
                                            whiteSpace: 'nowrap',
                                            maxWidth: '133px',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            opacity: this.opacityCalculator(
                                              this.state.selectedFE.first_name
                                            ),
                                          }}
                                        >
                                          {`${this.state.selectedFE.first_name} ${this.state.selectedFE.last_name}`}
                                        </Typography>
                                      </AccordionSummary>
                                      <Box
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Divider
                                          style={{
                                            width: "80%",
                                            height: "1px",
                                            background: "#F0EBE0",
                                            borderRadius: "4px",
                                          }}
                                        />
                                      </Box>
                                      <AccordionDetails className="accordionDetails">
                                        <Box
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                          }}
                                        >
                                          <TextField
                                            placeholder="Search F.E. "
                                            className="fieldExecutive-field-class"
                                            data-testid="fieldExectiveTestId"
                                            id="fieldExective-textfield"
                                            value={this.state.inputField}
                                            variant="outlined"
                                            style={{ marginBottom: "7px" }}
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment
                                                  position="start"
                                                  style={{
                                                    background: "#ffffff",
                                                  }}
                                                >
                                                  <Search
                                                    fontSize="small"
                                                    style={{
                                                      opacity: "0.32",
                                                      color: "#1C2324",
                                                    }}
                                                  />
                                                </InputAdornment>
                                              ),
                                            }}
                                            onChange={(event) =>
                                              this.setState({
                                                inputField: event.target.value,
                                              })
                                            }
                                          />
                                          <Box
                                            style={{
                                              maxHeight: "40px",
                                              overflowY: "scroll",
                                              width: "100%",
                                            }}
                                          >
                                            <CheckCondition
                                              condition={
                                                this.state.FEList.length > 0
                                              }
                                            >
                                              <>
                                                {this.state.FEList.filter((data: any) =>
                                                  this.handleSearch(`${data.first_name} ${data.last_name}`)
                                                ).map(
                                                  (fieldExectiveData: any) => (
                                                    <Tooltip
                                                      title={`${fieldExectiveData.first_name} ${fieldExectiveData.last_name}`}
                                                      key={`fe-${fieldExectiveData.id}`}
                                                    >
                                                      <MenuItem
                                                        value={
                                                          fieldExectiveData.user_name
                                                        }
                                                        className="fe-menu-items"
                                                        style={{
                                                          fontWeight: 400,
                                                          fontSize: "12px",
                                                          lineHeight: "18px",
                                                          padding: "0px",
                                                          marginBottom: "2px",
                                                          color: "#1C2324",
                                                        }}
                                                        onClick={() =>
                                                          this.setState({
                                                            selectedFE:
                                                              fieldExectiveData,
                                                            expanded: null,
                                                            inputField: "",
                                                          })
                                                        }
                                                      >
                                                        {`${fieldExectiveData.first_name} ${fieldExectiveData.last_name}`}
                                                      </MenuItem>
                                                    </Tooltip>
                                                  )
                                                )}
                                              </>
                                            </CheckCondition>
                                          </Box>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  md={12}
                                  lg={12}
                                >
                                  <Grid item xs={3}>
                                    <Box>
                                      <Typography
                                        style={{
                                          transform: "translateY(4px)",
                                          display: "flex",
                                          fontSize: "14px",
                                          lineHeight: "22px",
                                          color: "#1C2324",
                                          fontWeight: 400,
                                        }}
                                      >
                                        Farmer
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={9}
                                    className="accordion-grid"
                                    style={{
                                      position: "relative",
                                      zIndex: 4,
                                      paddingLeft: "12px",
                                    }}
                                  >
                                    <Accordion
                                      style={{
                                        position: "absolute",
                                        backgroundColor: "#FCFAF7",
                                        border: "1px solid #F0EBE0",
                                        borderRadius: "8px",
                                        width: "176px",
                                      }}
                                      id="farmer_Accordion"
                                      disabled={this.checkDisabled("farmerAccordion")}
                                      expanded={
                                        this.state.expanded === "farmerAccordion"
                                      }
                                      onChange={() =>
                                        this.handleChangePanel("farmerAccordion")
                                      }
                                    >
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                          minHeight: "28px",
                                          height: "28px",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            fontSize: "12px",
                                            lineHeight: "18px",
                                            color: "#1C2324",
                                            fontWeight: 400,
                                            opacity: this.opacityCalculator(
                                              this.state.selectedFarmer
                                                .first_name
                                            ),
                                          }}
                                        >
                                          {this.state.selectedFarmer.first_name}
                                        </Typography>
                                      </AccordionSummary>
                                      <Box
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Divider
                                          style={{
                                            width: "80%",
                                            height: "1px",
                                            background: "#F0EBE0",
                                            borderRadius: "4px",
                                          }}
                                        />
                                      </Box>
                                      <AccordionDetails className="accordionDetails">
                                        <Box
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <TextField
                                            placeholder="Search Farmer "
                                            className="farmer-field-class"
                                            data-testid="farmerTextFieldTestid"
                                            variant="outlined"
                                            value={this.state.inputField}
                                            id="farmer-textfield"
                                            style={{ marginBottom: "7px" }}
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  <Search
                                                    fontSize="small"
                                                    style={{
                                                      opacity: "0.32",
                                                      color: "#1C2324",
                                                    }}
                                                  />
                                                </InputAdornment>
                                              ),
                                            }}
                                            onChange={(event) =>
                                              this.setState({
                                                inputField: event.target.value,
                                              })
                                            }
                                          />
                                          <Box
                                            style={{
                                              maxHeight: "40px",
                                              overflowY: "scroll",
                                            }}
                                          >
                                            <CheckCondition
                                              condition={
                                                this.state.farmerList.length > 0
                                              }
                                            >
                                              <>
                                                {this.state.farmerList.filter((data: any) =>
                                                  this.handleSearch(data.first_name)
                                                ).map(
                                                  (farmerData: any) => (
                                                    <Tooltip
                                                      title={
                                                        farmerData.first_name
                                                      }
                                                      key={`farmers-${farmerData.id}`}
                                                    >
                                                      <MenuItem

                                                        value={
                                                          farmerData.first_name
                                                        }
                                                        className="farmer-menu-items"
                                                        style={{
                                                          fontWeight: 400,
                                                          fontSize: "12px",
                                                          lineHeight: "18px",
                                                          padding: "0px",
                                                          marginBottom: "2px",
                                                          color: "#1C2324",
                                                        }}
                                                        onClick={() =>
                                                          this.setState({
                                                            selectedFarmer:
                                                              farmerData,
                                                            expanded: null,
                                                            inputField: "",
                                                          })
                                                        }
                                                      >
                                                        {farmerData.first_name}
                                                      </MenuItem>
                                                    </Tooltip>
                                                  )
                                                )}
                                              </>
                                            </CheckCondition>
                                          </Box>
                                        </Box>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  md={12}
                                  lg={12}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexFlow: "row",
                                    marginTop: "10px",
                                  }}
                                >
                                  <Button
                                    onClick={(e: any) => {
                                      this.handleSubmitAccordion();
                                      this.setState({ anchorEl: null })
                                    }}
                                    className="submit_accordion_btn"
                                    type="button"
                                    variant="contained"
                                    style={{
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      lineHeight: "22px",
                                      textTransform: "uppercase",
                                      background: "#DF5200",
                                      padding: "5px 40px",
                                      borderRadius: "16px",
                                      color: "#FFFFFF",
                                    }}
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    style={{
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      lineHeight: "22px",
                                      textTransform: "uppercase",
                                      border: "1px solid #DF5200",
                                      borderRadius: "16px",
                                      background: "#FFFFF",
                                      padding: "5px 40px",
                                      marginLeft: "12px",
                                      color: "#1C2324",
                                    }}
                                    variant="outlined"
                                    id="clear_filter"
                                    type="button"
                                    onClick={() => this.clearFilter(true)}
                                  >
                                    Clear
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Popover>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </NavigationMenu>
        {this.state.isShare && <LinkShare isShare={this.state.isShare}
            handleShareLink={this.handleShareLink} linkShare={this.state.linkShare}
            messageHeading={this.state.messageHeading} />}
        <NotificationChatContent
          handleShareLinkPopup={this.handleShowNotificationPopup}
          isLinkPopup={this.state.isNotifyLInk}
          notificationObj={this.state.showNotificationObj}
          notiFyType={this.state.notiFyType}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledToggleButton = withStyles({
  root: {
    background: "#F0EBE0",
    color: "black",
    border: "0",
    "&.MuiToggleButton-root.Mui-selected": {
      color: "#fff",
      backgroundColor: "#DF5200",
    },
    "&:hover": {
      background: "#F0EBE0",
    },
  },
})(MuiToggleButton);

const EventField = withStyles({
  root: {
    background: "#FCFAF7",
    border: "1px solid #F0EBE0",
    borderRadius: "12px",
    fontFamily: "IBM Plex Sans",
    fontWeight: 400,
    padding: "0px",
    fontSize: "16px",
    lineHeight: "24px",
    "& .Mui:focused": {
      borderColor: "1px solid #1C2324",
    },
  },
})(OutlinedInput);
const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: "10%",
    top: "15%",
    // borderRadius: "2px",
    width: "25px",
    height: "20px",
    padding: ".8rem",
  },
}));

const AntTabs = withStyles({
  root: {
    textTransform: "capitalize",
    "& .MuiTab-textColorInherit": {
      borderBottom: "2px solid",
    },
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#DF5200",
    textTransform: "none",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "black",
    },
  },
})(Tabs);
const AntTab = withStyles({
  root: {
    fontSize: "20px",
    width: "100%",
    maxWidth: "100%",
    minWidth: '100%',
    color: 'rgba(28, 35, 36, 0.48)',
    "&:hover": {
      color: "#DF5200",
      opacity: 1,
    },
    "&$selected": {
      color: "#DF5200",
    },
    "&:focus": {
      color: "#DF5200",
    },
  },
  selected: {
    color: "#DF5200",
  },
})(Tab);

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  placeHolderLabel: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  placeHolder: {
    color: "#838383",
  },
  sendToContainer: {
    padding: "0px 2px 0px 17px",
    border: "1px solid #f0ebe0",
    borderRadius: "12px",
    background: "#fcfaf7",
    color: "#aaabaa",
  },
  mainNotificationHeading: {
    fontSize: "32px",
    fontWeight: 600,
    lineHeight: "48px",
    margin: "24px 0 12px 0",
  },
  notificationBox: {
    backgroundColor: "#F8F6F1",
    padding: "8px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
  },
  headerBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  imgBox: {
    minWidth: '56px',
    minHeight: '56px',
    background: 'rgb(149, 67, 67)',
    margin: '10px',
    width: '85px',
    height: '85px',
    borderRadius: '5px'
  },
  headingNotification: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#1C2324",
    overflow: "hidden",
    lineClamp: 1,
    boxOrient: "vertical" as const,
    maxWidth: "80%",
    display: "-webkit-inline-box",
  },
  paraNotification: {
    color: "#1C2324",
    fontSize: "10px",
    lineHeight: "18px",
    padding: "5px 0",
  },
  notificationAlert: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  alertText: {
    color: "#B3261E",
    fontSize: "12px",
    lineHeight: "18px",
  },
  minText: {
    color: "#1C2324",
    fontSize: "10px",
    lineHeight: "16px",
    fontStyle: "italic",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  itemWrapper: {
    border: "1px solid #767676",
    display: "flex",
    width: "70%",
    flexDirection: "row",
    marginTop: 10,
    padding: 16,
    paddingTop: 10,
    cursor: "pointer",
  },
  itemHeadingWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  iconStyle: {
    width: 20,
    height: 26,
    marginTop: 6,
  },
  itemHeading: {
    color: "#000",
    flex: 1,
    fontWeight: 700,
  },
  itemHeadingRead: {
    color: "#6200EE",
    flex: 1,
    fontWeight: 700,
  },
  contents: {
    fontSize: 16,
    color: "#767676",
  },
  okButton: {
    backgroundColor: "#ccc",
    color: "#000",
  },
  deleteButton: {
    backgroundColor: "#FF0000",
  },
  w100: {
    width: "100%",
  },
  hide: {
    display: "none",
  },
  badgeButton: {
    backgroundColor: "rgba(28, 35, 36, .56)",
    padding: "0.375rem",
  },
  badgeCardRoot: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FCFAF7",
    border: "1px solid #f0ebe0",
    borderRadius: "0.75rem",
    height: "200px",
  },
  errorBorder: {
    border: "1px solid",
  },
  formImageContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FCFAF7",
    border: "1px solid #F0EBE0",
    borderRadius: "0.75rem",
    width: "100%",
    height: "200px",
  },
  photoCamera: {
    width: 60,
    height: 60,
    color: "#1C2324",
    opacity: 0.5,
  },
  leftToggle: {
    width: "50%",
    borderTopLeftRadius: "50px",
    borderBottomLeftRadius: "50px",
    padding: " 0px !important",
    height: "40px",
  },
  rightToggle: {
    width: "50%",
    borderTopRightRadius: "50px",
    borderBottomRightRadius: "50px",
    padding: " 0px !important",
    height: "40px",
  },
  rightSection: {
    width: "100%",
    background: "white",
    padding: "10px",
    borderRadius: "12px",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  addNotificationHeading: {
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "uppercase" as const,
    lineHeight: "28px",
    margin: "20px 0px",
    paddingLeft: "5px",
  },
  formBox: {
    padding: "0 20px",
  },
  label: {
    fontSize: "14px",
    color: "#1C2324",
    opacity: 0.64,
    lineHeight: "1.5vw",
    marginBottom: "8px",
    marginTop: "16px",
  },
  decription: {
    padding: "15px",
  },
  detailsSection:{
    margin: '10px',
    borderRadius: '5px',
    width: '70%'
  },
  secondaryUploadImageBox: { display: "flex", gap: "1rem" },
  secondaryRootBox: { width: "100%" },
  modalCardContentEditModalTypography: {
    fontFamily: "IBM Plex Sans",
    paddingBlockEnd: 1,
    fontSize: ".875rem",
    marginTop: "16px",
    marginBottom: "8px",
    fontWeight: 400,
    color: "#1C2324",
    opacity: 0.64,
  },
  modalFormControl: {
    height: "100%",
  },
};
// Customizable Area End
