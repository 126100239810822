import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    InputBase,
    IconButton
} from "@material-ui/core";
import { Search, Close } from "@material-ui/icons";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./Dropdown.web.css"
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import { RegistrationDropdownIcon, FarmdiaryDropdownIcon, NotificationDropdownIcon,ExpandRightArrowIconSVG } from "../../src/assets";
import LanguageTabs from "../../../../components/src/LanguageTabs.web";

import DropdownController, { DropdownIndex, Props, Subdropdown } from "./DropdownController";
import NavigationMenu from "../../../dashboard/src/NavigationMenu.web";
import { configJSON } from "../../../dashboard/src/NavigationMenuController";
import { Help } from "../../../dashboard/src/assets";
import { LoaderCenter } from "../../../../components/src/AdminConsoleHelper";

// Customizable Area End

// Customizable Area Start
const { navigationScreenDefaults: screenDefaults } = configJSON;

const getDropdownData = (item: DropdownIndex) => {
    let imageSrc: any = ""
    let margin: any = "0 12px 0 0"
    let title: string = item.dropdown

    if (item.dropdown === "Registration") {
        imageSrc = RegistrationDropdownIcon
    } else if (item.dropdown === "Farm Dairy") {
        imageSrc = FarmdiaryDropdownIcon
        margin = "0 16px 0 0"
    } else if (item.dropdown === "Materrapedia") {
        imageSrc = Help
        margin = "0px 24px 0px 10px"
    } else if (item.dropdown === "Notification") {
        imageSrc = NotificationDropdownIcon
    }
    return { imageSrc, margin, title }
}
// Customizable Area End
export default class Dropdown extends DropdownController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderSubDropDowns = (data: any[]) => {
        const { section1InputsEnglish, section2InputsHindi, section3InputsGujrati } = this.state        
        return (
            <>
                <Box className="dropdown-accordian-wrapper">
                    {
                        data.map((item: any,itemIndex:number) => {
                            return (
                                <>
                                    <Accordion
                                        className="main-sub-accordian-wrapper"
                                        expanded={this.state.currentExpandedSubDropdown === item.heading}
                                        onChange={this.handleSetCurrentExpandedSubDropdown(item.heading,item)}
                                        data-testid={`child-dropdown-${itemIndex}-${item.heading}`}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon style={{ width: "28px", height: "28px", color: "#1C2324", opacity: 0.72 }} />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <Typography className="sub-dropdown-title">{item.heading}</Typography>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails className="main-accordian-details-wrapper">
                                            <Box>
                                                <LanguageTabs
                                                    data-testid={`language-tab-${itemIndex}-${item.heading}`}
                                                    loading={this.state.subDropdownDetailsDataLoading} // loading for fetch api
                                                    loadingIndicator={this.state.loading}   // submit button loader
                                                    handleErrorClear={this.handleErrorClear}                                                                                  
                                                    handleInputChange={this.handleInputChange}                                                    
                                                    handleAddInput={this.handleAddInput} // handle add input function
                                                    handleRemoveInput={this.handleRemoveInput} // handle remove input function
                                                    section1InputsEnglish={section1InputsEnglish}
                                                    section2InputsHindi={section2InputsHindi}
                                                    section3InputsGujrati={section3InputsGujrati}                                                    
                                                    resetSelectionInput={this.handleResetSection} // reset function
                                                    stateList={this.state.stateList}
                                                    districtList={this.state.districtList}
                                                    talukaList={this.state.talukaList}
                                                    villageList={this.state.villageList}
                                                    // dropdown details array
                                                    specificDropDownData={this.specificDropDownDataProp()}                                                    
                                                    keyName={this.keyNameProp()}
                                                    handleSelectData={this.addDropDownData} // submit API call function                                                    
                                                    error={this.errorProp(item)} // error message
                                                    errorType={this.errorTypeProp(item)} // error type
                                                />
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                </>
                            )
                        })
                    }
                </Box>
            </>
        )

    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { navigation, id } = this.props;
        const { dropdownDataIndex, subDropdownHeadings } = this.state;

        return (
            <ThemeProvider theme={theme}>
                <NavigationMenu navigation={navigation} id={id}>
                    <Grid container spacing={2}>
                        <Grid item md={4} lg={4} sm={12} xs={12} style={{padding:"8px 12px "}}>
                            <Typography style={styles.dropDownTitle}>{this.state.dropDownTitle}</Typography>                            
                            <Box className="dropdown-accordian-wrapper">
                                {dropdownDataIndex.length ? dropdownDataIndex.map((item: DropdownIndex,itemIndex:number) => {
                                    const dropdownData = getDropdownData(item)
                                    return (
                                        <>
                                            <Accordion className="main-accordian-wrapper"
                                                expanded={this.state.currentExpandedDropdown === item.dropdown}
                                                onChange={this.handleSetCurrentExpandedDropdown(item.dropdown)}
                                                data-testid={`dropdown-parent-${itemIndex}`}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon style={{width:"24px", height:"24px", color:"#1C2324", opacity:0.72 }}/>}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                                        <img src={dropdownData.imageSrc} style={{ margin: dropdownData.margin }} />
                                                        <Typography className="main-dropdown-title">{dropdownData.title}</Typography>
                                                    </Box>
                                                </AccordionSummary>
                                                <AccordionDetails className="main-accordian-details-wrapper">
                                                    <Divider style={{ marginTop: 0, height: "2px", background: "#F0EBE0" }} />
                                                    <Box className="main-sub-dropdown-wrapper">
                                                        {item.subdropdowns.length ? item.subdropdowns.map((subItem: Subdropdown,subItemIndex:number) => {
                                                            return (
                                                                <>
                                                                    <Box className="sub-dropdown-wrapper"
                                                                        style={{
                                                                            border: "1px solid",
                                                                            borderColor: this.subDropdownBorderColor(subItem)
                                                                        }}
                                                                        onClick={() => this.setSubDropDownData(item, subItem)}
                                                                        data-testid={`dropdown-sub-${subItemIndex}-${itemIndex}`}
                                                                    >
                                                                        <Box>
                                                                            <Box>
                                                                                <Typography className="subdropdown-title">{subItem.heading}</Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                <Typography className="subdropdown-count">{subItem.count}</Typography>
                                                                            </Box>
                                                                        </Box>
                                                                        <img src={ExpandRightArrowIconSVG} className="subdropdown-expand-arrow" />
                                                                    </Box>
                                                                </>
                                                            )
                                                        }):(<></>)}
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                        </>
                                    )
                                })
                                    :
                                    <>
                                        <Typography style={styles.noDataFoundText}>{this.state.dropdownIndexDataLoading ? <LoaderCenter /> : "No Data Found!"}</Typography>
                                    </>
                                }
                            </Box>

                                
                        </Grid>
                        <Grid item md={8} lg={8} sm={12} xs={12} style={{padding:"8px 12px "}}>
                            <Box className="right-div-main-wrapper">
                                <Box style={styles.dropDownRightContainer}>
                                    <Typography style={styles.selectedHeader} className="selectedHeaderStyle">
                                        {this.subDropDownMainHeading()}
                                    </Typography>                                    
                                    <Box style={styles.searchAppBar} className="searchAppBarStyle">
                                        <Box style={styles.searchIconContainer}>
                                            <Search style={styles.searchIcon} fontSize="medium" />
                                        </Box>
                                        <InputBase
                                            placeholder="Search here..."
                                            inputProps={{ "aria-label": "search" }}
                                            style={styles.searchInputNative}
                                            id="searchInputField"
                                            classes={{ input: "searchInput" }}
                                            value={this.state.searchInput}
                                            onChange={(event) => this.updateInput(event.target.value)}
                                        />
                                        {this.state.searchInput &&
                                            <IconButton onClick={() => this.updateInput("")} id="close-btn">
                                                <Close />
                                            </IconButton>}
                                    </Box>
                                </Box>
                                
                                {subDropdownHeadings.length ? (
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} lg={6}>
                                                {/* First Half */}
                                                {this.renderSubDropDowns(subDropdownHeadings.slice(0, Math.ceil(subDropdownHeadings.length / 2)))}
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6}>
                                                {/* Second Half */}
                                                {this.renderSubDropDowns(subDropdownHeadings.slice(Math.ceil(subDropdownHeadings.length / 2)))}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ) : (
                                    <Typography style={styles.noDataFoundText}>{this.state.subDropdownHeadingDataLoading ? <LoaderCenter /> : "No Data Found!"}</Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </NavigationMenu>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#DF5200",
            contrastText: "#fff"
        },
        secondary: {
            main: "#DF5200",
            contrastText: "#fff",
        },

    },
    typography: {
        fontFamily: 'IBM Plex Sans',
        h6: {
            fontWeight: 500
        },
        subtitle1: {
            margin: "20px 0px"
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    }
});
const styles: any = {
    selectedHeader: {
        fontSize: '24px',
        fontWeight: 600,
    },
    dropDownTitle: {
        fontWeight: 600,
        fontSize: "32px",
        lineHeight: '3.3vw',
        marginBottom: '24px'
    },
    noDataFoundText: {
        textAlign: 'center',
        padding: '1rem'
    },
    dropDownRightContainer: {
        marginBottom: "24px",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    searchAppBar: {
        background: "#FCFAF7",
        border: "1px solid #F0EBE0",
        fontSize: "1rem",
        borderRadius: "28px",
        display: "flex",
        width: "50%",
        height: "40px",
        alignItems: "center"
    },

    searchIconContainer: {
        padding: "1rem",
        paddingInlineEnd: "0.5rem",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },

    searchIcon: {
        color: "#1C2324",
        opacity: 0.48
    },

    searchInputNative: {
        fontFamily: screenDefaults.fontFamily,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "1rem",
        padding: 0,
        lineHeight: "1.5rem",
        width: "100%"
    },
};

// Customizable Area End
