import React from "react";

import {
  Grid,
  Button,
  Typography,
  IconButton,
  // Customizable Area Start
  Switch,
  Tabs,
  Box,
  Tab,
  FormControl,
  TextField,
  Tooltip,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { Share, Delete, Create, Clear } from "@material-ui/icons";
import { Formik, Form } from "formik";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import CustomModal from "../../../components/src/customModal.web";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { styles, theme } from "./SurveysStyle.web";
import CreateQuestion from "../../../components/src/surveys/surveysInput.web";
import Loader from "../../../components/src/surveys/loader.web";
import SurveysController, { Props, configJSON, FormikInitalValue } from "./SurveysController";
import LinkShare from "../../LinkShare/src/LinkShare.web";

const fieldObject = {
  question: '',
  question_hindi: "",
  question_gujrati: "",
  question_type_id: "none",
  question_options_attributes: [JSON.parse(JSON.stringify(configJSON.optionInitialValue))]
}

export const initialValues: FormikInitalValue = {
  languageActiveTab: 0 as (0 | 1 | 2),
  title: { 0: "", 1: "", 2: "" },
  fieldArray: [
   JSON.parse(JSON.stringify(fieldObject))
  ]
}

// Customizable Area End




export class Surveys extends SurveysController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  leftSection = () => {
    const { surveyList } = this.state
    const { classes } = this.props

    if (!surveyList || typeof surveyList === "string") {
      return <Loader error={surveyList} />;
    }

    return surveyList.map((survey, index) => (
      <div className={classes.cardSection} key={`survey-list-${index}`}>

        <div className={classes.betweenCard} onClick={() => this.props.navigation.navigate("SurveysDetails", {id:survey.id} )}>
          <Tooltip title={survey.attributes.name}>
              <p className={classes.betweenCardHeading}>{survey.attributes.name}</p>
          </Tooltip>
          <Typography
            className={classes.dateStyle}
            variant="subtitle2"
          >
            {moment(survey?.attributes.created_at).format("DD/MM/YYYY")}
          </Typography>
        </div>
        <div className={classes.betweenCard}>
          <Typography
            className={classes.responseTitle}
            variant="body1"
            onClick={() => this.props.navigation.navigate("SurveysDetails", {id:survey.id} )}
          >
            {survey?.attributes?.survey_response_count}{" "}
            {`Response${(survey?.attributes?.survey_response_count || 0) !== 1
              ? "s"
              : ""
              }`}

          </Typography>

          <div>
            <Switch checked={!survey.attributes.active}
            color="default"
              className={survey.attributes.active ? classes.switchBtn : classes.disableSwitchBtn}
              onChange={() => this.changeSurveysActionState(survey.id, !survey.attributes.active)} 
              />

            <IconButton data-test-id='editSurvey' size="small" className={classes.editBtn} onClick={() => this.editSurvey(survey)}>
              <Create className={classes.font20} />
            </IconButton>
            <IconButton size="small" id='shareSurvey'  className={classes.shareBtn}>
              <Share className={classes.font20} onClick={()=> this.handleShareLink(survey)} />
            </IconButton>
            <IconButton size="small" id='deleteSurvey' className={classes.deleteBtn} onClick={() => this.toggleDelete(survey.id)}>
              <Delete className={classes.font20} />
            </IconButton>
          </div>
        </div>
      </div>
    ))
  }
  // Customizable Area End

  render() {
    const { navigation, id, classes } = this.props;
    const { deleteId, formikInitalValue } = this.state
    return (
      // Customizable Area Start
      <NavigationMenu navigation={navigation} id={id}>
        <ThemeProvider theme={theme}>
          <Box>
            <CustomModal
              open={!!deleteId}
              onClose={() => this.toggleDelete(null)}
              confirmHandler={() => this.deleteSurveyAPI()}
              title="Delete Survey"
              description={configJSON.DELETE_SURVEY_DESCRIPTION}
              confirmTitle="Delete"
            />
            <Grid container spacing={3}>
              <Grid item xs={12} md={5}>
                <div >
                  <Typography variant="h5" className={classes.headerTitle}>
                    Surveys
                  </Typography>
                  <div id="scrollableDiv" style={{ overflowY: "auto", maxHeight: "768px", marginBottom:"3.75rem",marginTop:'14px'}}>
                  <InfiniteScroll
                        dataLength={this.state.surveyList?.length}
                        scrollableTarget="scrollableDiv"
                        next={() => this.getSurveysList(this.state.page + 1)}
                        hasMore={true}
                        loader={<></>}
                    >
                  {this.leftSection()}
                  </InfiniteScroll>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={7} id="survey-form">
                <div className={classes.rightSection}>

                  <Formik initialValues={formikInitalValue || initialValues}
                    validationSchema={this.validation()}
                    onSubmit={(values, { resetForm }) => this.handleSubmit(values, resetForm)}
                    validateOnMount
                    enableReinitialize
                  >
                    {({ values, setFieldValue, handleSubmit, errors, touched, }) => (
                      <Form >
                        <div className={classes.between}>
                          <Typography
                            variant="inherit"
                            className={`${classes.headerTitle} ${classes.font18}`}
                          >
                            { formikInitalValue ? 'EDIT' : "ADD NEW"} SURVEY
                          </Typography>
                          {formikInitalValue &&
                            <IconButton data-test-id='iconBtn' onClick={() => this.setState({ formikInitalValue: null })}>
                              <Clear />
                            </IconButton>
                          }
                        </div>
                        <Tabs
                          variant="fullWidth"
                          indicatorColor="secondary"
                          textColor="secondary"
                          value={values.languageActiveTab}
                          data-test-id='tabs'
                          onChange={(_, newValue) => setFieldValue("languageActiveTab", newValue)}
                          className={classes.tabs}
                        >
                          <Tab label="ENGLISH" className={`${classes.tabLabel} ${classes.borderBottom}`} />
                          <Tab label="GUJARATI" className={`${classes.tabLabel} ${classes.borderBottom}`} />
                          <Tab label="HINDI" className={`${classes.tabLabel} ${classes.borderBottom}`} />
                        </Tabs>
                        <Typography className={classes.labelCss}>
                          Survey Name
                        </Typography>
                        <FormControl fullWidth>
                          <TextField
                            placeholder="Write name of survey here..."
                            type="text"
                            className="title-text-field-class"
                            variant="outlined"
                            color="secondary"
                            value={(values.title[values.languageActiveTab])}
                            helperText={errors?.title && touched.title && errors.title[values.languageActiveTab]}
                            error={errors?.title && touched.title && !!errors.title[values.languageActiveTab]}
                            onChange={(event) => setFieldValue(`title.${values.languageActiveTab}`, event.target.value)}
                          />
                        </FormControl>
                        {
                          values.fieldArray.map((data, index) =>
                            !data._destroy && <CreateQuestion
                              values={data} index={index}
                              errors={errors?.fieldArray && touched.fieldArray && errors?.fieldArray[index]}
                              setFieldValue={setFieldValue} currentTab={values.languageActiveTab}
                              removeField={(deleteIndex: number) => this.removeField(values, setFieldValue, deleteIndex)}
                              allQuestion={this.state.allQuestion}
                              key={`question-${index}`}
                              isOption={this.checkOptionsIsRequired(data.question_type_id)}
                            />)
                        }

                        <Box className={`${classes.boxWraperForServey} ${classes.centerClass}`} data-test-id='addAnotherQuestion' onClick={() => errors && ( Object.keys(errors).length  === 0 ? setFieldValue("fieldArray", values.fieldArray.concat(JSON.parse(JSON.stringify(fieldObject)))) : (handleSubmit(), this.checkErrors(errors, setFieldValue)))} >
                          <Button className={classes.AddAnotherQuestionBtn} fullWidth variant="contained">Add Another Question</Button>
                          <Box style={{ padding: '0 10px' }}>
                            <AddCircleOutlineIcon style={{ color: '#1C2324', opacity: "64%" }} />
                          </Box>
                        </Box>

                        <Box className={classes.submitBtnContainer} >
                          <Button className={classes.shareSurvey} disabled={this.state.isLoading} onClick={() => {
                            handleSubmit()
                            this.checkErrors(errors, setFieldValue)
                          }} fullWidth data-test-id="submit" variant="contained">{formikInitalValue ?  'Update' : "Create"} Survey
                            { this.state.isLoading &&  <CircularProgress
                                style={{
                                  color: "#FFF",
                                  width: "18px",
                                  height: "18px",
                                  marginLeft: "10px",
                                }}
                              />  }
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Grid>
            </Grid>
          </Box>
          {this.state.isShare && <LinkShare isShare={this.state.isShare}
            handleShareLink={this.handleShareLink} linkShare={this.state.linkShare}
            messageHeading={this.state.messageHeading} />}
        </ThemeProvider>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(Surveys);
// Customizable Area End
