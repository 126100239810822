import React from "react";

// Customizable Area Start
import {
  Button,
  Typography,
  Grid,
  ListItem,
  ListItemIcon,
  Avatar,
  Paper,
  IconButton,
  Box,
  FormControl,
  Badge,
  Card,
} from "@material-ui/core";
import FilterListIcon from '@material-ui/icons/FilterList';
import ChatView from "./ChatView.web"
import {  ArrowBack, Send, CameraAlt, Mood ,Attachment} from '@material-ui/icons';
import "./Chat.web.css";
import { addIconSVG, groupIconSVG, messageDeliveredSVG, messageSeenSVG, messageSentSVG } from './assets';
import moment from "moment";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import PopoverComponent from "./components/PopoverComponent";
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit'
import Picker from 'emoji-picker-react'
import CustomModal from "../../../components/src/customModal.web";
import ImageCropEditor from "../../../components/src/ImageCropper";
// Customizable Area End

import ChatController, { Props } from "./ChatController";
import InfiniteScroll from "react-infinite-scroll-component";
import { SearchField } from "./components/SearchField";



const theme = createTheme({
  typography: {
    fontFamily: "IBM Plex Sans",
  },
});

const CustomHeadText = styled('h5')({
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',  
  overflow: 'hidden',
  width: '100%'
})
const Customspan = styled('span')({
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',  
  overflow: 'hidden',
})

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: "10%",
    top: "15%",
    // borderRadius: "2px",
    width: "25px",
    height: "20px",
    padding: ".8rem",
  },
}));

 export const renderChatList = ({ data, search, showEditProfilePage, accountId, showChatMessages, isGroupAdding, handleParticipant, selectedParticipant,userId }: any) => {

  let handleSelectParticipant=(ele:any)=>{
    let filteredData:any;
    let isItemExist=selectedParticipant.find((item:any)=>item.id==ele.id);
    filteredData=isItemExist ?selectedParticipant.filter((item:any)=>item.id != ele.id) : [...selectedParticipant,ele]
    handleParticipant(filteredData)
  }

  let handleClick=(ele:any)=>{
    if (isGroupAdding) {
      handleSelectParticipant(ele)
    } else {
      let chatCount = data.map((chat: any) => {
        return chat.id === ele.id ?
          ({ ...chat, unreadCount: 0 })
          :
          (chat)
          ;
      });
      showChatMessages(ele.id, chatCount,ele.lastMessage?.data);
    }
  }

  let renderListClassName=(ele:any)=>{
    let className="user-box"
    if(ele.id == accountId){
      className+=" active"
    }
    if(isGroupAdding && selectedParticipant.find((item:any)=>item.id==ele.id)){
      className+=" active"
    }
    return className
  }

  const renderMessageStatus=(item:any)=>{
    if((item?.admin_user_id || item?.account_id) !=userId) return
    if(item?.is_mark_read){
        return  <img className="chatlist-message-status" alt="message seen" src={messageSeenSVG} />
    }else if(item?.is_mark_delivered){
        return <img className="chatlist-message-status" alt="message delivered" src={messageDeliveredSVG} />
    }else{
        return  <img className="chatlist-message-status" alt="message sent" src={messageSentSVG} />
    }
}

  let renderLastMessage=(lastMessage:any)=>{
      let msg= <p style={{display:"flex",alignItems:"center",gap:"4px"}}>{renderMessageStatus(lastMessage)} <Customspan title={lastMessage?.message || `Start a chat`}>{lastMessage?.message || `Start a chat`}</Customspan></p>
    if(lastMessage?.attachment_file_type){
      msg=<p style={{display:"flex",alignItems:"center",gap:"5px",textTransform:"capitalize"}}> {renderMessageStatus(lastMessage)}<span><Attachment style={{color:"#1C2324",opacity:0.7,marginBottom:"-7px"}} fontSize="small"/></span> <Customspan title={lastMessage.attachment_file_type}>{lastMessage.attachment_file_type}</Customspan></p>
    }
    return msg
  }

  return data?.map((ele: any, i: number) => {

    const name = ele.name?.split(" ")
    let avatarName = ""
    if (name?.length) {
      avatarName = name[0].charAt(0).toUpperCase()
      if (name.length >= 2) {
        avatarName += name[1].charAt(0).toUpperCase()
      }
    }

    let dateOfLastMessage = ""

    if (ele.lastMessage?.data?.attributes.created_at) {

      const now = moment()
      const startOfDay = now.startOf('day').toString()
      const endOfDay = now.endOf('day').toString()
      const lastMessageDate = moment(ele.lastMessage?.data?.attributes.created_at)
      const isBetween = lastMessageDate.isBetween(startOfDay, endOfDay)

      if (isBetween) {
        dateOfLastMessage = moment(lastMessageDate).format("hh:mma")
      }
      else {
        dateOfLastMessage = moment(lastMessageDate).format("DD/MM/YYYY")
      }
    }
    return (
      <ListItem
        button
        key={ele.id}
        data-test-id="btnShowChat"
        className={renderListClassName(ele)}
        onClick={
          () => {
            handleClick(ele)
          }}
      >
        <ListItemIcon
          data-testid={`user-image`}
          className="user-image"
        >
          {
            ele.logo
              ?
              <Avatar src={ele.logo} alt="" id="user_profile_image" />
              :
              <Avatar
                data-testid='user-symbol'
                className="user-symbol"
              // onClick={showEditProfilePage(ele.id)}
              >
                {avatarName}
              </Avatar>
          }
        </ListItemIcon>

        <div className="chatside-user">
          <div className="name">
            <CustomHeadText title={ele.name}>{ele.name}</CustomHeadText>
            {renderLastMessage(ele.lastMessage?.data?.attributes)}
          </div>
          <div className="time">
            <span>{dateOfLastMessage}</span>
            {ele.unreadCount ? <label className="number">{ele.unreadCount}</label> : <></>}
          </div>
        </div>
      </ListItem >
    );
  })
}

const Filter = ({ showSearchFilterModal, checkFilterActive }: { showSearchFilterModal: (event: any) => void, checkFilterActive: () => boolean }) => {
  return (
    <IconButton
      data-testid="search-input-filter"
      style={{ backgroundColor: "#fff", padding: "0 12px" }}
      onClick={showSearchFilterModal}
    >
      <Box style={{ position: "relative" }}>
        {checkFilterActive() &&
          <Box
            height={"10px"}
            width={"10px"}
            style={{ backgroundColor: "#DF5200", borderRadius: "50%", flexShrink: 0, position: "absolute", right: "-2px", top: "-1px" }}>          </Box>}
        <FilterListIcon style={{ color: '#1C2324', opacity: "0.47999998927116394" }} />
      </Box>
    </IconButton>)
}

const Loader = ({ isLoading }: { isLoading: boolean }) => {
  if (isLoading)
    return <Box sx={{ display: 'flex', justifyContent: "center" }}>
      <CircularProgress
        style={{ color: "#df5200" }}
      />
    </Box>
  return <></>
}




export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderChatTopbar=()=>{
    return  <Box className="chat-topbar">
    {!this.state.isAddGroup && !this.state.isCreateGroup ?
      <div className="chat">
        <Typography variant="h5" className="header-message">Chat</Typography>
        <Button
          data-test-id="newGroup"
          variant="contained"
          style={{
            borderRadius: "24px",
            fontWeight: 500,
            lineHeight: "24px",
            display: "flex",
            alignItems: "center",
            background: "#DF5200",
            fontSize: "16px",
            color: "#FFFFFF",
            padding: "8px 24px",
            boxShadow: "none",
            fontFamily: 'IBM Plex Sans',
            textTransform: "capitalize"
          }}
          disabled={this.state.isLoading}
          onClick={this.handleNewGroupNavigation}
        >
          <img src={addIconSVG} style={{ marginRight: '4px' }}/>  New Group
        </Button>
      </div> :
      <div className="participant-header">
        <Button  disabled={this.state.isLoading} data-testid="back_to_chat" className="participant-arrow" onClick={this.backBtnHandler}>
          <ArrowBack />
        </Button>
        <p>{!this.state.isCreateGroup ? "Add New Participant" : "New Group"}</p>
      </div>}
    {!this.state.isCreateGroup &&
      <div className="search-chat">
        <SearchField
          data-test-id="SearchField"
          chatList={this.state.chatList}
          searchText={this.state.searchText}
          filterList={this.filterList}
          isAddGroup={this.state.isAddGroup}
          handleParticipant={this.handleParticipant}
          selectedParticipant={this.state.selectedParticipant}
          onInputChange={this.onInputChange}
        />

        <Filter showSearchFilterModal={this.showSearchFilterModal} checkFilterActive={this.checkFilterActive} />

        <PopoverComponent
          data-test-id="PopoverComponent"
          isFilterOpen={this.state.isFilterOpen}
          anchorEl={this.state.anchorEl}
          closeFilter={this.closeFilter}
          // handleAllCheck={""}
          sortAll={this.state.sortAll}
          isDisabled={this.state.isDisabled}
          expanded={this.state.expanded}
          handleChangePanel={this.handleChangePanel}
          handleSubmitAccordion={this.handleSubmitAccordion}
          handleClear={this.handleClear}


          opacityStateCalculator={this.opacityStateCalculator}
          selectedState={this.state.selectedState}
          stateInputValue={this.state.stateInputValue}
          handleStateSearch={this.handleStateSearch}
          filteredStates={this.state.filteredStates}
          handleChangeStateField={this.handleChangeStateField}

          opacityDistrictCalculator={this.opacityDistrictCalculator}
          selectedDistrict={this.state.selectedDistrict}
          districtInputValue={this.state.districtInputValue}
          handleDistrictSearch={this.handleDistrictSearch}
          filteredDistricts={this.state.filteredDistricts}
          handleChangeDistrictField={this.handleChangeDistrictField}


          opacityTalukaCalculator={this.opacityTalukaCalculator}
          selectedTaluka={this.state.selectedTaluka}
          talukaInputValue={this.state.talukaInputValue}
          handleTalukaSearch={this.handleTalukaSearch}
          filteredTalukas={this.state.filteredTalukas}
          handleChangeTalukaField={this.handleChangeTalukaField}

          opacityVillageCalculator={this.opacityVillageCalculator}
          selectedVillage={this.state.selectedVillage}
          villageInputValue={this.state.villageInputValue}
          handleVillageSearch={this.handleVillageSearch}
          filteredVillages={this.state.filteredVillages}
          handleChangeVillageField={this.handleChangeVillageField}

          opacityFarmerCalculator={this.opacityFarmerCalculator}
          selectedFarmer={this.state.selectedFarmer}
          farmerInputValue={this.state.farmerInputValue}
          handleFarmerSearch={this.handleFarmerSearch}
          filteredFarmers={this.state.filteredFarmers}
          handleChangeFarmerField={this.handleChangeFarmerField}

          opacityFieldExecutiveCalculator={this.opacityFieldExecutiveCalculator}
          selectedFieldExecutive={this.state.selectedFieldExecutive}
          fieldExecutiveInputValue={this.state.fieldExecutiveInputValue}
          handleFieldExectiveSearch={this.handleFieldExectiveSearch}
          filteredFieldExecutive={this.state.filteredFieldExecutive}
          handleChangeFieldExecutiveField={this.handleChangeFieldExecutiveField}

          opacityAdminCalculator={this.opacityAdminCalculator}
          selectedAdmin={this.state.selectedAdmin}
          adminInputValue={this.state.adminInputValue}
          handleAdminSearch={this.handleAdminSearch}
          filteredAdmin={this.state.filteredAdmin}
          handleChangeAdminField={this.handleChangeAdminField}

          opacitySuperAdminCalculator={this.opacitySuperAdminCalculator}
          selectedSuperAdmin={this.state.selectedSuperAdmin}
          superAdminInputValue={this.state.superAdminInputValue}
          handleSuperAdminSearch={this.handleSuperAdminSearch}
          filteredSuperAdmin={this.state.filteredSuperAdmin}
          handleChangeSuperAdminField={this.handleChangeSuperAdminField}

          sortaz={this.sortaz}
          sortza={this.sortza}
          sortAscending={this.state.sortAscending}
          sortDescending={this.state.sortDescending}
          isAddGroup={this.state.isAddGroup}
          isCreateGroup={this.state.isCreateGroup}
        />
      </div>}
  </Box>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { navigation, id } = this.props;
    return (
      <NavigationMenu id={id} navigation={navigation} childrenStyle={{padding:0}} lastMessageId={this.state.readMessageId}>
        <ThemeProvider theme={theme}>
        <CustomModal 
          open={!!this.state.apiError} 
          onClose={this.closeErrorModal}
          title="Error"
          description={this.state.apiError}
          />
         {this.state.createGroup?.icon && <ImageCropEditor  open={this.state.cropImageModal} src={this.state.createGroup.icon} onClose={this.handleCloseCropModal} title="Group profile" buttonText="Submit" handleUploadImage={this.handleSubmitProgfileImage}/>}
          <div className="chatview-container">
            <Box className="chatview">
              <Grid container
                component={Paper}
                className="chat-container"
                style={{
                  boxShadow: "none",
                  height: "100%",
                }}
              >
                <Grid item
                  className="sidebar"
                 >
                  {this.renderChatTopbar()}
                  {!this.state.isCreateGroup && this.checkToShow() &&
                    <div id="scrollableDiv" style={{ overflowY: "auto", maxHeight: "812px", marginBottom:this.state.isCreateGroup || this.state.isAddGroup  ? "3.75rem":0}}>
                      <Loader isLoading={this.state.isLoading} />
                      <InfiniteScroll
                        dataLength={this.state.chatList?.length}
                        className="sidebar-chat"
                        style={{
                          // height: this.state.isAddGroup ? 683 : 775,
                          boxShadow: "none",
                          width: "100%",
                          paddingInline: "24px"
                        }}
                        scrollableTarget="scrollableDiv"
                        next={this.userChatListRenderer}
                        hasMore={true}
                        loader={<></>}
                      >
                        {renderChatList({
                          data: this.state.chatList,
                          search: this.state.searchText.toLowerCase(),
                          accountId: this.state.accountId,
                          showEditProfilePage: this.showEditProfilePage,
                          showChatMessages: this.showChatMessages,
                          isGroupAdding: this.state.isAddGroup,
                          handleParticipant: this.handleParticipant,
                          selectedParticipant: this.state.selectedParticipant,
                          userId:this.state.currentUser
                        })}
                      </InfiniteScroll>
                    </div>
                  }
                  {(this.state.isAddGroup && !this.state.isCreateGroup && this.state.chatList?.length) ?
                    <Button
                      variant="contained"
                      data-test-id="nextBtn"
                      style={{
                        width: "100%",
                        minHeight: "84px",
                        fontWeight: 500,
                        background: "#DF5200",
                        fontSize: "24px",
                        color: "#FFFFFF",
                        display: "fixed",
                        fontFamily: 'IBM Plex Sans',
                        borderRadius:0,
                        position:"absolute",
                        bottom:0
                      }}
                      onClick={() => {
                        this.setState({ isCreateGroup: true })
                      }}
                      disabled={this.state.selectedParticipant.length >= 2 ? false : true}
                    >  Next
                      <Send style={{ marginLeft: '8px' }} />
                    </Button> : null}

                  {this.state.isCreateGroup &&
                    <div>
                      <Box style={{
                        height: 765
                      }}>
                        <Box display={"flex"} justifyContent={"center"} width="196px" height="196px" style={{ margin: "auto", backgroundColor: "rgb(28, 35, 36, 0.16)", borderRadius: "50%", }}>
                          {
                            this.state.croppedImage.length ?
                              <>
                                <StyledBadge
                                  style={webStyles.cardImageContainer}
                                  overlap="rectangular"
                                  badgeContent={
                                    <Box>
                                      <input
                                        type="file"
                                        style={webStyles.hide}
                                        id="edit-icon-badge-file"
                                        className="image-contained-class"
                                        onChange={(e) => this.handleImageUpload(e)}
                                        data-testid={"modal_image_handle_select"}
                                        accept="image/*"
                                      />
                                      <label htmlFor="edit-icon-badge-file">
                                        <IconButton
                                          data-testid={"modal_image_fragment_select"}
                                          component="span"
                                          aria-label="edit upload picture"
                                          disableRipple={true}
                                          style={webStyles.badgeButton}
                                        >
                                          <EditIcon fontSize="small" htmlColor="#fff" />
                                        </IconButton>
                                      </label>
                                    </Box>
                                  }
                                >
                                  <Card variant="outlined" style={webStyles.cardImageContainer}>
                                    <img src={this.state.croppedImage} style={{objectFit:"cover",width:"100%",height:"200px"}}/>
                                  </Card>
                                </StyledBadge>
                              </>
                              : <Box style={webStyles.formImageContainer}>
                                <input
                                  accept="image/*"
                                  type="file"
                                  className="image-empty-container-class"
                                  style={webStyles.hide}
                                  id="icon-button-file"
                                  onChange={(e) => this.handleImageUpload(e)}
                                  data-testid={"modal_image_handle_select"}
                                />
                                <label htmlFor="icon-button-file" style={{ display: "flex", alignItems: "center" }}>
                                 
                                    <Box
                                      display="flex"
                                      flexDirection={"column"}
                                      alignItems={"center"}
                                      width="100%"
                                      justifyContent={"center"}>
                                      <CameraAlt style={{
                                        width: '56px', height: '56px', flexShrink: 0,cursor:"pointer",opacity:0.64
                                      }}
                                      />
                                      <Typography style={{
                                        color: 'black', fontSize: '16px',
                                        fontFamily: 'IBM Plex Sans',
                                        fontWeight: 500,
                                        lineHeight: '24px',
                                        opacity:0.72
                                      }}>Add Group Icon</Typography>
                                    </Box>
                                </label>
                              </Box >
                          }

                        </Box>
                        <Grid item xs={12} sm={12} style={{ marginTop: "25px",paddingInline:"24px",position:"relative" }}>
                        {this.state.showEmojiPicker && (
                          <Picker
                          pickerStyle={{
                            width: "75%",
                            position: "absolute",
                            right: "24px",
                            bottom:"100%",
                            zIndex:"9"
                          }}
                            onEmojiClick={this.handleClickEmoji}
                          />
                        )}
                          <FormControl fullWidth>
                            <TextField
                              type="text"
                              variant="filled"
                              label="Group Subject"
                              inputProps={{
                                maxLength: 25,
                                style:{
                                  padding:"27px 0px 8px",
                                  fontFamily:"IBM Plex Sans"
                                }
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" style={{marginBottom:"-16px"}}>
                                    <span
                                      style={{
                                        marginRight: "5px",
                                        opacity: "0.47999998927116394",
                                        fontSize: "14px"
                                      }}>
                                      {25-this.state.createGroup.name.length}
                                    </span>
                                    <Mood style={{ opacity: "0.47999998927116394",cursor: "pointer"}} onClick={this.toggleEmojiPicker}/>
                                  </InputAdornment>
                                ),
                              }}
                              value={this.state.createGroup.name}
                              onChange={event => { this.handleGroupName(event.target.value) }}
                            />
                          </FormControl>
                        </Grid>

                      </Box>
                      <Button
                        variant="contained"
                        data-testid="createBtn"
                        style={{
                          width: "100%",
                          minHeight: "84px",
                          fontWeight: 600,
                          background: "#DF5200",
                          lineHeight:"36px",
                          fontSize: "24px",
                          color: "#FFFFFF",
                          display: "fixed",
                          fontFamily: 'IBM Plex Sans',
                          borderRadius:0,
                          position:"absolute",
                          bottom:0
                        }}
                        onClick={this.createNewGroup}
                        disabled={this.state.isCreatingGroup}
                      >  Create Group
                       <img src={groupIconSVG} style={{ marginLeft: '4px' }}/>
                      </Button>
                    </div>
                  }
                </Grid>

                <Grid item
                  // className="chatview-inner"
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    // height: "100%",
                    width: "65%",
                  }}
                >

                  <ChatView
                    // id={id}
                    isMobile={this.state.isMobile}
                    id={this.state.accountId}
                    navigation={navigation}
                    readUnreadmessage={''}
                    getChatList={this.getChatListPaginate}
                    accountId={this.state.accountId}
                    updateList={this.updateList}
                    lastMessageId={this.state.lastMessageId}
                    goBack={() => this.toggleAccountId() }
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
        </ThemeProvider>
      </NavigationMenu >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  filterSearchModal: {
    borderRadius: "12px",
    padding: "16px",
    boxShadow: "2px 4px 16px rgba(0, 0, 0, 0.12)",
    maxWidth: "262px",
  },
  filterSearchHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  filterSearchcheck: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBlock: "15px",
    gap: "25px"
  },
  searchAppBar: {
    background: "#FCFAF7",
    border: "1px solid #F0EBE0",
    fontSize: "1rem",
    borderRadius: "28px",
    display: "flex",
    width: "100%",
    minHeight: "48px"
  },
  searchIconContainer: {
    padding: "0rem 0.9rem",
    paddingInlineEnd: "0.5rem",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  searchIcon: {
    color: "#1C2324",
    opacity: 0.48
  },
  searchInputNative: {
    fontFamily: "IBM Plex Sans, Roboto, Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1rem",
    padding: 0,
    lineHeight: "1.5rem",
    width: "100%"
  },
  formImageContainer: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "100%",
    width: "100%",
    height: "200px",
  },
  cardImageContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FCFAF7",
    borderRadius: "100%",
    width: "100%",
    height: "200px",
  },
  hide: {
    display: "none"
  },
  badgeButton: {
    backgroundColor: "rgba(28, 35, 36, .56)",
    padding: "0.375rem",
  },
  badgeCardRoot: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FCFAF7",
    border: "1px solid #F0EBE0",
    borderRadius: "0.75rem",
    height: '200px'
  },
};
// Customizable Area End
