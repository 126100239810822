
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { serialize } from "object-to-formdata";

// Customizable Area End

export const configJSON = require("./config");
import {
  getLatLng,
  geocodeByAddress,
  geocodeByLatLng,
} from "react-google-places-autocomplete";

export interface Props {
  navigation: any;
  id: string;
  google: string,
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  deletePopup: number | null,
  errorPopup: string | null,
  isLoading: boolean;
  languageTab: string;
  errorMsg: string;
  loading: boolean;
  newsData: any[];
  eventsData: any[];
  newsAndEventsData: any[];
  expandData: boolean;
  selectedId: number;
  selectedEvent: string;
  shareText: string;
  newsAndEventsTitle: string;
  eventsSubtitle: string;
  newsSubtitle: string;
  savedSortAll: boolean;
  TokenValue: string | null,
  isEdit: null | number,
  savedIsDisabled: {
    state: boolean,
    district: boolean,
    taluka: boolean,
    village: boolean,
    fe: boolean,
    group: boolean,
    farmer: boolean,
  },
  savedState: any,
  savedDistrict: any,
  savedTaluka: any,
  savedVillage: any,
  savedFieldExecutive: any,
  savedGroup: any,
  savedFarmer: any,
  sortAll: boolean;
  anchorEl: null,
  isFilterOpen: boolean,
  initialStates: any[],
  initalDistricts: any[],
  initialTalukas: any[],
  initialVillages: any[],
  initialFieldExective: any[],
  initialGroups: any[],
  initialFarmers: any[],
  filteredStates: any[],
  filteredDistricts: any[],
  filteredTalukas: any[],
  filteredVillages: any[],
  filteredFieldExecutive: any[],
  filteredGroups: any[],
  filteredFarmers: any[],
  selectedState: any,
  selectedDistrict: any,
  image: string,
  selectedTaluka: any,
  selectedVillage: any,
  isNewsFormSubmitted: boolean,
  isEventFormSubmitted: boolean,
  selectedFieldExecutive: any,
  selectedGroup: any,
  selectedFarmer: any,
  expanded: boolean | string,
  stateInputValue: string,
  districtInputValue: string,
  talukaInputValue: string,
  villageInputValue: string,
  fieldExecutiveInputValue: string,
  groupInputValue: string,
  farmerInputValue: string,
  isDisabled: {
    state: boolean,
    district: boolean,
    taluka: boolean,
    village: boolean,
    fe: boolean,
    group: boolean,
    farmer: boolean,
  },
  formData: any,
  location: any,
  mapDialogOpen: boolean,
  clickedHindiLocation: string,
  clickedEnglishLocation: string,
  clickedGujaratiLocation: string,
  chips: any[]
  eventEngData: any[] | null,
  newsEngData: any[] | null,
  isShare: boolean,
  linkShare:string,
  messageHeading:string,
  popupTitle: string,
  eventsPages: number,
  newsPages: number
  // Customizable Area End
}

interface SS {
  id: any;
}

export interface FormDataType {
  events_news: any;
  description: string,
  event_date?: string,
  event_time?: string,
  image?: string | { file?: string  ,  url?: string },
  language: string,
  name: string,
  news_event_type: string,
  written_by?: string,
  events_news_accounts_attributes?: Array<{
    account_id?:  string,
    chat_id?: string,
  }>,
  id?: number,
  location?: string,
}

export default class NewsAndEventsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  map: any = null;
  apiRequestCallId: string = "";
  newsId: string = "";
  eventsId: string = "";
  newsAndEventsDataId: string = "";
  deleteNewAndEventsId: string = "";
  getStateData: string = "";
  getDistrictData: string = "";
  getTalukaData: string = "";
  getVillageData: string = "";
  getFieldExecutiveData: string = "";
  getGroupData: string = "";
  getFarmerData: string = "";
  createNewEventNewId: string = "";
  updateNewEventNewId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      deletePopup: null,
      errorPopup: null,
      isLoading: false,
      TokenValue: localStorage.getItem("tokenn"),
      image: "",
      isEdit: null,
      languageTab: "English",
      errorMsg: "",
      loading: false,
      newsData: [],
      eventsData: [],
      newsAndEventsData: [],
      expandData: false,
      selectedId: -1,
      selectedEvent: 'events',
      eventEngData: null,
      newsEngData: null,
      shareText: configJSON.shareText,
      newsAndEventsTitle: configJSON.newsAndEventsTitle,
      eventsSubtitle: configJSON.eventsSubtitle,
      newsSubtitle: configJSON.newsSubtitle,
      isEventFormSubmitted: false,
      isNewsFormSubmitted: false,
      //latest changes
      savedSortAll: false,
      savedIsDisabled: {
        state: false,
        district: false,
        taluka: false,
        village: false,
        fe: false,
        group: false,
        farmer: false,
      },
      savedState:
      {
        name: "Select State",
        id: "",
      },
      savedDistrict: {
        name: "Select District",
        id: "",
      },
      savedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      savedVillage: {
        name: "Select Village",
        id: "",
      },
      savedFieldExecutive: {
        user_name: "Select Field Executive",
        id: ""
      },
      savedGroup: {
        name: "Select Group",
        id: "",
      },
      savedFarmer: {
        first_name: "Select Farmer",
        id: "",
      },
      sortAll: false,
      anchorEl: null,
      isFilterOpen: false,
      initialStates: [],
      initalDistricts: [],
      initialTalukas: [],
      initialVillages: [],
      initialFieldExective: [],
      initialGroups: [],
      initialFarmers: [],
      filteredStates: [],
      filteredDistricts: [],
      filteredTalukas: [],
      filteredVillages: [],
      filteredFieldExecutive: [],
      filteredGroups: [],
      filteredFarmers: [],
      selectedState:
      {
        name: "Select State",
        id: "",
      },
      selectedDistrict: {
        name: "Select District",
        id: "",
      },
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
      selectedFieldExecutive: {
        first_name: "Select Field Executive",
        last_name: "",
        id: "",
      },
      selectedGroup: {
        name: "Select Group",
        id: "",
      },
      selectedFarmer: {
        first_name: "Select Farmer",
        id: "",
      },
      expanded: false,
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      fieldExecutiveInputValue: "",
      groupInputValue: "",
      farmerInputValue: "",
      isDisabled: {
        state: false,
        district: false,
        taluka: false,
        village: false,
        fe: false,
        group: false,
        farmer: false,
      },
      formData: {
        events_news: JSON.parse(JSON.stringify(configJSON.initalFormDataValue))
      },
      location: { latitude: "", longitude: "" },
      mapDialogOpen: false,
      clickedHindiLocation: "",
      clickedEnglishLocation: "",
      clickedGujaratiLocation: "",
      chips: [],
      isShare: false,
      linkShare:'',
      messageHeading:'',
      popupTitle:  'Error',
      eventsPages: 1,
      newsPages: 1
      //latest changes end

      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  //map data start


  getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position: { coords: {} }) => {
        this.setState({ location: position.coords });
      }
    );
  };
  handleMapValues(locationName: string) {

    switch (this.state.languageTab) {
      case 'English':
        this.setState({
          clickedEnglishLocation: locationName,
        })
        break;
      case 'Hindi':
        this.setState({
          clickedHindiLocation: locationName
        })
        break;
      case 'Gujarati':
        this.setState({
          clickedGujaratiLocation: locationName
        })
        break;
    }
  }

  handleMapClick = async (mapProps: any, map: any, clickEvent: any) => {
    const latitude = clickEvent.latLng.lat();
    const longitude = clickEvent.latLng.lng();
    const updatedFormData = { ...this.state.formData };

    try {
      const latLng = { lat: latitude, lng: longitude };
      const results = await geocodeByLatLng(latLng);

      if (results.length > 0) {
        const locationName = results[0].formatted_address;
        this.handleMapValues(locationName)
        this.setState({
          mapDialogOpen: false,
        }
        );
        updatedFormData.events_news[3].location = "";
        updatedFormData.events_news[4].location = "";
        updatedFormData.events_news[5].location = "";
      }
    } catch (error) {
      console.error('Error retrieving location:', error);
    }
  };

  handleGetDestinationList = (
    data: { [k: string]: string }
  ) => {
    geocodeByAddress(data.label)
      .then((results: google.maps.GeocoderResult[]) => getLatLng(results[0]))
      .then(({ lat, lng }: { lat: number; lng: number }) => {
        this.setState({
          location: {
            latitude: lat,
            longitude: lng
          }
        })
      }
      );
  };

  closeMapDialog() {
    this.setState({
      mapDialogOpen: !this.state.mapDialogOpen
    })
  }

  //map functionality ends
  getStateDataRes = (responseJson: any) => {
    this.setState({
      initialStates: responseJson,
      filteredStates: responseJson
    })
  }
  getDistrictDataRes = (responseJson: any) => {
    this.setState({
      initalDistricts: responseJson,
      filteredDistricts: responseJson
    })
  }
  getTalukaDataRes = (responseJson: any) => {
    this.setState({
      initialTalukas: responseJson,
      filteredTalukas: responseJson
    })
  }
  getVillageDataRes = (responseJson: any) => {
    this.setState({
      initialVillages: responseJson,
      filteredVillages: responseJson
    })
  }
  getFEDataRes = (responseJson: any) => {
    this.setState({
      initialFieldExective: JSON.parse(JSON.stringify(responseJson)),
      filteredFieldExecutive: JSON.parse(JSON.stringify(responseJson)),
    })
  }
  getGroupDataRes = (responseJson: any) => {
    this.setState({
      initialGroups: responseJson,
      filteredGroups: responseJson
    })
  }
  getFarmerDataRes = (responseJson: any) => {
    this.setState({
      initialFarmers: responseJson,
      filteredFarmers: responseJson
    })
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (apiResponse) {

        switch (apiRequestCallId) {
          case this.eventsId:
            this.handleEventsResponse(apiResponse);
            break;
          case this.newsId:
            this.handleNewsResponse(apiResponse);
            break;
          case this.deleteNewAndEventsId:
            this.deleteResponse(apiResponse);
            break;
          case this.createNewEventNewId:
          case this.updateNewEventNewId:
            this.updateNewsAndEventsResponse(apiResponse);
            break;
          case this.getStateData:
            this.getStateDataRes(apiResponse);
            break;
          case this.getDistrictData:
            this.getDistrictDataRes(apiResponse);
            break;
          case this.getTalukaData:
            this.getTalukaDataRes(apiResponse);
            break;
          case this.getVillageData:
            this.getVillageDataRes(apiResponse);
            break;
          case this.getFieldExecutiveData:
            this.getFEDataRes(apiResponse);
            break;
          case this.getGroupData:
            this.getGroupDataRes(apiResponse);
            break;
          case this.getFarmerData:
            this.getFarmerDataRes(apiResponse);
            break;
          case this.createNewEventNewId:
            console.log(apiResponse);
            break;
        }
      } else {
        this.handleErrorResponse(errorReponse);
      }

    }

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getStates();
    this.getDistricts();
    this.getTalukas();
    this.getVillages();
    this.getGroups();
    this.getFieldExecutives();
    this.getFarmers();
    this.getEvents();
    this.getNews();
    this.getCurrentLocation();
    // Customizable Area End
  }
  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<S>,
    snapshot?: SS | undefined
  ): void {
    // Customizable Area Start
    if (prevState.selectedState !== this.state.selectedState) {
      this.getDistricts()
    }
    if (prevState.selectedDistrict !== this.state.selectedDistrict) {
      this.getTalukas()
    }
    if (prevState.selectedTaluka !== this.state.selectedTaluka) {
      this.getVillages()
    }
    if (prevState.selectedVillage !== this.state.selectedVillage) {

      this.getFieldExecutives()
    }
    if (prevState.selectedVillage !== this.state.selectedVillage) {

      this.getGroups()
    }
    if (prevState.selectedFieldExecutive !== this.state.selectedFieldExecutive ) {
      this.getFarmers()
    }
    // Customizable Area End
  }
  addMapLocationToFormdata() {
    const updatedFormData = { ...this.state.formData };
    updatedFormData.events_news[3].location = this.state.formData.events_news[3].location || this.state.clickedEnglishLocation;
    updatedFormData.events_news[4].location = this.state.formData.events_news[4].location || this.state.clickedHindiLocation;
    updatedFormData.events_news[5].location = this.state.formData.events_news[5].location || this.state.clickedGujaratiLocation;
    return updatedFormData
  }

  checkValidation = (data?: Array<FormDataType>, selectedEvent?: string) => {
    if(!data || !selectedEvent ) {
      return undefined;
    }

    return data.find((bodyData) => {
      if(!bodyData.name || !bodyData.language || !bodyData.news_event_type || !bodyData.image){
        document.getElementById("name")?.scrollIntoView({behavior: 'smooth'})
         return true;
      }
      if ( !bodyData.description || !bodyData.event_date  || (selectedEvent === "news" ? !bodyData.written_by : (!bodyData.event_time || !bodyData.location))) {
        document.getElementById("description")?.scrollIntoView({behavior: 'smooth'})
        return true;
      }
  
      if (bodyData.event_date.toString() === "Invalid Date" || bodyData.event_time?.toString() === "Invalid Date") {
        this.showAlert(configJSON.errorTitle, configJSON.dateInvalid)
        return true;
      }
  
      return false;
    })
    
  }

  handleShareLink =(eventsObj?:any) => {
    const shareLink = configJSON.newsAndEventsShareLink + eventsObj?.attributes?.news_event_type +'/'+ eventsObj?.id
    const heading = `${eventsObj?.attributes?.news_event_type} : `+eventsObj?.attributes?.name
    this.setState({
      isShare : !this.state.isShare,
      linkShare:shareLink,
      messageHeading:heading
    })   
  }

  chipsList = () =>  this.state.chips.map((data) => data?.chat_type ? ({ chat_id: data.id }) : ({ account_id: data.id }))
 
  handleSubmitNewsEventsForm() {
    
    const { selectedEvent, formData, languageTab, isEdit } = this.state
   
    const type = selectedEvent === "news" ? "News" : "Event";
    this.setState({ isEventFormSubmitted: selectedEvent === "events" , isNewsFormSubmitted : selectedEvent === "news", isLoading: true})

    const updatedFormData = this.addMapLocationToFormdata();

    const bodyData: Array<FormDataType> = formData.events_news.filter((data: any) => data.news_event_type === type)
    const emptySection =  this.checkValidation(bodyData, selectedEvent);

    const sizeValidation = this.handleImageErrorText(selectedEvent);
    if(!bodyData || emptySection || sizeValidation) {
        this.setState({ languageTab: emptySection ? emptySection?.language : languageTab, isLoading: false })
        return null;
    }
  
    bodyData.forEach((formValues, index) => {
      bodyData[index].event_time = moment(formValues.event_time).format('hh:mm a')
      bodyData[index].event_date= moment(formValues.event_date).format("DD/MM/YYYY")
      bodyData[index].image = typeof(formValues.image) !== "string" ? (formValues.image?.file || formValues.image?.url) : "";

      if(formValues.news_event_type === "Event"){
        bodyData[index].events_news_accounts_attributes = this.chipsList();
      }
    } )
    
    if (isEdit) {      
      this.updateNewEventNew(serialize({ events_news: bodyData}, { indices: true, allowEmptyArrays: true }))
      return null
    } 
      this.createNewEventNew(serialize({ events_news: bodyData}, { indices: true, allowEmptyArrays: true }));
  }

  handleHelperTextEvent(updatedKey: any, key: any) {
    switch (this.state.languageTab) {
      case 'English':
        if (!this.state.formData.events_news[3][key]) {
          return `${updatedKey} is required`
        }
        break;
      case 'Hindi':
        if (!this.state.formData.events_news[4][key]) {
          return `${updatedKey} is required`
        }
        break;
      case 'Gujarati':
        if (!this.state.formData.events_news[5][key]) {
          return `${updatedKey} is required`
        }
        break;
    }
  }

  handleHelperTextNews(updatedKey: any, key: any) {
    switch (this.state.languageTab) {
      case 'English':
        if (!this.state.formData.events_news[0][key]) {
          return `${updatedKey} is required`
        }
        break;
      case 'Hindi':
        if (!this.state.formData.events_news[1][key]) {
          return `${updatedKey} is required`
        }
        break;
      case 'Gujarati':
        if (!this.state.formData.events_news[2][key]) {
          return `${updatedKey} is required`
        }
        break;
    }
  }
  handleHelperText(key: any, event_type: string) {
    let upperCaseStr = key.charAt(0).toUpperCase() + key.slice(1)
    if (upperCaseStr === "Event_date") {
      upperCaseStr = "Event Date"
    }
    else
      if (upperCaseStr === "Event_time") {
        upperCaseStr = "Event Time"
      }
      else
        if (upperCaseStr === "Written_by") {
          upperCaseStr = "Written by"
        }
    if (event_type === "event" && this.state.isEventFormSubmitted) {
      return this.handleHelperTextEvent(upperCaseStr, key)
    }
    else
      if (event_type === "news" && this.state.isNewsFormSubmitted) {
        return this.handleHelperTextNews(upperCaseStr, key)
      }
  }
  handleImageErrorText(
    event_type: string
  ) {
    if (!this.state.formData.events_news[3]?.image && this.state.isEventFormSubmitted && event_type === "event") {
      return 'Image is required'
    }
    else  if (!this.state.formData.events_news[0]?.image && this.state.isNewsFormSubmitted && event_type === "news")  {
      return 'Image is required'
    } 
    else if((this.state.formData.events_news[0]?.image?.file?.size >= configJSON.imageLimit_250) || (this.state.formData.events_news[3]?.image?.file?.size >=  configJSON.imageLimit_250)){
      return 'Max allowed size is 250MB'
    }
    return null;
  }
  handleMapErrorText(

  ) {
    switch (this.state.languageTab) {
      case 'English':
        if (!(this.state.formData.events_news[3]?.location || this.state.clickedEnglishLocation) && this.state.isEventFormSubmitted) {
          return 'Location is required'
        }
        break;
      case 'Hindi':
        if (!(this.state.formData.events_news[4]?.location || this.state.clickedHindiLocation) && this.state.isEventFormSubmitted) {
          return 'Location is required'
        }
        break;
      case 'Gujarati':
        if (!(this.state.formData.events_news[5]?.location || this.state.clickedGujaratiLocation) && this.state.isEventFormSubmitted) {
          return 'Location is required'
        }
        break;
    }
  }
  handleErrorTextEvent(key: any) {

    switch (this.state.languageTab) {
      case 'English':
        if (!this.state.formData.events_news[3][key]) {
          return true
        }
        break;
      case 'Hindi':
        if (!this.state.formData.events_news[4][key]) {
          return true
        }
        break;
      case 'Gujarati':
        if (!this.state.formData.events_news[5][key]) {
          return true
        }
        break;
    }
  }
  handleErrorTextNews(key: any) {
    switch (this.state.languageTab) {
      case 'English':
        if (!this.state.formData.events_news[0][key]) {
          return true
        }
        break;
      case 'Hindi':
        if (!this.state.formData.events_news[1][key]) {
          return true
        }
        break;
      case 'Gujarati':
        if (!this.state.formData.events_news[2][key]) {
          return true
        }
        break;
    }
  }
  handleErrorText(key: any, event_type: string) {
    if (event_type === "event" && this.state.isEventFormSubmitted) {
      return this.handleErrorTextEvent(key)
    }
    else
      if (event_type === "news" && this.state.isNewsFormSubmitted) {
        return this.handleErrorTextNews(key)
      }

  }
  handleEventsFormValue(key: any) {
    switch (this.state.languageTab) {
      case 'English':
        return this.state.formData.events_news[3][key]
      case 'Hindi':
        return this.state.formData.events_news[4][key]
      case 'Gujarati':
        return this.state.formData.events_news[5][key]
    }
  }
  handleEventsLocationFormValue() {
    switch (this.state.languageTab) {
      case 'English':
        return this.state.clickedEnglishLocation || this.state.formData.events_news[3].location
      case 'Hindi':
        return this.state.clickedHindiLocation || this.state.formData.events_news[4].location
      case 'Gujarati':
        return this.state.clickedGujaratiLocation || this.state.formData.events_news[5].location
    }
  }
  handleNewsFormValue(key: any) {
    switch (this.state.languageTab) {
      case 'English':
        return this.state.formData.events_news[0][key]
      case 'Hindi':
        return this.state.formData.events_news[1][key]
      case 'Gujarati':
        return this.state.formData.events_news[2][key]
    }
  }
  handleEventTextFieldChange(e: any, key: any, event_type: string, fieldCommon: boolean) {

    const { formData } = this.state;
    const updatedFormData = { ...formData };
    if (key === "location") {
      this.setState({
        clickedEnglishLocation: "",
        clickedGujaratiLocation: "",
        clickedHindiLocation: ""
      })
    }
    if (event_type === "event") {
      this.setState({
        isEventFormSubmitted: false,
      })
      if (fieldCommon) {
        updatedFormData.events_news[3][key] = e?.target?.value;
        updatedFormData.events_news[4][key] = e?.target?.value;
        updatedFormData.events_news[5][key] = e?.target?.value;
        this.setState({
          formData: updatedFormData
        })
      }
      else {
        switch (this.state.languageTab) {
          case 'English':
            this.setState((prevState) => {
              updatedFormData.events_news[3][key] = e?.target?.value;
              return { formData: updatedFormData };
            });
            break;
          case 'Hindi':
            this.setState((prevState) => {
              updatedFormData.events_news[4][key] = e?.target?.value;
              return { formData: updatedFormData };
            });
            break;
          case 'Gujarati':
            this.setState((prevState) => {
              updatedFormData.events_news[5][key] = e?.target?.value;
              return { formData: updatedFormData };
            });
            break;
        }
      }
    }
    else {
      this.setState({
        isNewsFormSubmitted: false,
      })
      if (fieldCommon) {
        updatedFormData.events_news[0][key] = e?.target?.value;
        updatedFormData.events_news[1][key] = e?.target?.value;
        updatedFormData.events_news[2][key] = e?.target?.value;
        this.setState({
          formData: updatedFormData
        })
      }
      else {
        switch (this.state.languageTab) {
          case 'English':
            this.setState((prevState) => {
              updatedFormData.events_news[0][key] = e?.target?.value;
              return { formData: updatedFormData };
            });
            break;
          case 'Hindi':
            this.setState((prevState) => {
              updatedFormData.events_news[1][key] = e?.target?.value;
              return { formData: updatedFormData };
            });
            break;
          case 'Gujarati':
            this.setState((prevState) => {
              updatedFormData.events_news[2][key] = e?.target?.value;
              return { formData: updatedFormData };
            });
            break;
        }
      }
    }
  }
  handleEventDateChange(e: any, key: any, event_type: string, fieldCommon: boolean) {

    const { formData } = this.state;
    const updatedFormData = { ...formData };
    if (event_type === "event") {
      this.setState({
        isEventFormSubmitted: false
      })
      if (fieldCommon) {
        updatedFormData.events_news[3][key] = new Date(e);
        updatedFormData.events_news[4][key] = new Date(e);
        updatedFormData.events_news[5][key] = new Date(e);
        this.setState({
          formData: updatedFormData
        })
      }
      else {
        switch (this.state.languageTab) {
          case 'English':
            this.setState((prevState) => {

              updatedFormData.events_news[3][key] = new Date(e);
              return { formData: updatedFormData };
            });
            break;
          case 'Hindi':
            this.setState((prevState) => {

              updatedFormData.events_news[4][key] = new Date(e)
              return { formData: updatedFormData };
            });
            break;
          case 'Gujarati':
            this.setState((prevState) => {

              updatedFormData.events_news[5][key] = new Date(e);
              return { formData: updatedFormData };
            });
            break;
        }
      }
    }
    else {
      this.setState({
        isNewsFormSubmitted: false
      })
      if (fieldCommon) {
        updatedFormData.events_news[0][key] = new Date(e);
        updatedFormData.events_news[1][key] = new Date(e);
        updatedFormData.events_news[2][key] = new Date(e);
        this.setState({
          formData: updatedFormData
        })
      }
      else {
        switch (this.state.languageTab) {
          case 'English':
            this.setState((prevState) => {
              updatedFormData.events_news[0][key] = new Date(e);
              return { formData: updatedFormData };
            });
            break;
          case 'Hindi':
            this.setState((prevState) => {
              updatedFormData.events_news[1][key] = new Date(e)
              return { formData: updatedFormData };
            });
            break;
          case 'Gujarati':
            this.setState((prevState) => {
              updatedFormData.events_news[2][key] = new Date(e);
              return { formData: updatedFormData };
            });
            break;
        }
      }
    }
  }
  handleEventTimeChange(e: any, key: any, fieldCommon: boolean) {
    this.setState({
      isEventFormSubmitted: false
    })
    const { formData } = this.state;
    const updatedFormData = { ...formData };
    if (fieldCommon) {
      updatedFormData.events_news[3][key] = new Date(e);
      updatedFormData.events_news[4][key] = new Date(e);
      updatedFormData.events_news[5][key] = new Date(e);
      this.setState({
        formData: updatedFormData
      })
    }
    else {
      switch (this.state.languageTab) {
        case 'English':
          this.setState((prevState) => {
            updatedFormData.events_news[3][key] = new Date(e);
            return { formData: updatedFormData };
          });
          break;
        case 'Hindi':
          this.setState((prevState) => {
            updatedFormData.events_news[4][key] = new Date(e)
            return { formData: updatedFormData };
          });
          break;
        case 'Gujarati':
          this.setState((prevState) => {
            updatedFormData.events_news[5][key] = new Date(e);
            return { formData: updatedFormData };
          });
          break;
      }
    }

  }

  handleStateSearch(e: any) {
    const query = e?.target?.value?.toLowerCase() || "";
    this.setState({ stateInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initialStates.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialStates;
    this.setState({
      filteredStates: filteredArray,
    });
  }
  handleDistrictSearch(e: any) {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ districtInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initalDistricts.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initalDistricts;
    this.setState({
      filteredDistricts: filteredArray,
    });
  }
  handleTalukaSearch(e: any) {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ talukaInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initialTalukas.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialTalukas;
    this.setState({
      filteredTalukas: filteredArray,
    });
  }

  handleVillageSearch(e: any) {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ villageInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initialVillages.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialVillages;
    this.setState({
      filteredVillages: filteredArray,
    });
  }
  handleGroupSearch(e: any) {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ groupInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initialGroups.filter((value: any) =>
        value.name.toLowerCase().includes(query)
      )
      : this.state.initialGroups;
    this.setState({
      filteredGroups: filteredArray,
    });
  }

  handleFieldExectiveSearch(e: any) {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ fieldExecutiveInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initialFieldExective.filter((value: any) =>
        `${value.first_name} ${value.last_name}`.toLowerCase().includes(query)
      )
      : this.state.initialFieldExective;
    this.setState({
      filteredFieldExecutive: JSON.parse(JSON.stringify(filteredArray)),
    });
  }

  handleFarmerSearch(e: any) {
    const query = e?.target?.value?.toLowerCase();
    this.setState({ farmerInputValue: e?.target?.value });
    const filteredArray = query
      ? this.state.initialFarmers.filter((value: any) =>
        value.first_name.toLowerCase().includes(query)
      )
      : this.state.initialFarmers;
    this.setState({
      filteredFarmers: filteredArray,
    });
  }

 isFilterApplied = () =>
  !(this.state.selectedState.id ||
    this.state.selectedDistrict.id ||
    this.state.selectedTaluka.id ||
    this.state.selectedVillage.id,
  this.state.selectedGroup.id ||
    this.state.selectedFarmer.id ||
    this.state.sortAll);


  handleSubmitAccordion() {
  this.setState({
    expanded: false,
    savedState: this.state.selectedState,
    savedDistrict: this.state.selectedDistrict,
    savedTaluka: this.state.selectedTaluka,
    savedVillage: this.state.selectedVillage,
    savedFieldExecutive: this.state.selectedFieldExecutive,
    savedGroup: this.state.selectedGroup,
    savedFarmer: this.state.selectedFarmer,
    savedIsDisabled: this.state.isDisabled,
    savedSortAll: this.state.sortAll,
  });

  let chipsArr1: any[] = [];

  if(this.state.sortAll || this.state.selectedState.id || this.state.selectedTaluka.id || this.state.selectedDistrict.id || this.state.selectedVillage.id){
    chipsArr1 = this.state.initialFarmers.concat(this.state.initialFieldExective, this.state.initialGroups);
  }
  
  if (this.state.selectedGroup.name !== "Select Group") {
    chipsArr1  = [this.state.selectedGroup];
  }

  if (this.state.selectedFieldExecutive.first_name !== "Select Field Executive") {
    chipsArr1 = [this.state.selectedFieldExecutive].concat(this.state.initialFarmers)
  }
  
  if (this.state.selectedFarmer.first_name !== "Select Farmer") {
    chipsArr1 = [this.state.selectedFarmer]
  }

  const chipsArr = this.state.chips.concat([...chipsArr1])

  this.setState(() => ({
    chips: chipsArr.filter((obj, index) => 
        index === chipsArr.findIndex(objItem => obj.id === objItem.id)
    ),
  }), () => this.handleClear());
  }

  getTypeChip = (type: number | undefined, role?: boolean) => {
    if(!type) return role ? "group" : "name" ;
    if(type === 1) return role ?  "FE" : "user_name";
    return role ? 'farmer' : "first_name";
  }


  handleChangePanel = (panel: any) => (event: any, newExpanded: any) => {
    this.setState({
      expanded: newExpanded ? panel : false,
      filteredStates: this.state.initialStates,
      filteredDistricts: this.state.initalDistricts,
      filteredTalukas: this.state.initialTalukas,
      filteredVillages: this.state.initialVillages,
      filteredFieldExecutive: this.state.initialFieldExective,
      filteredGroups: this.state.initialGroups,
      filteredFarmers: this.state.initialFarmers,
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      fieldExecutiveInputValue: "",
      groupInputValue: "",
      farmerInputValue: "",
    });
  };
  handleClear = (isOpen?: boolean) => {
    this.setState({
      sortAll: false,
      filteredStates: this.state.initialStates,
      filteredDistricts: this.state.initalDistricts,
      filteredTalukas: this.state.initialTalukas,
      filteredVillages: this.state.initialVillages,
      filteredFieldExecutive: this.state.filteredFieldExecutive,
      filteredGroups: this.state.initialGroups,
      filteredFarmers: this.state.initialFarmers,
      expanded: false,
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      fieldExecutiveInputValue: "",
      groupInputValue: "",
      farmerInputValue: "",
      isFilterOpen: !!isOpen,
      selectedState:
      {
        name: "Select State",
        id: "",
      },
      selectedDistrict: {
        name: "Select District",
        id: "",
      },
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      },
      selectedVillage: {
        name: "Select Village",
        id: "",
      },
      selectedFieldExecutive: {
        first_name: "Select Field Executive",
        last_name: "",
        id: "",
      },
      selectedGroup: {
        name: "Select Group",
        id: "",
      },
      selectedFarmer: {
        first_name: "Select Farmer",
        id: "",
      },
      isDisabled: {
        state: false,
        district: false,
        taluka: false,
        village: false,
        fe: false,
        group: false,
        farmer: false,
      },
    });
  };
  handleChangeField(key1: any, key2: any, key3: any, key4: any, key5: any, key6: any, key7: any) {
    const isDisabledInitialValues = {
      state: true,
      district: true,
      taluka: true,
      village: true,
      fe: true,
      group: true,
      farmer: true,
    }
    this.setState({
      isDisabled: {
        ...isDisabledInitialValues,
        ...(key1 && { [key1]: false }),
        ...(key2 && { [key2]: false }),
        ...(key3 && { [key3]: false }),
        ...(key4 && { [key4]: false }),
        ...(key5 && { [key5]: false }),
        ...(key6 && { [key6]: false }),
        ...(key7 && { [key7]: false }),

      }
    })

  }
  handleChangeStateField() {
    this.handleChangeField("", "state", "district", "", "fe", "group", "farmer")
  }
  handleAllCheck() {
    this.setState({ sortAll: !this.state.sortAll, }, () => {

      this.setState({
        isDisabled: {
          state: this.state.sortAll,
          district: this.state.sortAll,
          taluka: this.state.sortAll,
          village: this.state.sortAll,
          fe: this.state.sortAll,
          group: this.state.sortAll,
          farmer: this.state.sortAll,
        },
      })
      if (this.state.sortAll) {
        this.handleClear()
        this.setState({
          isDisabled: {
            state: this.state.sortAll,
            district: this.state.sortAll,
            taluka: this.state.sortAll,
            village: this.state.sortAll,
            fe: this.state.sortAll,
            group: this.state.sortAll,
            farmer: this.state.sortAll,
          },
          sortAll: true,
          isFilterOpen: true,
        })
      }
    })


  }
  handleChangeDistrictField() {
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "", "district", "taluka", "", "fe", "group", "farmer")

  }
  handleChangeTalukaField() {
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "", this.state.selectedDistrict.name === "Select District" ? "" : "district", "taluka", "village", "fe", "group", "farmer")
  }
  handleChangeVillageField() {
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "", this.state.selectedDistrict.name === "Select District" ? "" : "district", this.state.selectedTaluka.name === "Select Taluka" ? "" : "taluka", "village", "fe", "group", "farmer")
  }

  handleChangeFieldExecutiveField() {
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "", this.state.selectedDistrict.name === "Select District" ? "" : "district", this.state.selectedTaluka.name === "Select Taluka" ? "" : "taluka", this.state.selectedVillage.name === "Select Village" ? "" : "village", "fe", "group", "farmer")
  }
  handleChangeGroupField() {
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "", this.state.selectedDistrict.name === "Select District" ? "" : "district", this.state.selectedTaluka.name === "Select Taluka" ? "" : "taluka", this.state.selectedVillage.name === "Select Village" ? "" : "village",  'fe', "group",  'farmer' )
  }
  handleChangeFarmerField() {
    this.handleChangeField(this.state.selectedState.name !== "Select State" ? "state" : "", this.state.selectedDistrict.name === "Select District" ? "" : "district", this.state.selectedTaluka.name === "Select Taluka" ? "" : "taluka", this.state.selectedVillage.name === "Select Village" ? "" : "village", "fe", "group", "farmer")
  }

  opacityStateCalculator() {
    if (this.state.selectedState.name === "Select State") {
      return 0.48
    }
    else {
      return 1
    }
  }
  opacityDistrictCalculator() {

    if (this.state.selectedDistrict.name === "Select District") {
      return 0.48
    }
    else {
      return 1
    }
  }
  opacityTalukaCalculator() {

    if (this.state.selectedTaluka.name === "Select Taluka") {

      return 0.48
    }
    else {

      return 1
    }
  }
  opacityVillageCalculator() {

    if (this.state.selectedVillage.name === "Select Village") {

      return 0.48
    }
    else {

      return 1
    }
  }
  opacityFieldExecutiveCalculator() {

    if (this.state.selectedFieldExecutive.first_name === "Select Field Executive") {

      return 0.48
    }
    else {

      return 1
    }
  }
  opacityGroupCalculator() {

    if (this.state.selectedGroup.name === "Select Group") {
      return 0.48
    }
    else {
      return 1
    }
  }
  opacityFarmerCalculator() {

    if (this.state.selectedFarmer.first_name === "Select Farmer") {
      return 0.48
    }
    else {
      return 1
    }
  }
  closeFilter() {
    this.setState({
      isFilterOpen: false,
      expanded: false,
      selectedState: this.state.savedState,
      selectedDistrict: this.state.savedDistrict,
      selectedTaluka: this.state.savedTaluka,
      selectedVillage: this.state.savedVillage,
      selectedFieldExecutive: this.state.savedFieldExecutive,
      selectedGroup: this.state.savedGroup,
      selectedFarmer: this.state.savedFarmer,
      sortAll: this.state.savedSortAll,
      isDisabled: this.state.savedIsDisabled,
      stateInputValue: "",
      districtInputValue: "",
      talukaInputValue: "",
      villageInputValue: "",
      filteredStates: this.state.initialStates,
      filteredDistricts: this.state.initalDistricts,
      filteredTalukas: this.state.initialTalukas,
      filteredVillages: this.state.initialVillages,
      filteredFieldExecutive: this.state.filteredFieldExecutive,
      filteredGroups: this.state.initialGroups,
      filteredFarmers: this.state.initialFarmers,
    })

  }
  openFilter() {
    this.setState({
      isFilterOpen: true,

    })

  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start

  getIndexField = (language: string, type: string) => {
    return this.state.formData.events_news.findIndex((data: FormDataType) => data.language === language && data.news_event_type === type)
  }

  handleTabEnglishBorder() {
    if (this.state.languageTab === "English") {
      return "1px solid #DF5200"
    }
    else {
      return "1px solid #1C2324"
    }
  }
  handleTabGujaratiBorder() {
    if (this.state.languageTab === "Gujarati") {
      return "1px solid #DF5200"
    }
    else {
      return "1px solid #1C2324"
    }

  }
  handleTabHindiBorder() {
    if (this.state.languageTab === "Hindi") {
      return "1px solid #DF5200"
    }
    else {
      return "1px solid #1C2324"
    }
  }
  handleTabChange(e: string) {
    this.setState({
      languageTab: e
    })
  }

  handleAccordion = (id: number,) => {
    this.setState(({ selectedId }) => ({
      selectedId: selectedId === id ? -1 : id
    }))
  }

  cancelUpdate = () => this.setState({ formData: Object.assign({}, { events_news: JSON.parse(JSON.stringify(configJSON.initalFormDataValue)) }), isEdit: null })

  handleEventChange = (e: any) => this.setState({ selectedEvent: e?.target?.value })

  getEvents = () => {
    const endPoint = `${configJSON.eventsApiEndpoint}?per_page=5&page=${this.state.eventsPages}`;
    this.eventsId = this.apiCall(configJSON.validationApiMethodType, endPoint)
  }

  getNews = () => {
    const endPoint = `${configJSON.newsApiEndpoint}?per_page=5&page=${this.state.newsPages}`;
    this.newsId = this.apiCall(configJSON.validationApiMethodType, endPoint)
  }

  deleteEvent = (delId: number) => {
    const endPoint = `${configJSON.newsandeventsApiEndpoint}/${delId}`
    this.deleteNewAndEventsId = this.apiCall(configJSON.deleteApiMethodType, endPoint)
  }

  editSection = (id: number, type: string) => {

    const { eventsData, newsData, formData } = this.state
    const dataList = type === configJSON.events ? eventsData : newsData 
    const editData: Array<any> | null =   dataList.filter((data) => data.id  === id);

    const isEdit = editData[0]?.attributes?.events_news?.data[0]?.attributes?.admin_event_id;


    const updateData = formData.events_news.map((data: FormDataType) => {
        let updateSection  = null
        editData[0]?.attributes?.events_news?.data?.find((apiData: any) => {
          if(apiData.attributes.news_event_type === data.news_event_type && apiData.attributes.language === data.language) {
            updateSection = JSON.parse(JSON.stringify(apiData?.attributes));
            updateSection.id = apiData.id;
            if(type === configJSON.events){
              delete updateSection.events_news_accounts
            }        
            return true;
          }
        });
        return  JSON.parse(JSON.stringify(updateSection))  || data
    })    

    let chips = []

    if(type === configJSON.events){
      const  userIds = editData[0]?.attributes?.events_news?.data[0]?.attributes?.events_news_accounts?.filter(({ account_id }: { account_id :  string }) => account_id )?.map(({ account_id }: { account_id :  string }) => account_id );
      const  chartIds = editData[0]?.attributes?.events_news?.data[0]?.attributes?.events_news_accounts?.filter(({ chat_id }: { chat_id :  string }) => chat_id )?.map(({ chat_id }: { chat_id :  string }) => chat_id );
  
      chips = this.state.filteredFieldExecutive.concat(this.state.filteredFarmers).filter((data) =>  userIds?.includes(data.id));
      chips =  chips.concat(this.state.filteredGroups?.filter((data) => chartIds?.includes(data?.id)));
    }
    
    this.setState({ languageTab: 'English', selectedEvent: type === configJSON.events ? 'events' : 'news', formData: {events_news: updateData}, isEdit, chips })
  }

  updateNewEventNew = (body: any) => {
    this.updateNewEventNewId = this.apiCall(configJSON.apicallTypePatch, `${configJSON.newsandeventsApiEndpoint}/${this.state.isEdit}`, body,true)
  }

  createNewEventNew = (body: any) => {
    this.createNewEventNewId = this.apiCall(configJSON.exampleAPiMethod, configJSON.newsandeventsApiEndpoint, body,true)
  }

  //responses functions

  handleEventsResponse = (response: any) => {
    if(response.errors) {
      this.handleErrorResponse(response);
    } else {
      this.setState(prev => ({
        ...prev,
        eventsData: [...prev.eventsData, ...response.data],
        eventsPages: prev.eventsPages + 1
      }));
    }
  }

  handleNewsResponse = (response: any) => {
    if(response.errors) {
      this.handleErrorResponse(response);
    } else {
      this.setState(prev => ({
        ...prev,
        newsData: [...prev.newsData, ...response.data],
        newsPages: prev.newsPages + 1
      }));
    }
  }    

  deleteResponse = (success: any) => {
    if(success.deleted) {
      this.setState(prevState => ({
        eventsData: prevState.eventsData.filter(event => event.id != prevState.deletePopup),
        newsData: prevState.newsData.filter(news => news.id != prevState.deletePopup)
      }), () => this.setState({ deletePopup: null }));
      this.resetFormState();
    } else {
      this.setState({ deletePopup: null });
    }
  }

  updateNewsAndEventsResponse = (delRes: any) => {
    this.setState({ isLoading: false, languageTab: "English",  })        
      if(this.state.selectedEvent === 'events') {
        const { eventsData } = this.state;
        const indexToUpdate = eventsData.findIndex(item => item.id === delRes.data.id);

        const updatedEventsData = [...eventsData];
        updatedEventsData[indexToUpdate] = delRes.data;
  
        this.setState({
          eventsData: updatedEventsData
        });
        
      } else {
        const { newsData } = this.state;
        const indexToUpdate = newsData.findIndex(item => item.id === delRes.data.id);

        const updatednewsData = [...newsData];
        updatednewsData[indexToUpdate] = delRes.data;
  
        this.setState({
          newsData: updatednewsData
        });
      }
      this.resetFormState();
  };

  resetFormState = () => {
    this.setState({
      isEdit: null,
      isEventFormSubmitted: false,
      isNewsFormSubmitted: false,
      chips: [],
      formData: Object.assign({},
        { events_news: configJSON.initalFormDataValue }
      ),
      popupTitle: "Success",
      errorPopup: `${ this.state.isEdit ? "Update": "Added"} successfully` 
    }, () => window.scrollTo({ top: 0 , behavior: 'smooth'}));
  }

  // error responses functions
  handleErrorResponse = (errorReponse: any) => {
    if (errorReponse === undefined) {
      this.setState({
        errorMsg: "Something went wrong",
        loading: false
      });
    } else {
      this.setState({
        errorMsg: errorReponse,
        loading: false
      });
    }
  }
  getStates() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStateData = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getStateList}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getDistricts() {
    let params = this.state.selectedState.id
    this.setState({
      selectedDistrict: {
        name: "Select District",
        id: "",
      }
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDistrictData = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDistrictList}?state_id=${params}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getTalukas() {
    let params = this.state.selectedDistrict.id
    this.setState({
      selectedTaluka: {
        name: "Select Taluka",
        id: "",
      }
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTalukaData = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getTalukaList}?district_id=${params}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getVillages() {
    let params = this.state.selectedTaluka.id
    this.setState({
      selectedVillage: {
        name: "Select Village",
        id: "",
      }
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVillageData = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getVillageList}?taluka_id=${params}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getGroups() {
    //fix reuired
    let params1 = this.state.selectedState.id
    let params2 = this.state.selectedDistrict.id
    let params3 = this.state.selectedTaluka.id
    let params4 = this.state.selectedVillage.id
    this.setState({
      selectedGroup: {
        name: "Select Group",
        id: "",
      }
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGroupData = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      //fix reuired
      `${configJSON.getGroupList}?state_id=${params1}&district_id=${params2}&taluka_id=${params3}&village_id=${params4}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getFieldExecutives() {
    let params1 = this.state.selectedState.id
    let params2 = this.state.selectedDistrict.id
    let params3 = this.state.selectedTaluka.id
    let params4 = this.state.selectedVillage.id
    this.setState({
      selectedFieldExecutive: {
        first_name: "Select Field Executive",
        last_name: "",
        id: "",
      }
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFieldExecutiveData = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getFieldExecutiveList}?state_id=${params1}&district_id=${params2}&taluka_id=${params3}&village_id=${params4}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getFarmers() {
    //fix reuired
    let params1 = this.state.selectedState.id
    let params2 = this.state.selectedDistrict.id
    let params3 = this.state.selectedTaluka.id
    let params4 = this.state.selectedVillage.id
    let params5 = this.state.selectedFieldExecutive.id
    let group = this.state.selectedGroup.id
    
    this.setState({
      selectedFarmer: {
        first_name: "Select Farmer",
        id: "",
      }
    })
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.TokenValue
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFarmerData = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      //fix reuired
      `${configJSON.getFarmerList}?state_id=${params1}&district_id=${params2}&taluka_id=${params3}&village_id=${params4}&field_executive_id=${params5}&group=${group}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  parseImages = (img?: string): string | undefined => {
    if (!img) return undefined;
    return img;
  };
  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  async handleImageUpload(event: any, event_type: string) {

    const selectedFile = event.target.files[0];
    
    const url = await this.getBase64(selectedFile)
    this.setState({
      isEventFormSubmitted: false
    })
    const { formData } = this.state;
    const updatedFormData = { ...formData };
    updatedFormData.events_news[event_type === "event" ? 3 : 0].image = {
      file: selectedFile,
      url: url,
    };
    updatedFormData.events_news[event_type === "event" ? 4 : 1].image = {
      file: selectedFile,
      url: url,
    };
    updatedFormData.events_news[event_type === "event" ? 5 : 2].image = {
      file: selectedFile,
      url: url,
    };

    this.setState({
      formData: updatedFormData
    })
  }


  apiCall = (method: string, endpoint: string, body?: string, isContentFormData?:boolean) => {
    const headerJson = {
      token: this.state.TokenValue,
      "Content-Type": isContentFormData ? "multipart/form-data": "application/json",
    };

    const headerFormData = {
      token: this.state.TokenValue
    };
    const header = isContentFormData ? headerFormData : headerJson

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;

  }
  //Changes for Chip text field
  handleChipDelete = (chipToDelete: any) => () => {
    this.setState((prevState) => ({
      chips: prevState.chips.filter((chip) => chip !== chipToDelete),
    }));
  };

  chipsPlaceholder = () => {
    return this.state.chips.length === 0 ? "Search by name of person, groups" : ""
  }

  toggleDeletePopup = (deletePopup: number | null) => { 
    this.setState({ deletePopup })
  }

  toggleErrorPopup = (errorPopup: string | null, title?: string) => { 
      this.setState({ errorPopup , popupTitle : title || "Error"})
  }

  //Changes end for chip text field

  // Customizable Area End
}
