import React from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  FormLabel,
  FormControl,
  TextField,
  Input,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  Tooltip
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import moment from 'moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { styled } from "@material-ui/core/styles";
import {
  PhotoCamera,
  DateRange,
  Close,
  Visibility,
  VisibilityOff
} from "@material-ui/icons";
import ProfileScreenController, { Props, configJSON } from "./AdminConsoleProfileScreenController";
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";
import CustomModal from "../../../components/src/customModal.web";
import ImageCropEditor from "../../../components/src/ImageCropper";
import { Formik } from 'formik'
import { SchemaAdminFormEDIT } from "../../../components/src/helper";

const { screenDefaultsTexts: screenDefaults } = configJSON;

export default class ProfileScreen extends ProfileScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getEyeIconPassword = (visibility: boolean) => (
    visibility ?
      <Visibility />
      :
      <VisibilityOff />
  );

  InputField = (title: string, value: string, handleChange: any, name: string, touched: any, errors: any, placeholder: string) => {
    return (
      <>
        <FormLabel className="customLabelField">
          {title}
        </FormLabel>
        <FormControl fullWidth>
          <TextField
            style={styles.customInputField}
            placeholder={placeholder}
            type={title.includes("Email") ? 'email' : "text"}
            name={name}
            variant="outlined"
            color="secondary"
            value={value}
            onChange={handleChange}
            error={this.getErrorMessage(
              touched,
              errors,
              name
            )}
            helperText={this.getHelperText(
              touched,
              errors,
              name
            )}
            InputProps={{
              autoComplete: "new-username",
            }}
          />
        </FormControl>
      </>
    )
  }

  userProfileSection = () => this.state.profile_avatar_image && <ImageCropEditor open={this.state.cropImageModal} src={this.state.profile_avatar_image} onClose={this.handleCloseCropModal} title="User
    profile" buttonText="Submit" handleUploadImage={this.handleSubmitProgfileImage} />

  showAdminForm = () => {
    return (
      <>
        <Formik
          data-testid="adminFormikTestid"
          initialValues={this.state.editIntValue}
          validationSchema={SchemaAdminFormEDIT}
          onSubmit={(values) => this.updateAdmin(values)}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  {
                    this.InputField("First Name", values.firstnameAdmin, handleChange, "firstnameAdmin", touched, errors, "Enter First Name")
                  }
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  {
                    this.InputField("Last Name", values.lastnameAdmin, handleChange, "lastnameAdmin", touched, errors, "Enter Last Name")
                  }
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <FormLabel
                    className="customLabelField"
                  >
                    Mobile Number
                  </FormLabel>
                  <FormControl fullWidth>
                    <Input
                      disableUnderline
                      fullWidth
                      style={styles.sectionInput}
                      placeholder="Enter Mobile Number"
                      type="text"
                      className="custom-mobile-input"
                      name="mobileNumberAdmin"
                      value={values.mobileNumberAdmin}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box
                            style={{
                              padding: "0 16px",
                              borderRight: "1px solid #F0EBE0",
                            }}
                          >
                            <p>+91</p>
                          </Box>
                        </InputAdornment>
                      }
                      onChange={handleChange}
                      error={this.getErrorMessage(
                        touched,
                        errors,
                        "mobileNumberAdmin"
                      )}
                    />
                    {
                      touched.mobileNumberAdmin &&
                      errors.mobileNumberAdmin && (
                        <div>
                          <Typography className="text-danger" style={{ fontSize: "0.75rem" }}>
                            {errors.mobileNumberAdmin}
                          </Typography>
                        </div>
                      )
                    }
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <FormLabel
                    className="customLabelField"
                  >
                    Date of Birth
                  </FormLabel>
                  <FormControl fullWidth style={{ marginTop: "8px" }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        required
                        className="customDatePicker"
                        clearable
                        data-testid="dateOfBirthAdminTestid"
                        value={values.dateOfBirthAdmin}
                        maxDate={moment().subtract(18, "years").toDate()}
                        invalidDateMessage={`Invalid date`}
                        placeholder="DD/MM/YYYY"
                        onChange={(date) =>
                          setFieldValue("dateOfBirthAdmin", date)
                        }
                        format="DD/MM/yyyy"
                        inputVariant="outlined"
                        color="secondary"
                        name="dateOfBirthAdmin"
                        error={this.getErrorMessage(
                          touched,
                          errors,
                          "dateOfBirthAdmin"
                        )}
                        helperText={this.getHelperText(
                          touched,
                          errors,
                          "dateOfBirthAdmin"
                        )}
                        keyboardIcon={
                          <DateRange />
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  {
                    this.InputField("Email Address", values.emailAddressAdmin, handleChange, "emailAddressAdmin", touched, errors, "Enter Email Id")
                  }

                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  {
                    this.InputField("Username", values.usernameAdmin, handleChange, "usernameAdmin", touched, errors, "Enter Username")
                  }

                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <FormLabel className="customLabelField">
                    Password
                  </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      autoComplete="off"
                      style={styles.customInputField}
                      placeholder="Enter Password"
                      type={this.changeType(values.isPassword)}
                      name="passwordAdmin"
                      variant="outlined"
                      color="secondary"
                      value={values.passwordAdmin}
                      onChange={handleChange}
                      error={this.getErrorMessage(
                        touched,
                        errors,
                        "passwordAdmin"
                      )}
                      helperText={this.getHelperText(
                        touched,
                        errors,
                        "passwordAdmin"
                      )}
                      data-testid="showPasswordTestid"
                      InputProps={{
                        autoComplete: "new-password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              data-testid="showPasswordIconButton"
                              onClick={() => setFieldValue('isPassword', !values.isPassword)}
                              edge="end"
                            >
                              {this.getEyeIconPassword(values.isPassword)}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <FormLabel
                    id="confirmPasswordAdminId"
                    className="customLabelField"
                  >
                    Confirm Password
                  </FormLabel>
                  <FormControl fullWidth>
                    <TextField
                      style={styles.customInputField}
                      placeholder="Enter Confirm Password"
                      type={this.changeType(values.isConfigPassword)}
                      name="confirmPasswordAdmin"
                      variant="outlined"
                      color="secondary"
                      value={values.confirmPasswordAdmin}
                      onChange={handleChange}
                      error={
                        (touched?.confirmPasswordAdmin ||
                          touched?.passwordAdmin) &&
                        Boolean(errors?.confirmPasswordAdmin)
                      }
                      helperText={
                        (touched.confirmPasswordAdmin ||
                          touched?.passwordAdmin) &&
                        errors.confirmPasswordAdmin
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              data-testId="showConfirmPasswordTestid"
                              onClick={() => setFieldValue('isConfigPassword', !values.isConfigPassword)}
                              edge="end"
                            >
                              {this.getEyeIconPassword(values.isConfigPassword)}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Box className="modalSubmitButtonWrapper">
                <Button
                  variant="contained"
                  id="adminFormSubmitBtn"
                  className="modalSubmitButton"
                  fullWidth
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Update Profile
                </Button>
              </Box>
            </>
          )}
        </Formik>
      </>
    )
  };

  render() {
    const { navigation, id } = this.props;
    const _this = this;

    return (
      // Customizable Area Start
      <NavigationMenu navigation={navigation} id={id}>
        <>
          {this.userProfileSection()}
          <Grid spacing={3} container item>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: '40px', width: '100%' }}
            >
              <Box>
                <Typography variant='h4' style={styles.heading}>{screenDefaults.heading1}</Typography>
              </Box>
              <Box>
                <Button
                  style={styles.headingRight}
                  data-testid='logoutBtnTestid'
                  onClick={this.handleOpenLogout}
                >
                  Logout
                </Button>
              </Box>
            </Box>
          </Grid>

          <CustomModal
            open={this.state.openLogoutPopup}
            onClose={this.handleCloseLogout}
            title="Logout"
            description={"Are you sure you want to Logout?"}
            confirmTitle="YES"
            confirmHandler={this.deleteNotification}
          />
          <CustomModal
            open={this.state.deletePopup}
            onClose={() => this.toggleDeletePopup(false)}
            title="Delete"
            description={"Are you sure you want to Delete?"}
            confirmTitle="DELETE"
            confirmHandler={() => this.deleteUser()}
          />
          <CustomModal
            open={!!this.state.error}
            onClose={() => this.setState({ error: '' })}
            title={this.state.popupTitle}
            description={this.state.error}
          />
          <Grid container style={styles.rootGrid}>
            <Grid item container style={styles.profileContainer} justifyContent="space-between" spacing={3}>
              <Grid item xs={12} sm={12} md={2}>
                <Box style={styles.profileAvatarContainer}>
                  <input type="file" id="upload" data-test-id="uploadProfileButton" onChange={(event) => event?.currentTarget?.files?.length && this.onProfileImageUpload(event)} accept="image/*" style={{ display: 'none' }} />
                  <Box   >
                    <label htmlFor="upload" style={{ display: 'flex', alignItems: 'center' }}>
                      <StyledBadge
                        overlap="rectangular"
                        badgeContent={
                          <PhotoCamera fontSize="small" style={styles.profileIconButton} />
                        }
                        data-testid='badge-content'
                      >
                        <IconButton
                          disableRipple={true}
                          aria-label="camera-edit_icon"
                          style={styles.avatarContainer}
                        >
                          <Avatar style={styles.profileAvatar} src={this.state.profile_avatar}>{this.state.profile_firstname[0]}</Avatar>
                        </IconButton>
                      </StyledBadge>
                    </label>
                  </Box>
                  <Box style={{ maxWidth: '142px' }}>
                    <Tooltip title={this.state.profile_firstname}>
                      <Typography noWrap style={styles.avatarTypographyTitle}>{`${this.state.profile_firstname} ${this.state.profile_lastname}`}</Typography>
                    </Tooltip>
                    <Typography noWrap style={styles.avatarTypographyContact}>{this.state.profile_phone}</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} container alignItems="center" alignContent="center" justifyContent="space-between" spacing={1} style={{marginBottom: '35px'}}>
                <Grid item md={7}>
                  <Box style={styles.profileLabelContainer}>
                    <Box component="span" style={styles.labelSpan}>
                      <Box style={styles.profileLabel}>{screenDefaults.profileTextEmail}&#58;</Box>
                      <Tooltip title={this.state.profile_email}>
                        <Typography noWrap style={styles.profileLabelValue}>{this.state.profile_email}</Typography>
                      </Tooltip>
                    </Box>
                    <Box component="span" style={styles.labelSpan}>
                      <Box style={styles.profileLabel}>{screenDefaults.profileTextUsername}&#58;</Box>
                      <Tooltip title={this.state.profile_user}>
                        <Typography noWrap style={styles.profileLabelValue}>{this.state.profile_user}</Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={5} style={{alignSelf: 'flex-start'}}>
                  <Box style={styles.profileLabelContainer}>
                    <Box component="span" style={styles.labelSpan}>
                      <Box style={styles.profileLabel}>{screenDefaults.profileTextDOB}&#58;</Box>
                      <Tooltip title={this.state.profile_dob}>
                        <Typography noWrap style={styles.profileLabelValue}>{this.state.profile_dob}</Typography>
                      </Tooltip>
                    </Box>

                    <Box component="span" style={styles.labelSpan}>
                      {/* <Box style={styles.profileLabel}>{screenDefaults.profileTextLocation}&#58;</Box>
                                        <Typography noWrap style={styles.profileLabelValue}>{this.state.profile_location}</Typography> */}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item container xs={12} sm={12} md={4} justifyContent="flex-end" alignItems="flex-end">
                <Box style={styles.functionButtonContainer} data-test-id={'profile-container'}>
                  <Button
                    component="span"
                    aria-label="edit-profile"
                    disableRipple={true}
                    style={styles.buttonFunction}
                    data-testid={"edit-profile"}
                    onClick={() => this.toggleEditPopup(true)}
                  >
                    <Typography style={styles.buttonFunctionTxt}>
                      {screenDefaults.buttonText1}
                    </Typography>
                  </Button>

                  <Button
                    component="span"
                    aria-label="delete-profile"
                    disableRipple={true}
                    style={{ ...styles.buttonFunction, ...styles.buttonDeleteFunction }}
                    data-testid={"delete-profile"}
                    onClick={() => this.toggleDeletePopup(true)}
                  >
                    <Typography style={styles.buttonFunctionTxt}>
                      {screenDefaults.buttonText2}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid >
          {/* ADD-EDIT USER MODAL START */}
          <Dialog
            aria-labelledby="customized-dialog-title"
            open={this.state.userModal}
            maxWidth={"md"}
            PaperProps={{
              style: { borderRadius: 12, width: "712px" },
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <DialogTitle className="modalDialogTitle">
                  <Typography variant="subtitle1" style={styles.labelEdit} >
                    Edit Profile
                  </Typography>
                </DialogTitle>
              </div>
              <div style={{ marginRight: 20 }}>
                <IconButton
                  aria-label="close"
                  data-testid="modalCloseBtnTestid"
                  onClick={() => this.toggleEditPopup(false)}
                >
                  <Close />
                </IconButton>
              </div>
            </div>
            <DialogContent dividers className="modalBodyWrapper">
              <Box className="userFormBodyWrapper">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="formfieldWrapper"
                  >
                    <FormLabel className="customLabelField">
                      User Type
                    </FormLabel>
                    <FormControl fullWidth className="userTypeFormControl">
                      <Select
                        value={this.state.profile_role}
                        variant="outlined"
                        className="farmerFormDDClass"
                        color="secondary"
                        data-testid="createUserModalDropdown"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        disabled
                        readOnly
                      >
                        <MenuItem value={"farmers"}>Farmer</MenuItem>
                        <MenuItem value={"field_executive"}>Field Executive</MenuItem>
                        <MenuItem value={"admin"}>Admin</MenuItem>
                        <MenuItem value={"super_admin"}>Super Admin</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {this.showAdminForm()}
              </Box>
            </DialogContent>
          </Dialog>
          {/* ADD-EDIT USER MODAL END */}
        </>
      </NavigationMenu>
      // Customizable Area End
    );
  }
}

export const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    color: 'rgb(235, 143, 6)',
    top: '83%',
    right: '18%',
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '50%',
    backgroundColor: 'rgb(240, 235, 224)'
  },
}));

// Customizable Area Start
const styles = {
  rootGrid: {
    flexGrow: 1,
    width: "100%",
    // height: "100%"
  } as React.CSSProperties,

  heading: {
    fontSize: "2rem",
    fontFamily: screenDefaults.fontFamily,
    fontWeight: 600,
    fontStyle: "normal",
    color: "#1C2324",
  } as React.CSSProperties,

  headingRight: {
    fontSize: "1.5rem",
    fontFamily: screenDefaults.fontFamily,
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "2rem",
    color: "#B3261E",
    cursor: 'pointer',
    paddingRight: 0,
  } as React.CSSProperties,

  profileContainer: {
    paddingInline: "1.5rem",
    paddingBlock: "1.5rem",
    backgroundColor: "#F8F6F1",
    borderRadius: "12px",
    height: "100%"
  } as React.CSSProperties,

  avatarContainer: {
    padding: "0.5rem",
    width: "7rem",
    objectFit: "cover",
    height: "6.5rem",
  } as React.CSSProperties,

  profileAvatarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%"
  } as React.CSSProperties,

  profileIconButton: {
    backgroundColor: "#F0EBE0",
    borderRadius: "50%",
    fontSize: '1.125rem',
    cursor: "pointer" as const
  } as React.CSSProperties,

  profileAvatar: {
    width: "6rem",
    height: "6rem",
    backgroundColor: "#108B88",
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "3rem",
    lineHeight: "3.5rem",
    textTransform: "uppercase",
    color: "#FFFFFF",
  } as React.CSSProperties,

  avatarTypographyTitle: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "30px",
    color: "#1C2324",
  } as React.CSSProperties,

  avatarTypographyContact: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: ".85rem",
    lineHeight: "22px",
    textAlign: "center"
  } as React.CSSProperties,

  profileDetails: {
    display: "flex",
    gap: "1rem",
    width: "100%",
  } as React.CSSProperties,

  profileLabelContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1.2rem",
  } as React.CSSProperties,

  profileLabel: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#1C2324",
    opacity: 0.64,
    whiteSpace: 'nowrap'
  } as React.CSSProperties,

  labelSpan: {
    display: "flex",
    gap: ".5rem",
    alignItems: "center"
  } as React.CSSProperties,

  profileLabelValue: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2324",
    maxWidth: '200px',
  } as React.CSSProperties,


  customFunctionContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    height: "100%",
    width: "100%",
  } as React.CSSProperties,

  functionButtonContainer: {

    display: "flex",
    gap: "1.5rem",
    '@media(min-width:900px) and (max-width:1024px)': {
      background: 'red'
    }
  } as React.CSSProperties,

  buttonFunction: {
    backgroundColor: "#DF5200",
    borderRadius: "1.85rem",
    paddingBlock: ".3rem",
    paddingInline: "2rem",
    width: "100%",
    height: "2rem"
  } as React.CSSProperties,

  buttonFunctionTxt: {
    fontFamily: screenDefaults.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "22px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#fff"
  } as React.CSSProperties,

  buttonDeleteFunction: {
    backgroundColor: "#B3261E",
  } as React.CSSProperties,

  customInputField: {
    margin: "8px 0 0 0",
  } as React.CSSProperties,

  labelEdit: {
    fontSize: '24px',
    fontFamily: screenDefaults.fontFamily,
    fontWeight: 500,
    lineHeight: '36px'
  } as React.CSSProperties,

  sectionInput: {
    marginTop: "8px",
    padding: "8px 8px 8px 0",
    background: "#FCFAF7",
    borderRadius: "12px",
    border: "1px solid #F0EBE0",
  } as React.CSSProperties
};
// Customizable Area End