Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdminConsole2";
exports.labelBodyText = "AdminConsole2 Body";
exports.apicallTypePatch = "PATCH";

exports.errorTitle = "Error";
exports.imageLimit_250 = 262144000;
exports.missingParameter = "The requested parameter is missing or incorrect.";
exports.dateInvalid = "The date or time is invalid.";
exports.btnExampleTitle = "CLICK ME";
exports.deleteApiMethodType = "DELETE";
exports.addeventsTitle = (isEdit) =>
  isEdit ? "Edit Event/News" : "Add Event/News";
exports.shareText = "Share in Chat";
exports.newsAndEventsTitle = "News & Events";
exports.eventsSubtitle = "Upcoming Workshop";
exports.newsSubtitle = "Latest News";
exports.newsandeventsApiEndpoint = "admin/bx_block_news_and_events/events_news";
exports.delNewAndEventsApiEndpoint =
  "admin/bx_block_news_and_events/events_news/";
exports.eventsApiEndpoint = "admin/bx_block_news_and_events/events_news/get_latest_events";
exports.newsApiEndpoint = "admin/bx_block_news_and_events/events_news/get_all_news";
exports.getStateList = "admin/state_listing";
exports.getDistrictList = "admin/district_listing";
exports.getTalukaList = "admin/taluka_listing";
exports.getVillageList = "admin/village_listing";
exports.getGroupList = "admin/group_listing";
exports.getFieldExecutiveList = "admin/fe_listing";
exports.getFarmerList = "admin/farmer_listing";
exports.apiMapKey = "AIzaSyDbaGDdgArWGFmY6cXieJngZkdTFkq8Zb4";

exports.events = "Event";
exports.news = "News";
exports.newsAndEventsShareLink = "https://co:farm/newsandevents/";
exports.farmDairyShareLink = "https://co:farm/farmdairy/";

exports.initalFormDataValue = [
  {
    name: "",
    news_event_type: "News",
    language: "English",
    event_date: null,
    description: "",
    events_news_accounts_attributes: [],
    written_by: "",
    image: "",
  },
  {
    name: "",
    news_event_type: "News",
    language: "Hindi",
    event_date: null,
    description: "",
    events_news_accounts_attributes: [],
    written_by: "",
    image: "",
  },
  {
    name: "",
    news_event_type: "News",
    language: "Gujarati",
    event_date: null,
    description: "",
    events_news_accounts_attributes: [],
    written_by: "",
    image: "",
  },
  {
    name: "",
    news_event_type: "Event",
    location: "",
    sentTo: [],
    language: "English",
    event_date: null,
    event_time: null,
    description: "",
    events_news_accounts_attributes: [],
    image: "",
  },
  {
    name: "",
    news_event_type: "Event",
    location: "",
    sentTo: [],
    language: "Hindi",
    event_date: null,
    event_time: null,
    description: "",
    events_news_accounts_attributes: [],
    image: "",
  },
  {
    name: "",
    news_event_type: "Event",
    location: "",
    sentTo: [],
    language: "Gujarati",
    event_date: null,
    event_time: null,
    description: "",
    events_news_accounts_attributes: [],
    image: "",
  },
];

exports.farmDiaryScreenDefaults = {
  nutrient: "Nutrient",
  pest_management: "Pest Management",
  pre_sowing: "Pre Sowing",
  sowing: "Sowing",
  gap_filling: "Gap Filling",
  sell: "Sell",
  irrigation: "Irrigation",
  weed_management: "Weed Management",
  harvest: "Harvest",
};


exports.farmDiaryCategoryMapping = {
  pre_sowing: {
    date: {
      label: "Date",
      type: "DATE",
    },
    cost: {
      label: "Cost",
      type: "STRING",
    },
    compost_cost: {
      label: "Compost Cost",
      type: "STRING",
    },
    labor_cost: {
      label: "Labor Cost",
      type: "STRING",
    },
    pre_sowing_activity_id: {
      label: "Pre-Sowing Activity",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
  },
  sowing: {
    date_of_sowing: {
      label: "Date of Sowing",
      type: "DATE",
    },
    crop_name_id: {
      label: "Crop Name",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    crop_area: {
      label: "Crop Area",
      type: "STRING",
    },
    unit_farm_area_id: {
      label: "Unit",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    crop_type_id: {
      label: "Crop Type",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    variety_id: {
      label: "Variety",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    seed_source_id: {
      label: "Seed Source",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    number_of_packet: {
      label: "Number of Packets",
      type: "STRING",
    },
    quantity_in_kg_other_crop: {
      label: "Quantity in kg (Other Crop)",
      type: "STRING",
    },
    seed_price: {
      label: "Seed Price",
      type: "STRING",
    },
    labor_cost: {
      label: "Labor Cost",
      type: "STRING",
    },
    seed_treatment: {
      label: "Seed Treatment",
      type: "STRING",
    },
    bio_agent_id: {
      label: "Bio Agent",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    bio_agent_cost: {
      label: "Bio Agent Cost",
      type: "STRING",
    },
  },
  gap_filling: {
    date: {
      label: "Date",
      type: "DATE",
    },
    labor_cost: {
      label: "Labor Cost",
      type: "STRING",
    },
    price_of_seed: {
      label: "Price of Seed",
      type: "STRING",
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
  },
  weed_management: {
    weeding_date: {
      label: "Weeding Date",
      type: "DATE",
    },
    weeding_type_id: {
      label: "Weeding Type",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    machine_charges: {
      label: "Machine Charges",
      type: "STRING",
    },
    labor_cost: {
      label: "Labor Cost",
      type: "STRING",
    },
    weedicide_id: {
      label: "Weedicide",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    quantity_of_weedicide: {
      label: "Quantity of Weedicide",
      type: "STRING",
    },
    cost_of_weedicide: {
      label: "Cost of Weedicide",
      type: "STRING",
    },
    labor_cost_of_spraying: {
      label: "Labor Cost of Spraying",
      type: "STRING",
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
  },
  nutrient: {
    nutrient_managment_id: {
      label: "Nutrient Management",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    date_of_application: {
      label: "Date of Application",
      type: "DATE",
    },
    quantity_of_nutrient: {
      label: "Quantity of Nutrient",
      type: "STRING",
    },
    unit_of_measure_id: {
      label: "Unit of Measure",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    fertilizer_cost: {
      label: "Fertilizer Cost",
      type: "STRING",
    },
    labor_cost: {
      label: "Labor Cost",
      type: "STRING",
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
      
    },
  },
  pest_management: {
    pest_managment_id: {
      label: "Pest Management",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    bio_agent_release_id: {
      label: "Bio Agent Release",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    date_of_release: {
      label: "Date of Release",
      type: "DATE",
    },
    quantity: {
      label: "Quantity",
      type: "STRING",
    },
    cost_of_input: {
      label: "Cost of Input",
      type: "STRING",
    },
    pesticide_id: {
      label: "Pesticide",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    date_of_application: {
      label: "Date of Application",
      type: "DATE",
    },
    machine_cost_of_spraying: {
      label: "Machine Cost of Spraying",
      type: "STRING",
    },
    quantity_of_pesticide: {
      label: "Quantity of Pesticide",
      type: "STRING",
    },
    pesticide_cost: {
      label: "Pesticide Cost",
      type: "STRING",
    },
    type_of_trap_id: {
      label: "Trap Type",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    date_of_installation: {
      label: "Date of Installation",
      type: "DATE",
    },
    number_of_trap: {
      label: "Number of Traps",
      type: "STRING",
    },
    cost_of_trap: {
      label: "Cost of Trap",
      type: "STRING",
    },
    unit_of_measure_id: {
      label: "Unit of Measure",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    labor_cost: {
      label: "Labor Cost",
      type: "STRING",
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
  },
  irrigation: {
    date_of_irrigation: {
      label: "Date of Irrigation",
      type: "DATE",
    },
    source_irrigation_id: {
      label: "Source of Irrigation",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    type_irrigation_id: {
      label: "Irrigation Type",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    hours_of_irrigation: {
      label: "Hours of Irrigation",
      type: "STRING",
    },
    number_of_nozels: {
      label: "Number of Nozzles",
      type: "STRING",
    },
    spacing_of_dripper: {
      label: "Spacing of Dripper",
      type: "STRING",
    },
    drip_irrigated_area: {
      label: "Drip Irrigated Area",
      type: "STRING",
    },
    row_to_row_spacing: {
      label: "Row to Row Spacing",
      type: "STRING",
    },
    irrigation_cost: {
      label: "Irrigation Cost",
      type: "STRING",
    },
    labor_cost: {
      label: "Labor Cost",
      type: "STRING",
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
  },
  harvest: {
    date_of_picking: {
      label: "Date of Picking",
      type: "DATE",
    },
    quantity_picked: {
      label: "Quantity Picked",
      type: "STRING",
    },
    total_picking_cost: {
      label: "Total Picking Cost",
      type: "STRING",
    },
    storage_type_id: {
      label: "Storage Type",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
  },
  sell: {
    date_of_sell: {
      label: "Date of Sale",
      type: "DATE",
    },
    quantity_of_cotton_sold: {
      label: "Quantity of Cotton Sold",
      type: "STRING",
    },
    cotton_price: {
      label: "Cotton Price",
      type: "STRING",
    },
    total_amount: {
      label: "Total Amount",
      type: "STRING",
    },
    buyer_name: {
      label: "Buyer Name",
      type: "STRING",
    },
    transportation_cost: {
      label: "Transportation Cost",
      type: "STRING",
    },
    vehical_type_id: {
      label: "Vehicle Type",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
    labor_cost_of_load_and_unload: {
      label: "Labor Cost of Load and Unload",
      type: "STRING",
    },
    distance_from_farmer_location_to_buyer: {
      label: "Distance from Farmer Location to Buyer",
      type: "STRING",
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return ((item?.attributes && item?.attributes[key]?.length) ? item?.attributes[key][0] : "")
      }
    },
  },
};

exports.farmDiaryCategoryMappingChatPopup = {
  pre_sowing: {
    date: {
      label: "Date",
      type: "DATE",
    },
    cost: {
      label: "Cost",
      type: "STRING",
    },
    compost_cost: {
      label: "Compost Cost",
      type: "STRING",
    },
    labor_cost: {
      label: "Labor Cost",
      type: "STRING",
    },
    pre_sowing_activity_id: {
      label: "Pre-Sowing Activity",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
  },
  sowing: {
    date_of_sowing: {
      label: "Date of Sowing",
      type: "DATE",
    },
    crop_name_id: {
      label: "Crop Name",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    crop_area: {
      label: "Crop Area",
      type: "STRING",
    },
    unit_farm_area_id: {
      label: "Unit",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    crop_type_id: {
      label: "Crop Type",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    variety_id: {
      label: "Variety",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    seed_source_id: {
      label: "Seed Source",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    number_of_packet: {
      label: "Number of Packets",
      type: "STRING",
    },
    quantity_in_kg_other_crop: {
      label: "Quantity in kg (Other Crop)",
      type: "STRING",
    },
    seed_price: {
      label: "Seed Price",
      type: "STRING",
    },
    labor_cost: {
      label: "Labor Cost",
      type: "STRING",
    },
    seed_treatment: {
      label: "Seed Treatment",
      type: "STRING",
    },
    bio_agent_id: {
      label: "Bio Agent",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    bio_agent_cost: {
      label: "Bio Agent Cost",
      type: "STRING",
    },
  },
  gap_filling: {
    date: {
      label: "Date",
      type: "DATE",
    },
    labor_cost: {
      label: "Labor Cost",
      type: "STRING",
    },
    price_of_seed: {
      label: "Price of Seed",
      type: "STRING",
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
  },
  weed_management: {
    weeding_date: {
      label: "Weeding Date",
      type: "DATE",
    },
    weeding_type_id: {
      label: "Weeding Type",
      type: "STRING",
      formatter: (item, key) => {
        return (item ? item[key][0] : "")
      }
    },
    machine_charges: {
      label: "Machine Charges",
      type: "STRING",
    },
    labor_cost: {
      label: "Labor Cost",
      type: "STRING",
    },
    weedicide_id: {
      label: "Weedicide",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    quantity_of_weedicide: {
      label: "Quantity of Weedicide",
      type: "STRING",
    },
    cost_of_weedicide: {
      label: "Cost of Weedicide",
      type: "STRING",
    },
    labor_cost_of_spraying: {
      label: "Labor Cost of Spraying",
      type: "STRING",
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
  },
  nutrient: {
    nutrient_managment_id: {
      label: "Nutrient Management",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    date_of_application: {
      label: "Date of Application",
      type: "DATE",
    },
    quantity_of_nutrient: {
      label: "Quantity of Nutrient",
      type: "STRING",
    },
    unit_of_measure_id: {
      label: "Unit of Measure",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    fertilizer_cost: {
      label: "Fertilizer Cost",
      type: "STRING",
    },
    labor_cost: {
      label: "Labor Cost",
      type: "STRING",
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
      
    },
  },
  pest_management: {
    pest_managment_id: {
      label: "Pest Management",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    bio_agent_release_id: {
      label: "Bio Agent Release",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    date_of_release: {
      label: "Date of Release",
      type: "DATE",
    },
    quantity: {
      label: "Quantity",
      type: "STRING",
    },
    cost_of_input: {
      label: "Cost of Input",
      type: "STRING",
    },
    pesticide_id: {
      label: "Pesticide",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    date_of_application: {
      label: "Date of Application",
      type: "DATE",
    },
    machine_cost_of_spraying: {
      label: "Machine Cost of Spraying",
      type: "STRING",
    },
    quantity_of_pesticide: {
      label: "Quantity of Pesticide",
      type: "STRING",
    },
    pesticide_cost: {
      label: "Pesticide Cost",
      type: "STRING",
    },
    type_of_trap_id: {
      label: "Trap Type",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    date_of_installation: {
      label: "Date of Installation",
      type: "DATE",
    },
    number_of_trap: {
      label: "Number of Traps",
      type: "STRING",
    },
    cost_of_trap: {
      label: "Cost of Trap",
      type: "STRING",
    },
    unit_of_measure_id: {
      label: "Unit of Measure",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    labor_cost: {
      label: "Labor Cost",
      type: "STRING",
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
  },
  irrigation: {
    date_of_irrigation: {
      label: "Date of Irrigation",
      type: "DATE",
    },
    source_irrigation_id: {
      label: "Source of Irrigation",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    type_irrigation_id: {
      label: "Irrigation Type",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    hours_of_irrigation: {
      label: "Hours of Irrigation",
      type: "STRING",
    },
    number_of_nozels: {
      label: "Number of Nozzles",
      type: "STRING",
    },
    spacing_of_dripper: {
      label: "Spacing of Dripper",
      type: "STRING",
    },
    drip_irrigated_area: {
      label: "Drip Irrigated Area",
      type: "STRING",
    },
    row_to_row_spacing: {
      label: "Row to Row Spacing",
      type: "STRING",
    },
    irrigation_cost: {
      label: "Irrigation Cost",
      type: "STRING",
    },
    labor_cost: {
      label: "Labor Cost",
      type: "STRING",
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
  },
  harvest: {
    date_of_picking: {
      label: "Date of Picking",
      type: "DATE",
    },
    quantity_picked: {
      label: "Quantity Picked",
      type: "STRING",
    },
    total_picking_cost: {
      label: "Total Picking Cost",
      type: "STRING",
    },
    storage_type_id: {
      label: "Storage Type",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
  },
  sell: {
    date_of_sell: {
      label: "Date of Sale",
      type: "DATE",
    },
    quantity_of_cotton_sold: {
      label: "Quantity of Cotton Sold",
      type: "STRING",
    },
    cotton_price: {
      label: "Cotton Price",
      type: "STRING",
    },
    total_amount: {
      label: "Total Amount",
      type: "STRING",
    },
    buyer_name: {
      label: "Buyer Name",
      type: "STRING",
    },
    transportation_cost: {
      label: "Transportation Cost",
      type: "STRING",
    },
    vehical_type_id: {
      label: "Vehicle Type",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
    labor_cost_of_load_and_unload: {
      label: "Labor Cost of Load and Unload",
      type: "STRING",
    },
    distance_from_farmer_location_to_buyer: {
      label: "Distance from Farmer Location to Buyer",
      type: "STRING",
    },
    crop_season_id: {
      label: "Crop Season",
      type: "STRING",
      formatter: (item, key) => {
        return (item? item[key][0] : "")
      }
    },
  },
};
// Customizable Area End
