import { createTheme } from "@material-ui/core/styles";

// Customizable Area Start
export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary :{
      main : "#DF5200",   
      contrastText: '#DF5200'   
    }
  },
  typography: {
    fontFamily: "IBM Plex Sans",
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: "11.5px",
    },
    subtitle2: {
      fontSize: "12px",
      color: "#8E8E8E",
    },
  },
  overrides:{
      MuiMenu: {
        list:{
          background :'#F0EBE0'
        }
    },
  }
});
// Customizable Area End

// Customizable Area Start
export const styles = () => ({
  headerTitle: {
    fontWeight: 550,
    paddingBottom: 10,
    fontFamily: "IBM Plex Sans" as const,
  },
  cardSection: {
    marginBottom: "18px",
    background: "#F8F6F1",
    width: "100%",
    borderRadius: "12px",
    padding: "12px 15px",
    cursor: "pointer",
  },
  betweenCard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  betweenCardHeading: {
    maxWidth: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap" as const
  },
  boxWraperForServey: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  borderBottom: {
    borderBottom: "2px solid rgba(28, 35, 36, .3)"
  },
  listSection: {
    marginTop: "18px",
    background: "#F8F6F1",
    width: "100%",
    borderRadius: "12px",
    '& text.recharts-text.recharts-pie-label-text': {
      fill: 'black',
      fontFamily: "IBM Plex Sans",
    },
    "& path.recharts-curve.recharts-pie-label-line ": {
      stroke: "black",
    }
  },
  listSectionScroll: {
    height: "600px",
    overflow: "auto",
  },
  dateStyle: {
    "text-align": "end",
    color: "#8a9394",
    fontSize: "12px",
    display: "flex",
    justifyContent: "space-between",
  },
  responseTitle: {
    color: "#006A77",
    fontWeight: 600,
    marginTop: "2px",
    fontSize: "12px",
  },
  mt5: {
    marginTop: '5px',
  },
  font20: {
    fontSize: '20px'
  },
  font18: {
    fontSize: '18px'
  },
  rightSection: {
    padding: '16px',
    borderRadius: '9px',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 1px 7px',
    minHeight: '30pc'
  },
  labelCss: {
    fontSize: '14px',
    color: '#1C2324',
    opacity: 0.64,
    lineHeight: '16px',
    marginBottom: '10px',
    marginTop: '23px',
  },
  surveyInput: {
    marginBottom: '10px',
    borderBottom: '.5px solid #F0EBE0'
  },
  boxWraperForServeyInput: {
    flexGrow: 1,
    padding: '10px',
    borderRadius: '10px 15px',
    background: '#FCFAF7',
    border: '1px solid #F0EBE0',
    width: '100px'
  },
  AddAnotherQuestionBtn: {
    background: '#FCFAF7',
    boxShadow: 'none',
    borderRadius: '26px',
    padding: ' 14px 0',
    color: '#1C2324',
    flexGrow: 1,
    border: "1px solid #F0EBE0",
    "&:hover": {
      background: '#F0EBE0' ,
    }
  },
  centerClass: {
    alignItems: 'center',
  },
  customSelect: {
    display: 'flex',
    alignItems: 'center',
    background: '#F0EBE0',
    maxWidth: '209px',
    justifyContent: 'space-between',
    height: '56px',
    borderRadius: '12px',

    padding: '10px 20px',
  },
  customSelectBox: {
    background: '#F0EBE0',
    position: 'absolute' as const,
    maxWidth: '209px',
    minWidth: '209px',
    padding: '5px',
    zIndex: 999,
    borderRadius: '10px',
  },
  tabLabel: {
    fontSize: '18px',
  },
  loader: {
    "text-align": "center",
    marginTop: "10px",
  },
  switchBtn: {

    "& .MuiSwitch-thumb": {
      color: '#0f727f'
    },
    "& .MuiSwitch-track": {
      background: '#88b7b9'
    }
  },
  deleteBtn: {
    color: "#B3261E",
  },
  editBtn:{
    color: "#EB8F06"
  },
  shareSurvey: {
    background: '#DF5200',
    color: '#fff',
    borderRadius: '26px',
    height: '55px',
    fontSize: '18px',
    padding: '14px 0',
    flexGrow: 1,
    boxShadow: 'none',
    "&:hover":{
      background: '#DF5200',
    },
    "&:disabled":{
      background: '#DF5200 !important',
      color: '#fff !important',
    }
  },
  disableSwitchBtn:{
    "& .MuiSwitch-thumb":{
      background: "#7c7c7c" ,
    },
     "& .MuiSwitch-track" :{
      background: '#726d6d !important',
     }
  },
  submitBtnContainer: {
    marginTop: "20px",
  },
  between: {
    display: 'flex',
    alignItems: 'self-end',
    justifyContent: 'space-between'
  },
  headerSubtitle: {
    fontFamily: 'IBM Plex Sans',
    color: "#006A77",
    fontSize: "14px",
    fontStyle: "italic",
    paddingBottom: 10,
  },
  dateText: {
    color: "#8a9394",
    fontSize: "12px",
    marginLeft: '11px'
  },
  pathFirstPart: {
    opacity: 0.6,
    cursor: "pointer" as const,
  },
  deleteBtnLarge: {
    background: '#B3261E',
    borderRadius: '40px',
    color: 'white',
    padding: '5px 24px',
    fontWeight: 'bolder' as const,
    boxShadow: 'none' as const,
     "&:hover":{
      background: '#B3261E',
      boxShadow: 'none' as const,
     }
  },
  responseCard: {
    background: '#F8F6F1',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px'
  },
  padding10: {
    padding: "10px 10px"
  },
  mainColor: {
    color: '#006A77',
  },
  fontSize10: {
    fontSize: "10px"
  },
  question: {
    display: "inline-block",
    whiteSpace: "nowrap" as const,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "90%"
  },
  bottomBorder: {
    borderBottomStyle: 'groove' as const,
    paddingBottom: '10px',
    borderColor: '#ffffff61',
    marginBottom: '15px',
  },
  questionCard: {
    width: "100%",
    minHeight: "100px",
    marginTop: "15px"
  },
  mt24: {
    marginTop: '24px'
  },
  rightHeader: {
    display: 'flex',
    gap: '20px'
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column" as const,
  },
  option: {
    color: '#1C2324',
    display: "inline-block",
    width: "200px",
    whiteSpace: "nowrap" as const,
    overflow: "hidden",
    textOverflow: "ellipsis",   
  },
  errorText :{
    fontSize: '12px',
    fontWeight: 400,
    color: 'red',
    opacity:1,
  },
  textArea: {
    width: "100%",
    "& div": {
      padding: '0 !important',
    },
    "& textarea":{
      padding:  '10px !important'
    }
  },
  responseTitleKey: {
    fontSize: '16px',
    fontWeight: 500
  },
  responseAccordion: {
    background: "#F8F6F1",
  },
  headingAccordion: {
    fontSize: '17px',
    fontWeight: 500
  },
  questionAccordion: {
    padding: '7px 5px',
    background: '#F0EBE0',
    borderRadius: '7px',
    fontSize: '15px',
    width: '100%',
    wordWrap: 'break-word' as const
  },
  answer:{
    wordWrap: 'break-word' as const
  },
  detailSection: {
    display: "flex",
    flexDirection: "column" as const,
    gap: '10px'
  },
  shareBtn:{
    color: '#006A77',
  },
  deleteIconBtn:{
    color: "#B3261E",
  },
  w100:{
    width: '100%'
  },
  tabs:{
    '&.MuiTab-textColorSecondary.Mui-selected':{
      color: "#DF5200",
      "&.MuiTab-wrapper":{
        color: "#DF5200"
      }
    }
  },
  surveyName:{
    fontSize: '22px',
    fontWeight: 600
  },
  formControlLabel:{
    "& .MuiFormControlLabel-label":{
      fontWeight: 500
    }
  },
});
// Customizable Area End
