import React from "react";

import {
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  Box
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import {
  LocationOn,
} from "@material-ui/icons";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";

const theme = createTheme({
  spacing: 4,
  typography: {
    h4: {
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "28px",
      padding: "5px 16px"
    },
    subtitle2: {
      color: "#108B88",
      fontSize: "14px",
      fontStyle: "italic",
      fontWeight: 500,
      lineHeight: "22px",
      padding: "5px 16px"
    },
    body1: {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "18px",
      color: "#1C2324",
      padding: "2px 16px"
    },
    body2: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#1C2324",
      padding: "2px 16px",
    },
    fontFamily: "IBM Plex Sans",
  },
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#DF5200",
      contrastText: "#fff",
    },
  },
});


interface NewsAndEventsContent {
  handleShareLinkPopup: (linkType?: string,linkId?:any,accountId?:any,type?:any) => void;
  isLinkPopup: boolean;
  newsAndEventsObj: any
}

const NewsAndEventsChatContent = (props: NewsAndEventsContent) => {
  const { handleShareLinkPopup, isLinkPopup, newsAndEventsObj } = props
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={isLinkPopup}
        maxWidth={"md"}
        PaperProps={{
          style: { borderRadius: 12, width: "620px", background: '#F8F6F1' },
        }}
      >
        <DialogContent dividers className="modalBodyWrapper" style={{ marginBottom: '30px', boxSizing: 'border-box' }}>
          <div style={{ color: '#1C2324', position: 'absolute', right: '3px', zIndex: 10, top: '0px' }}>
            <IconButton
              aria-label="close"
              data-testid="modalCloseBtnTestid"
              onClick={(e)=>{
                const linkType = 'newsandevents'
                handleShareLinkPopup(linkType)
              }}
              style={{ color: '#FFFFFF' }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Box>
            {newsAndEventsObj?.image_or_video === 'Image' &&
              <img src={newsAndEventsObj?.image?.url}
                alt=""
                style={{ height: '248px', width: '596px', marginLeft: '12px', borderRadius: '6px', boxSizing: 'border-box', position: 'relative', marginTop: '5px' }} />}
            {newsAndEventsObj?.image_or_video === "Video" && 
             <video src={newsAndEventsObj.image?.url} 
             style={{ objectFit: "cover",  height: '248px', width: '596px', marginLeft: '12px', borderRadius: '6px', boxSizing: 'border-box', position: 'relative', marginTop: '5px' }} controls />}
          </Box>
          <Typography variant="subtitle1">
            {newsAndEventsObj?.name}
          </Typography>
          <Typography variant="body1">
            {moment(newsAndEventsObj?.event_date).format('D MMMM YYYY')}
          </Typography>
          <Typography variant="body2">
            {newsAndEventsObj?.description}
          </Typography>
          {newsAndEventsObj?.written_by && <Typography variant="subtitle2">
            <span style={{ fontWeight: 400 }}>{"Written by"}</span> <span>{newsAndEventsObj?.written_by}</span>
          </Typography>}
          {newsAndEventsObj?.location &&   
          <Typography variant="subtitle2" style={{display:'flex',alignItems:'center',paddingLeft:'10px'}}>
            <span><LocationOn /></span> <span>{newsAndEventsObj?.location}</span>
          </Typography>
         }
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  )
}


// Customizable Area Start
// Customizable Area End

export default NewsAndEventsChatContent;