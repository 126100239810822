import React, { useState, useEffect, useRef } from "react";
// Customizable Area Start

import { Badge, Card, CardMedia, IconButton, InputLabel, Button, Box, Typography, Tooltip, Paper, Tabs, Tab, FormControl, Select, MenuItem, FormHelperText } from "@material-ui/core";

import { styled } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { RemoveCircleOutlineIcon, AddCircleOutlineOutlinedIcon } from "../../blocks/AdminConsole/src/assets";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import CustomModal from "./customModal.web";
import { getBase64 } from "./helper";


// Customizable Area End
const LanguageTabs = ({ loadingIndicator, loading,  handleErrorClear, errorType, error, specificDropDownData, section2InputsHindi, section3InputsGujrati, resetSelectionInput, keyName, stateList, districtList, talukaList, handleInputChange, handleAddInput, handleRemoveInput, section1InputsEnglish, handleSelectData }: any) => {
    const [activeTab, setActiveTab] = useState(0);
    const [formData, setFormData] = useState<any>([
        { url: "", file: "" }
    ]);
    const [updateEnabled, setUpdateEnabled] = useState(false);
    const [deleteEnabled, setDeleteEnabled] = useState(false);
    const [editableData, setEditableData]: any = useState([])
    const [editedData, setEditedData] = useState([...specificDropDownData]); // Initialize with the same data


    const tabNameRef: any = useRef(null);

    const closeSnackbar = () => {
        resetState()
        handleErrorClear()
        setEditedData([...specificDropDownData])
    };
    useEffect(() => {
        setUpdateEnabled(false)
        setDeleteEnabled(false)
        setEditedData([...specificDropDownData])
    }, [specificDropDownData])

    useEffect(() => {
        if (specificDropDownData.length > 0) {
            setEditedData([...specificDropDownData])
        }
    }, [specificDropDownData])
    const handleEditInputChange = (data: any, index: any, newValue: any) => {
        setUpdateEnabled(true)

        let targetKey: any = "";
        if (activeTab === 0) {
            targetKey = Object.keys(data).find(key => key.includes("name_english") || key.includes("label_english"));
        } else if (activeTab === 1) {
            targetKey = Object.keys(data).find(key => key.includes("label_hindi") || key.includes("name_hindi"));
        } else {
            targetKey = Object.keys(data).find(key => key.includes("name_gujrati") || key.includes("name_gujarati") || key.includes("label_gujrati"));
        }
        if (!targetKey) {
            targetKey = Object.keys(data).find(key => key === "name");
        }
        const updatedItem = { ...editedData[index], [targetKey]: newValue };
        setEditableData([...editableData, updatedItem])
        const updatedData = [...editedData.slice(0, index), updatedItem, ...editedData.slice(index + 1)];
        setEditedData(updatedData);
    };

    const handleAddInputLocal = (num: any) => {
        const updatedFormData = [...formData, { url: '', file: "" }];
        setFormData(updatedFormData);
        handleAddInput(num)
    }
    const handleRemoveInputLocal = (num: any, index: any) => {
        handleRemoveInput(num, index)
        const updatedFieldData = [...formData];
        updatedFieldData.splice(index, 1);
        setFormData(updatedFieldData);
    }
    useEffect(() => {
        // Focus on the tab name when it changes
        if (tabNameRef.current) {
            tabNameRef.current.focus();
        }

    }, [activeTab]);
    const parseImages = (img?: string): string | undefined => {
        if (!img) return undefined;
        return img;
    };
    const ImageFragment = ({ type, index, attachment, file, event_type }: { type?: string, index?: number, attachment?: string, file: any, event_type: string }) => {
        let imgPartial = file ? attachment : parseImages(attachment);

        return attachment ? (
            <>
                <StyledBadge
                    overlap="rectangular"
                    badgeContent={
                        <Box>
                            <input
                                type="file"
                                style={webStyle.hide}
                                id={`edit-icon-badge-file-${index}`}
                                className="image-contained-class"
                                onChange={(e) => type === "input" ? handleImageUpload(index, e, event_type) : handleEditImageChange(index, e, event_type)}
                                data-testid={"modal_image_handle_select"}
                                accept="image/jpeg, image/png"
                            />
                            <label htmlFor={`edit-icon-badge-file-${index}`}>
                                <IconButton
                                    data-testid={"modal_image_fragment_select"}
                                    component="span"
                                    aria-label="edit upload picture"
                                    disableRipple={true}
                                    style={webStyle.badgeButton}
                                >
                                    <EditIcon fontSize="small" htmlColor="#fff" />
                                </IconButton>
                            </label>
                        </Box>
                    }
                >
                    <Card variant="outlined" style={webStyle.badgeCardRoot}>
                        <CardMedia
                            component="img"
                            image={imgPartial}
                            src={attachment}
                            alt={`cover-images + ${new Date().getTime()}`}
                        />
                    </Card>
                </StyledBadge>
            </>
        ) : (
            <>
                <Box style={webStyle.formImageContainer}>
                    <input
                        accept="image/*"
                        type="file"
                        className="image-empty-container-class"
                        style={webStyle.hide}
                        id={`icon-button-file-${index}`}
                        onChange={(e) => handleImageUpload(index, e, event_type)}
                        data-testid={"modal_image_handle_select"}
                    />
                    <label htmlFor={`icon-button-file-${index}`} style={{ display: "flex", alignItems: "center" }}>
                        <IconButton aria-label="upload image" component={"span"}>
                            <PhotoCamera style={webStyle.photoCamera} />
                        </IconButton>
                    </label>
                </Box >
            </>
        );

    }
    const handleImageErrorText = (event_type: string) => {
        if (formData.length < 1) {
            return 'Image is required'
        }
    }


    const createSelectValuesState = (sectionInputs: any) => {
        return Array(sectionInputs.length).fill(0);
    };
    const handleEditImageChange = async (index: any, event: any, event_type: string) => {
        setUpdateEnabled(true)
        // Define the new value for the image URL.
        const selectedFile = event.target.files[0];

        const newImageUrl = await getBase64(selectedFile)

        const updatedItem = {
            ...editedData[index],
            image: {
                ...editedData[index].image,
                resized: {
                    ...editedData[index].image.resized,
                    url: newImageUrl, // Update the 'url' property within 'image.resized'
                },
            },
        };

        // Create a copy of the editableData array with the updated item.
        const updatedData = [...editedData];
        updatedData[index] = updatedItem;
        setEditedData(updatedData);

        const updatedItem1 = {
            ...editedData[index],
            image: newImageUrl,
        };
        setEditableData([...editableData, updatedItem1])
        // Update the state with the updatedData.
    };
    
    const handleImageUpload = async (index: any, event: any, event_type: string) => {

        const selectedFile = event.target.files[0];

        const url = await getBase64(selectedFile)

        const updatedFormData = [...formData];
        updatedFormData[index] = {
            file: selectedFile,
            url: url,
        };
        setFormData(updatedFormData)

    }
    const [selectStateValuesEnglish, setSelectStateValuesEnglish] = useState(createSelectValuesState(section1InputsEnglish));
    const [selectDistrictValuesEnglish, setSelectDistrictValuesEnglish] = useState(createSelectValuesState(section1InputsEnglish));
    const [selectTalukaValuesEnglish, setSelectTalukaValuesEnglish] = useState(createSelectValuesState(section1InputsEnglish));

    const [selectStateValuesHindi, setSelectStateValuesHindi] = useState(createSelectValuesState(section2InputsHindi));
    const [selectDistrictValuesHindi, setSelectDistrictValuesHindi] = useState(createSelectValuesState(section2InputsHindi));
    const [selectTalukaValuesHindi, setSelectTalukaValuesHindi] = useState(createSelectValuesState(section2InputsHindi));

    const [selectStateValuesGujrati, setSelectStateValuesGujrati] = useState(createSelectValuesState(section3InputsGujrati));
    const [selectDistrictValuesGujrati, setSelectDistrictValuesGujrati] = useState(createSelectValuesState(section3InputsGujrati));
    const [selectTalukaValuesGujrati, setSelectTalukaValuesGujrati] = useState(createSelectValuesState(section3InputsGujrati));

    const [validationMessages, setValidationMessages] = useState(Array(section1InputsEnglish.length).fill(''));
    const [validationMessagesHindi, setValidationMessagesHindi] = useState(Array(section2InputsHindi.length).fill(''));
    const [validationMessagesGujrati, setValidationMessagesGujrati] = useState(Array(section3InputsGujrati.length).fill(''));


    const [deleteSelected, setDeleteSelected]: any[] = useState([])
    const classes = useStyles();
    let show: any = true
    if (keyName === "Village" || keyName === "District" || keyName === "Taluka") {
        show = false
    }

    let isLabel: any = true
    if (keyName == "Distance in" || keyName == "Type irrigation" || keyName == "Source irrigation" || keyName == "Soil texture" || keyName == "Land type" || keyName == "Unit farm area" || keyName == "Ownership Of Land") {
        isLabel = false
    }


    let isNotificationType

    const setDisable = (name: any) => {
        if (name === "Events And News" || name === "Chat" || name === "Activities" || name === "Mandi Price" || name === "Weather" || name === "Reminder" || name === "Alert") {
            isNotificationType = true
        } else {
            isNotificationType = false
        }
        return isNotificationType
    }
    const setDisableHindi = (name: any) => {
        if (name === "घटनाएँ और समाचार" || name === "बात करना" || name === "गतिविधियाँ" || name === "मंडी भाव" || name === "मौसम" || name === "अनुस्मारक" || name === "चेतावनी") {
            isNotificationType = true
        } else {
            isNotificationType = false
        }
        return isNotificationType
    }
    const setDisableGujrati = (name: any) => {
        if (name === "ઘટનાઓ અને સમાચાર" || name === "बात करना" || name === "પ્રવૃત્તિઓ" || name === "મંડી ભાવ" || name === "હવામાન" || name === "રીમાઇન્ડર" || name === "चेतावनी") {
            isNotificationType = true
        } else {
            isNotificationType = false
        }
        return isNotificationType
    }


    const clearValidationMessage = (index: any) => {
        setValidationMessages(prevMessages => {
            const newMessages = [...prevMessages];
            newMessages[index] = '';
            return newMessages;
        });
    };
    useEffect(() => {
        resetSelectionInput()
    }, [keyName])

    const resetState = () => {
        setActiveTab(0)
        setSelectStateValuesEnglish(createSelectValuesState(section1InputsEnglish));
        setSelectDistrictValuesEnglish(createSelectValuesState(section1InputsEnglish));
        setSelectTalukaValuesEnglish(createSelectValuesState(section1InputsEnglish));

        setSelectStateValuesHindi(createSelectValuesState(section2InputsHindi));
        setSelectDistrictValuesHindi(createSelectValuesState(section2InputsHindi));
        setSelectTalukaValuesHindi(createSelectValuesState(section2InputsHindi));

        setSelectStateValuesGujrati(createSelectValuesState(section3InputsGujrati));
        setSelectDistrictValuesGujrati(createSelectValuesState(section3InputsGujrati));
        setSelectTalukaValuesGujrati(createSelectValuesState(section3InputsGujrati));

        setValidationMessages(Array(section1InputsEnglish.length).fill(''));
        setValidationMessagesHindi(Array(section2InputsHindi.length).fill(''));
        setValidationMessagesGujrati(Array(section3InputsGujrati.length).fill(''));
        setDeleteSelected([])
        setFormData([
            { url: "", file: "" }
        ])
        setEditableData([])
        setDeleteEnabled(false)
        setUpdateEnabled(false)
    };

    const handleUpdateButtonClicked = (data: any) => {
        let combinedArray = data.slice(); // Create a copy of data

        if (updateEnabled || deleteEnabled) {
            if (updateEnabled) {
                if (keyName === "faq category") {
                    const idIndexMap: any = {};

                    editableData.forEach((item: any, index: any) => {
                        const id = item.id;
                        if (!(id in idIndexMap) || index > idIndexMap[id]) {
                            idIndexMap[id] = index;
                        }
                    });

                    const filteredData = editableData.filter((item: any, index: any) => index === idIndexMap[item.id]);

                    function removeFields(obj: any, fieldsToRemove: any) {
                        const newObj = { ...obj };
                        fieldsToRemove.forEach((field: any) => {
                            delete newObj[field];
                        });
                        return newObj;
                    }

                    const fieldsToRemove = ['created_at', 'updated_at', 'document'];
                    const fieldsToRemoveImage = ['created_at', 'updated_at', 'document', 'image'];

                    const finalData = filteredData.map((item: any) => {
                        if (typeof item.image !== 'string' || item?.image?.resized?.url.includes("https://minio.b280316")) {
                            let obj = removeFields(item, fieldsToRemoveImage)
                            return obj;
                        } else {
                            return removeFields(item, fieldsToRemove);
                        }

                    });
                    combinedArray = combinedArray.concat(finalData);

                } else {
                    combinedArray = combinedArray.concat(editableData);
                }
            }
            if (deleteEnabled) {
                const newArray = deleteSelected.map((item: any) => ({
                    _destroy: item.id
                }));
                combinedArray = combinedArray.concat(newArray);
            }
            handleSelectData(combinedArray)
            resetState()
        } else {
            handleSelectData(combinedArray)
            resetState()
        }
    };



    const handleSubmit = () => {
        if (section1InputsEnglish[0] !== "" || section2InputsHindi[0] !== "" || section3InputsGujrati[0] !== "") {
            if (show) {
                if (keyName === "faq category") {
                    const newValidationMessages = section1InputsEnglish.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        } else if (formData[index].url === "") {
                            return 'Please upload an image';
                        }
                        return '';
                    });
                    const newValidationMessages1 = section2InputsHindi.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        } else if (formData[index].url === "") {
                            return 'Please upload an image';
                        }
                        return '';
                    });
                    const newValidationMessages2 = section3InputsGujrati.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        } else if (formData[index].url === "") {
                            return 'Please upload an image';
                        }
                        return '';
                    });
                    const hasValidationErrors = newValidationMessages.some((message: any) => message !== '');
                    const hasValidationErrors1 = newValidationMessages1.some((message: any) => message !== '');
                    const hasValidationErrors2 = newValidationMessages2.some((message: any) => message !== '');
                    setValidationMessages([...newValidationMessages]);
                    setValidationMessagesHindi([...newValidationMessages1])
                    setValidationMessagesGujrati([...newValidationMessages2])

                    if (hasValidationErrors || hasValidationErrors1 || hasValidationErrors2) {
                        if (hasValidationErrors) {
                            setActiveTab(0);
                        } else if (hasValidationErrors1) {
                            setActiveTab(1);
                        } else if (hasValidationErrors2) {
                            setActiveTab(2);
                        } else {
                            return
                        }

                    } else {
                        let data: any = []
                        for (let i = 0; i < section1InputsEnglish.length; i++) {
                            let newItem;
                            newItem = {
                                name: section1InputsEnglish[i],
                                name_english: section1InputsEnglish[i],
                                name_hindi: section2InputsHindi[i],
                                name_gujarati: section3InputsGujrati[i],
                                active: true,
                                image: formData[i].url
                            };

                            data.push(newItem);
                        }
                        handleUpdateButtonClicked(data)

                    }
                } else {
                    const newValidationMessages = section1InputsEnglish.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        }
                        return '';
                    });
                    const newValidationMessages1 = section2InputsHindi.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        }
                        return '';
                    });
                    const newValidationMessages2 = section3InputsGujrati.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        }
                        return '';
                    });

                    const hasValidationErrors = newValidationMessages.some((message: any) => message !== '');
                    const hasValidationErrors1 = newValidationMessages1.some((message: any) => message !== '');
                    const hasValidationErrors2 = newValidationMessages2.some((message: any) => message !== '');

                    if (!hasValidationErrors || !hasValidationErrors1 || !hasValidationErrors2) {
                        setValidationMessages([...newValidationMessages]);
                        setValidationMessagesHindi([...newValidationMessages1])
                        setValidationMessagesGujrati([...newValidationMessages2])
                        if (hasValidationErrors || hasValidationErrors1 || hasValidationErrors2) {
                            if (hasValidationErrors) {
                                setActiveTab(0);
                            } else if (hasValidationErrors1) {
                                setActiveTab(1);
                            } else if (hasValidationErrors2) {
                                setActiveTab(2);
                            } else {
                                return
                            }
                        } else {
                            let data: any = []

                            for (let i = 0; i < section1InputsEnglish.length; i++) {
                                let newItem;

                                if (isLabel) {
                                    if (keyName === "Phase activity" || keyName === "Phase sub activity") {
                                        newItem = {
                                            name: section1InputsEnglish[i],
                                            name_english: section1InputsEnglish[i],
                                            name_hindi: section2InputsHindi[i],
                                            name_gujarati: section3InputsGujrati[i],
                                            active: true,
                                        };
                                    } else {
                                        newItem = {
                                            name: section1InputsEnglish[i],
                                            name_english: section1InputsEnglish[i],
                                            name_hindi: section2InputsHindi[i],
                                            name_gujrati: section3InputsGujrati[i],
                                            active: true,
                                        };
                                    }
                                } else {
                                    newItem = {
                                        label: section1InputsEnglish[i],
                                        label_english: section1InputsEnglish[i],
                                        label_hindi: section2InputsHindi[i],
                                        label_gujrati: section3InputsGujrati[i],
                                        active: true,
                                    };
                                }

                                data.push(newItem);
                            }
                            handleUpdateButtonClicked(data)
                        }
                    }
                }
            } else {
                if (keyName === "Village") {
                    const newValidationMessages = section1InputsEnglish.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        } else if (selectStateValuesEnglish[index] === 0) {
                            return 'Please select a state';
                        } else if (selectDistrictValuesEnglish[index] === 0) {
                            return 'Please select a district';
                        } else if (selectTalukaValuesEnglish[index] === 0) {
                            return 'Please select a taluka';
                        }
                        return '';
                    });
                    const newValidationMessages1 = section2InputsHindi.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        } else if (selectStateValuesHindi[index] === 0) {
                            return 'Please select a state';
                        } else if (selectDistrictValuesHindi[index] === 0) {
                            return 'Please select a district';
                        } else if (selectTalukaValuesHindi[index] === 0) {
                            return 'Please select a taluka';
                        }
                        return '';
                    });
                    const newValidationMessages2 = section3InputsGujrati.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        } else if (selectStateValuesGujrati[index] === 0) {
                            return 'Please select a state';
                        } else if (selectDistrictValuesGujrati[index] === 0) {
                            return 'Please select a district';
                        } else if (selectTalukaValuesGujrati[index] === 0) {
                            return 'Please select a taluka';
                        }
                        return '';
                    });
                    const hasValidationErrors = newValidationMessages.some((message: any) => message !== '');
                    const hasValidationErrors1 = newValidationMessages1.some((message: any) => message !== '');
                    const hasValidationErrors2 = newValidationMessages2.some((message: any) => message !== '');
                    setValidationMessages([...newValidationMessages]);
                    setValidationMessagesHindi([...newValidationMessages1])
                    setValidationMessagesGujrati([...newValidationMessages2])

                    if (hasValidationErrors || hasValidationErrors1 || hasValidationErrors2) {
                        if (hasValidationErrors) {
                            setActiveTab(0);
                        } else if (hasValidationErrors1) {
                            setActiveTab(1);
                        } else if (hasValidationErrors2) {
                            setActiveTab(2);
                        } else {
                            return
                        }

                    } else {
                        const villageData = section1InputsEnglish.map((name: any, index: any) => ({
                            name,
                            name_hindi: section2InputsHindi[index],
                            name_english: name,
                            name_gujrati: section3InputsGujrati[index],
                            active: true,
                            taluka_id: selectTalukaValuesGujrati[index]
                        }));
                        handleUpdateButtonClicked(villageData)

                    }
                } else if (keyName === "Taluka") {
                    const newValidationMessages = section1InputsEnglish.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        } else if (selectStateValuesEnglish[index] === 0) {
                            return 'Please select a state';
                        } else if (selectDistrictValuesEnglish[index] === 0) {
                            return 'Please select a district';
                        }
                        return '';
                    });
                    const newValidationMessages1 = section2InputsHindi.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        } else if (selectStateValuesHindi[index] === 0) {
                            return 'Please select a state';
                        } else if (selectDistrictValuesHindi[index] === 0) {
                            return 'Please select a district';
                        }
                        return '';
                    });
                    const newValidationMessages2 = section3InputsGujrati.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        } else if (selectStateValuesGujrati[index] === 0) {
                            return 'Please select a state';
                        } else if (selectDistrictValuesGujrati[index] === 0) {
                            return 'Please select a district';
                        }
                        return '';
                    });
                    const hasValidationErrors = newValidationMessages.some((message: any) => message !== '');
                    const hasValidationErrors1 = newValidationMessages1.some((message: any) => message !== '');
                    const hasValidationErrors2 = newValidationMessages2.some((message: any) => message !== '');
                    setValidationMessages([...newValidationMessages]);
                    setValidationMessagesHindi([...newValidationMessages1])
                    setValidationMessagesGujrati([...newValidationMessages2])
                    if (hasValidationErrors || hasValidationErrors1 || hasValidationErrors2) {
                        if (hasValidationErrors) {
                            setActiveTab(0);
                        } else if (hasValidationErrors1) {
                            setActiveTab(1);
                        } else if (hasValidationErrors2) {
                            setActiveTab(2);
                        } else {
                            return
                        }
                    } else {
                        const districtData = section1InputsEnglish.map((name: any, index: any) => ({
                            name,
                            name_hindi: section2InputsHindi[index],
                            name_english: name,
                            name_gujrati: section3InputsGujrati[index],
                            active: true,
                            district_id: selectDistrictValuesGujrati[index]
                        }));
                        handleUpdateButtonClicked(districtData)

                    }
                } else if (keyName === "District") {
                    const newValidationMessages = section1InputsEnglish.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        } else if (selectStateValuesEnglish[index] === 0) {
                            return 'Please select a state';
                        }
                        return '';
                    });
                    const newValidationMessages1 = section2InputsHindi.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        } else if (selectStateValuesHindi[index] === 0) {
                            return 'Please select a state';
                        }
                        return '';
                    });
                    const newValidationMessages2 = section3InputsGujrati.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        } else if (selectStateValuesGujrati[index] === 0) {
                            return 'Please select a state';
                        }
                        return '';
                    });
                    const hasValidationErrors = newValidationMessages.some((message: any) => message !== '');
                    const hasValidationErrors1 = newValidationMessages1.some((message: any) => message !== '');
                    const hasValidationErrors2 = newValidationMessages2.some((message: any) => message !== '');
                    setValidationMessages([...newValidationMessages]);
                    setValidationMessagesHindi([...newValidationMessages1])
                    setValidationMessagesGujrati([...newValidationMessages2])
                    if (hasValidationErrors || hasValidationErrors1 || hasValidationErrors2) {
                        if (hasValidationErrors) {
                            setActiveTab(0);
                        } else if (hasValidationErrors1) {
                            setActiveTab(1);
                        } else if (hasValidationErrors2) {
                            setActiveTab(2);
                        } else {
                            return
                        }
                    } else {
                        const districtData = section1InputsEnglish.map((name: any, index: any) => ({
                            name,
                            name_hindi: section2InputsHindi[index],
                            name_english: name,
                            name_gujrati: section3InputsGujrati[index],
                            active: true,
                            state_id: selectStateValuesGujrati[index]
                        }));
                        handleUpdateButtonClicked(districtData)

                    }
                } else {
                    const newValidationMessages = section1InputsEnglish.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        }
                        return '';
                    });
                    const newValidationMessages1 = section2InputsHindi.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        }
                        return '';
                    });
                    const newValidationMessages2 = section3InputsGujrati.map((value: any, index: any) => {
                        if (value.trim() === '') {
                            return 'Field cannot be empty';
                        }
                        return '';
                    });
                    const hasValidationErrors = newValidationMessages.some((message: any) => message !== '');
                    const hasValidationErrors1 = newValidationMessages1.some((message: any) => message !== '');
                    const hasValidationErrors2 = newValidationMessages2.some((message: any) => message !== '');
                    setValidationMessages([...newValidationMessages]);
                    setValidationMessagesHindi([...newValidationMessages1])
                    setValidationMessagesGujrati([...newValidationMessages2])
                    if (hasValidationErrors || hasValidationErrors1 || hasValidationErrors2) {
                        if (hasValidationErrors) {
                            setActiveTab(0);
                        } else if (hasValidationErrors1) {
                            setActiveTab(1);
                        } else if (hasValidationErrors2) {
                            setActiveTab(2);
                        } else {
                            return
                        }
                    } else {
                        const stateData = section1InputsEnglish.map((name: any, index: any) => ({
                            name,
                            name_hindi: section2InputsHindi[index],
                            name_english: name,
                            name_gujrati: section3InputsGujrati[index],
                            active: true,
                        }));
                        handleUpdateButtonClicked(stateData)

                    }
                }
            }
        } else {
            handleUpdateButtonClicked([])
        }
    }
    const handleSelectChange = (selectionType: any, sectionIndex: any, selectedValue: any, setSelectionFunction: any, selectionArray: any) => {
        // const newSelectValues = [...selectionArray];
        let newSelectValues1: any[] = []
        let newSelectValues2: any[] = []
        let newSelectValues3: any[] = []
        switch (selectionType) {
            case 'state':
                newSelectValues1 = [...selectStateValuesEnglish];
                newSelectValues2 = [...selectStateValuesHindi];
                newSelectValues3 = [...selectStateValuesGujrati];

                newSelectValues1[sectionIndex] = selectedValue;
                newSelectValues2[sectionIndex] = selectedValue;
                newSelectValues3[sectionIndex] = selectedValue;

                setSelectStateValuesEnglish(newSelectValues1)
                setSelectStateValuesHindi(newSelectValues2)
                setSelectStateValuesGujrati(newSelectValues3)


                // setSelectionFunction(newSelectValues);
                break;
            case 'district':
                newSelectValues1 = [...selectDistrictValuesEnglish];
                newSelectValues2 = [...selectDistrictValuesHindi];
                newSelectValues3 = [...selectDistrictValuesGujrati];

                newSelectValues1[sectionIndex] = selectedValue;
                newSelectValues2[sectionIndex] = selectedValue;
                newSelectValues3[sectionIndex] = selectedValue;

                setSelectDistrictValuesEnglish(newSelectValues1)
                setSelectDistrictValuesHindi(newSelectValues2)
                setSelectDistrictValuesGujrati(newSelectValues3)
                // newSelectValues[sectionIndex] = selectedValue;
                // setSelectionFunction(newSelectValues);
                break;
            case 'taluka':
                newSelectValues1 = [...selectTalukaValuesEnglish];
                newSelectValues2 = [...selectTalukaValuesHindi];
                newSelectValues3 = [...selectTalukaValuesGujrati];

                newSelectValues1[sectionIndex] = selectedValue;
                newSelectValues2[sectionIndex] = selectedValue;
                newSelectValues3[sectionIndex] = selectedValue;

                setSelectTalukaValuesEnglish(newSelectValues1)
                setSelectTalukaValuesHindi(newSelectValues2)
                setSelectTalukaValuesGujrati(newSelectValues3)
                // newSelectValues[sectionIndex] = selectedValue;
                // setSelectionFunction(newSelectValues);
                break;
            default:
                break;
        }

        clearValidationMessage(sectionIndex);
    };

    const handleChange = (event: any, newTab: any) => {
        setActiveTab(newTab);
    };


    const getLocalizedText = (item: any, language: any) => {
        switch (language) {
            case 'english':
                return item.name_english || item.name || item.label_english;
            case 'hindi':
                return item.name_hindi || item.label_hindi;
            case 'gujarati':
                return item.name_gujrati || item.name_gujarati || item.label_gujrati;
            default:
                return '';
        }
    };

    const handleItemClick = (itemSelected: any) => {
        setDeleteEnabled(true)
        const updatedDeleteSelected = [...deleteSelected, itemSelected];
        setDeleteSelected(updatedDeleteSelected);
        const updatedData = editedData.filter((item) => item.id !== itemSelected.id);

        setEditedData(updatedData);
    }

    const LoaderCenter = () => {
        return (
            <>
                <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", padding:"10px 0" }}>
                    <CircularProgress style={{ color: "#df5200", width:"20px", height:"20px" }} />
                </Box>
            </>
        )
    }
    return (
        <>
            <Box className={classes.rootSub}>
                <Box className={classes.boxContainerSub}
                    style={{
                        opacity: loadingIndicator ? 0.5 : 1, // Adjust the opacity value as needed
                        pointerEvents: loadingIndicator ? 'none' : 'auto', // Disable pointer events when loading
                        width: '100%',
                        padding: loadingIndicator ? '0 0px' : '0 0px',
                    }}>
                    <Paper square style={{ boxShadow: "0 6px 4px -8px black", background: "transparent",padding:"0 8px" }}>
                        <Tabs value={activeTab} onChange={handleChange} TabIndicatorProps={{ className: classes.customIndicator }} style={{minHeight:0}} >
                            <Tab label={<span ref={tabNameRef} tabIndex={0} className={classes.tabName}>English</span>} classes={{ root: classes.customTab, selected: classes.selectedTab }} />
                            <Tab label={<span ref={tabNameRef} tabIndex={1} className={classes.tabName}>Hindi</span>} classes={{ root: classes.customTab, selected: classes.selectedTab }} />
                            <Tab label={<span ref={tabNameRef} tabIndex={2} className={classes.tabName}>Gujarati</span>} classes={{ root: classes.customTab, selected: classes.selectedTab }} />
                        </Tabs>
                    </Paper>

                    <TabPanel value={activeTab} index={0} className={classes.individualWholeList}>
                        {loading ? <LoaderCenter /> :
                            <>
                                {editedData.map((item: any, index: any) => (
                                    <>
                                        <div className={classes.individualList} key={index}>
                                            <Tooltip title={getLocalizedText(item, 'english')}>

                                                <input
                                                    className={classes.modalTextField}
                                                    value={getLocalizedText(item, 'english')}
                                                    disabled={setDisable(getLocalizedText(item, 'english'))}
                                                    name="topic_name"
                                                    data-testid={"modal_input_topic_name"}
                                                    onChange={(e: any) => handleEditInputChange(item, index, e.target.value)}
                                                />
                                            </Tooltip>
                                            <img onClick={() => setDisable(getLocalizedText(item, 'english')) ? null : handleItemClick(item)} src={RemoveCircleOutlineIcon} width="20px" height="20px" alt='removeIcon' />
                                        </div>
                                        {keyName === 'faq category' &&
                                            <>
                                                <Box className={classes.upload_container}>
                                                    <Box component="form" style={webStyle.secondaryRootBox}>
                                                        <Typography
                                                            variant="subtitle2"
                                                            style={webStyle.modalCardContentEditModalTypography}
                                                        >
                                                            Upload Image
                                                        </Typography>
                                                        <FormControl
                                                            fullWidth
                                                            style={webStyle.modalFormControl}
                                                        >
                                                            <InputLabel htmlFor="my-input" />
                                                            {ImageFragment({ type: "api", index: index, attachment:updateEnabled?item?.image?.resized?.url: item?.image?.resized?.url + `?_=${Date.now()}`, file: item.image.file, event_type: "event" })}



                                                            <FormHelperText error id="accountId-error">
                                                                {handleImageErrorText("event")}
                                                            </FormHelperText>

                                                        </FormControl>
                                                    </Box>

                                                </Box >
                                                <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>

                                                    {validationMessages[index] === 'Please upload an image' && validationMessages[index]}
                                                </span>
                                            </>
                                        }
                                    </>

                                ))}
                                {section1InputsEnglish.map((value: any, index: any) => {
                                    const items = formData[index] || { url: "", file: "" };
                                    return (
                                        <div className={classes.individualList} key={index}>
                                            <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: "1.2rem", width: "100%" }}>
                                                    <Tooltip title={value}>
                                                        <input
                                                            type="text"
                                                            value={value}
                                                            onChange={(e) => handleInputChange(1, index, e.target.value)}
                                                            className={classes.modalTextField}
                                                            placeholder="Add another option"
                                                            name="topic_name"
                                                            data-testid={"modal_input_topic_name"}
                                                        />
                                                    </Tooltip>
                                                    {index === section1InputsEnglish.length - 1 ? (
                                                        <img src={AddCircleOutlineOutlinedIcon} width="20px" height="20px" alt='removeIcon' onClick={() => handleAddInputLocal(1)} />
                                                    ) : (
                                                        <img src={RemoveCircleOutlineIcon} width="20px" height="20px" alt='removeIcon' onClick={() => handleRemoveInputLocal(1, index)} />

                                                    )}
                                                </div>
                                                <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>

                                                    {validationMessages[index] === 'Field cannot be empty' && validationMessages[index]}
                                                </span>
                                                {keyName === 'faq category' &&
                                                    <>
                                                        <Box className={classes.upload_container}>
                                                            <Box component="form" style={webStyle.secondaryRootBox}>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    style={webStyle.modalCardContentEditModalTypography}
                                                                >
                                                                    Upload Image
                                                                </Typography>
                                                                <FormControl
                                                                    fullWidth
                                                                    style={webStyle.modalFormControl}
                                                                >
                                                                    <InputLabel htmlFor="my-input" />
                                                                    {ImageFragment({ type: "input", index: index, attachment: items.url, file: items.url, event_type: "event" })}



                                                                    <FormHelperText error id="accountId-error">
                                                                        {handleImageErrorText("event")}
                                                                    </FormHelperText>

                                                                </FormControl>
                                                            </Box>

                                                        </Box >
                                                        <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>

                                                            {validationMessages[index] === 'Please upload an image' && validationMessages[index]}
                                                        </span>
                                                    </>
                                                }
                                                {!show &&
                                                    <div className={classes.formcontrol_container}>
                                                        {(keyName === "District" || keyName === "Village" || keyName === "Taluka") &&
                                                            <FormControl className={classes.select_formcontrol}>
                                                                <Select
                                                                    className="selectInputCustom"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    inputProps={{ "aria-label": "Select State" }}
                                                                    style={{ height: "30px", marginTop: "8px" }}
                                                                    value={selectStateValuesEnglish[index] || 0}
                                                                    onChange={(e) => handleSelectChange('state', index, e.target.value, setSelectStateValuesEnglish, selectStateValuesEnglish)}
                                                                >
                                                                    <MenuItem value={0} disabled>
                                                                        <span className={classes.opcity_48}>Select State</span>
                                                                    </MenuItem>
                                                                    {stateList?.map((ech: any) => (
                                                                        <MenuItem
                                                                            key={ech.id}
                                                                            value={ech.id}
                                                                            data-testid={`filterStateSubTestid-${ech.id}`}
                                                                        >
                                                                            {ech.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>

                                                                    {validationMessages[index] === 'Please select a state' && validationMessages[index]}
                                                                </span>
                                                            </FormControl>
                                                        }
                                                        {(keyName === "Village" || keyName === "Taluka") &&

                                                            <FormControl className={classes.select_formcontrol}>
                                                                <Select
                                                                    className="selectInputCustom"
                                                                    value={selectDistrictValuesEnglish[index] || 0}
                                                                    onChange={(e) => handleSelectChange('district', index, e.target.value, setSelectDistrictValuesEnglish, selectDistrictValuesEnglish)}
                                                                    data-testid="tableFilterDistrictInputTestid"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    displayEmpty
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                    style={{ height: "30px", marginTop: "8px" }}
                                                                >
                                                                    <MenuItem
                                                                        value={0}
                                                                        className="filterMenuDropdown"
                                                                        disabled
                                                                    >
                                                                        <span className={classes.opcity_48}>Select District</span>
                                                                    </MenuItem>
                                                                    {
                                                                        districtList?.filter((item: any) => item.state_id === selectStateValuesEnglish[index])?.map(
                                                                            (ech: any) => (
                                                                                <MenuItem
                                                                                    key={ech.id}
                                                                                    value={ech?.id}
                                                                                    data-testid={`distSubTestid-${ech.id}`}
                                                                                >
                                                                                    {ech?.name}
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                </Select>
                                                                <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>

                                                                    {validationMessages[index] === 'Please select a district' && validationMessages[index]}
                                                                </span>
                                                            </FormControl>
                                                        }
                                                        {keyName === "Village" &&

                                                            <FormControl className={classes.select_formcontrol}>
                                                                <Select
                                                                    className="selectInputCustom"
                                                                    value={selectTalukaValuesEnglish[index] || 0}
                                                                    onChange={(e) => handleSelectChange('taluka', index, e.target.value, setSelectTalukaValuesEnglish, selectTalukaValuesEnglish)}
                                                                    data-testid="tableFilterTalukaInputTestid"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                    style={{ height: "30px", marginTop: "8px" }}
                                                                >
                                                                    <MenuItem value={0} disabled>
                                                                        <span className={classes.opcity_48}>Select Taluka</span>
                                                                    </MenuItem>
                                                                    {
                                                                        talukaList?.filter((item: any) => item.district_id === selectDistrictValuesEnglish[index])?.map((ech: any) => (
                                                                            <MenuItem
                                                                                key={ech.id}
                                                                                value={ech.id}
                                                                                data-testid={`talukaSubTestid-${ech.id}`}
                                                                            >
                                                                                {ech.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                </Select>
                                                                <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>

                                                                    {validationMessages[index] === 'Please select a taluka' && validationMessages[index]}
                                                                </span>
                                                            </FormControl>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}


                                <Button
                                    variant="contained"
                                    className={classes.individualButton}
                                    onClick={() => handleSubmit()}
                                    data-testid="addUserModalBtn"
                                    disabled={loadingIndicator}
                                >
                                    {loadingIndicator ? <Loader isLoading={loadingIndicator} /> : "Update"}
                                </Button>
                            </>
                        }
                    </TabPanel>
                    <TabPanel value={activeTab} index={1} className={classes.individualWholeList}>
                        {loading ? <LoaderCenter /> :
                            <>
                                {editedData.map((item: any, index: any) => (
                                    <>
                                        <div className={classes.individualList}>
                                            <Tooltip title={getLocalizedText(item, 'hindi')}>

                                                <input
                                                    className={classes.modalTextField}
                                                    value={getLocalizedText(item, 'hindi')}
                                                    name="topic_name"
                                                    data-testid={"modal_input_topic_name"}
                                                    disabled={setDisableHindi(getLocalizedText(item, 'hindi'))}

                                                    onChange={(e: any) => setDisableHindi(getLocalizedText(item, 'hindi')) ? null : handleEditInputChange(item, index, e.target.value)}

                                                />
                                            </Tooltip>
                                            <img onClick={() => handleItemClick(item)} src={RemoveCircleOutlineIcon} width="20px" height="20px" alt='removeIcon' />
                                        </div>
                                        {keyName === 'faq category' &&
                                            <>
                                                <Box className={classes.upload_container}>
                                                    <Box component="form" style={webStyle.secondaryRootBox}>
                                                        <Typography
                                                            variant="subtitle2"
                                                            style={webStyle.modalCardContentEditModalTypography}
                                                        >
                                                            Upload Image
                                                        </Typography>
                                                        <FormControl
                                                            fullWidth
                                                            style={webStyle.modalFormControl}
                                                        >
                                                            <InputLabel htmlFor="my-input" />
                                                            {ImageFragment({ type: "api", index: index, attachment:updateEnabled?item?.image?.resized?.url: item?.image?.resized?.url + `?_=${Date.now()}`, file: item.image.file, event_type: "event" })}



                                                            <FormHelperText error id="accountId-error">
                                                                {handleImageErrorText("event")}
                                                            </FormHelperText>

                                                        </FormControl>
                                                    </Box>

                                                </Box >
                                                <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>

                                                    {validationMessages[index] === 'Please upload an image' && validationMessages[index]}
                                                </span>
                                            </>
                                        }
                                    </>
                                ))}
                                {section2InputsHindi.map((value: any, index: any) => {
                                    const items = formData[index] || { url: "", file: "" };
                                    return (
                                        <div className={classes.individualList} key={index}>
                                            <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: "5%", width: "100%" }}>
                                                    <Tooltip title={value}>

                                                        <input
                                                            type="text"
                                                            value={value}
                                                            onChange={(e) => handleInputChange(2, index, e.target.value)}
                                                            className={classes.modalTextField}
                                                            placeholder="Add another option"
                                                            name="topic_name"
                                                            data-testid={"modal_input_topic_name"}
                                                        />
                                                    </Tooltip>
                                                    {index === section2InputsHindi.length - 1 ? (
                                                        <img src={AddCircleOutlineOutlinedIcon} width="20px" height="20px" alt='removeIcon' onClick={() => handleAddInputLocal(2)} />
                                                    ) : (
                                                        <img src={RemoveCircleOutlineIcon} width="20px" height="20px" alt='removeIcon' onClick={() => handleRemoveInputLocal(2, index)} />

                                                    )}
                                                </div>
                                                <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>
                                                    {validationMessagesHindi[index] === 'Field cannot be empty' && validationMessagesHindi[index]}
                                                </span>
                                                {keyName === 'faq category' &&
                                                    <>
                                                        <Box className={classes.upload_container}>
                                                            <Box component="form" style={webStyle.secondaryRootBox}>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    style={webStyle.modalCardContentEditModalTypography}
                                                                >
                                                                    Upload Image
                                                                </Typography>
                                                                <FormControl
                                                                    fullWidth
                                                                    style={webStyle.modalFormControl}
                                                                >
                                                                    <InputLabel htmlFor="my-input" />
                                                                    {ImageFragment({ type: "input", index: index, attachment: items.url, file: items.file, event_type: "event" })}



                                                                    <FormHelperText error id="accountId-error">
                                                                        {handleImageErrorText("event")}
                                                                    </FormHelperText>

                                                                </FormControl>
                                                            </Box>

                                                        </Box >
                                                        <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>

                                                            {validationMessages[index] === 'Please upload an image' && validationMessages[index]}
                                                        </span>
                                                    </>
                                                }
                                                {!show &&
                                                    <div className={classes.formcontrol_container}>
                                                        {(keyName === "District" || keyName === "Village" || keyName === "Taluka") &&
                                                            <FormControl className={classes.select_formcontrol}>
                                                                <Select
                                                                    className="selectInputCustom"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    inputProps={{ "aria-label": "Select State" }}
                                                                    style={{ height: "30px", marginTop: "8px" }}
                                                                    value={selectStateValuesHindi[index] || 0}
                                                                    onChange={(e) => handleSelectChange('state', index, e.target.value, setSelectStateValuesHindi, selectStateValuesHindi)}
                                                                // ... other attributes
                                                                >
                                                                    <MenuItem value={0} disabled>
                                                                        <span className={classes.opcity_48}>Select State</span>
                                                                    </MenuItem>
                                                                    {stateList?.map((ech: any) => (
                                                                        <MenuItem
                                                                            key={ech.id}
                                                                            value={ech.id}
                                                                            data-testid={`filterStateSubTestid-${ech.id}`}
                                                                        >
                                                                            {ech.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                    {validationMessagesHindi[index] === 'Please select a state' && validationMessages[index]}

                                                                </Select>
                                                                <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>
                                                                    {validationMessagesHindi[index] === 'Please select a state' && validationMessages[index]}
                                                                </span>
                                                            </FormControl>
                                                        }
                                                        {(keyName === "Village" || keyName === "Taluka") &&
                                                            <FormControl className={classes.select_formcontrol}>
                                                                <Select
                                                                    className="selectInputCustom"
                                                                    value={selectDistrictValuesHindi[index] || 0}
                                                                    onChange={(e) => handleSelectChange('district', index, e.target.value, setSelectDistrictValuesHindi, selectDistrictValuesHindi)}
                                                                    data-testid="tableFilterDistrictInputTestid"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    displayEmpty
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                    style={{ height: "30px", marginTop: "8px" }}
                                                                >
                                                                    <MenuItem
                                                                        value={0}
                                                                        className="filterMenuDropdown"
                                                                        disabled
                                                                    >
                                                                        <span className={classes.opcity_48}>Select District</span>
                                                                    </MenuItem>
                                                                    {
                                                                        districtList?.filter((item: any) => item.state_id === selectStateValuesHindi[index])?.map(
                                                                            (ech: any) => (
                                                                                <MenuItem
                                                                                    key={ech.id}
                                                                                    value={ech?.id}
                                                                                    data-testid={`distSubTestid-${ech.id}`}
                                                                                >
                                                                                    {ech?.name}
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                </Select>
                                                                <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>
                                                                    {validationMessagesHindi[index] === 'Please select a district' && validationMessages[index]}
                                                                </span>
                                                            </FormControl>
                                                        }
                                                        {keyName === "Village" &&
                                                            <FormControl className={classes.select_formcontrol}>
                                                                <Select
                                                                    className="selectInputCustom"
                                                                    value={selectTalukaValuesHindi[index] || 0}
                                                                    onChange={(e) => handleSelectChange('taluka', index, e.target.value, setSelectTalukaValuesHindi, selectTalukaValuesHindi)}
                                                                    data-testid="tableFilterTalukaInputTestid"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                    style={{ height: "30px", marginTop: "8px" }}
                                                                >
                                                                    <MenuItem value={0} disabled>
                                                                        <span className={classes.opcity_48}>Select Taluka</span>
                                                                    </MenuItem>
                                                                    {
                                                                        talukaList?.filter((item: any) => item.district_id === selectDistrictValuesHindi[index])?.map((ech: any) => (
                                                                            <MenuItem
                                                                                key={ech.id}
                                                                                value={ech.id}
                                                                                data-testid={`talukaSubTestid-${ech.id}`}
                                                                            >
                                                                                {ech.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                </Select>
                                                                <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>
                                                                    {validationMessagesHindi[index] === 'Please select a taluka' && validationMessages[index]}
                                                                </span>
                                                            </FormControl>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                                <Button
                                    variant="contained"
                                    className={classes.individualButton}
                                    onClick={() => handleSubmit()}
                                    data-testid="addUserModalBtn"
                                    disabled={loadingIndicator}
                                >
                                    {loadingIndicator ? <Loader isLoading={loadingIndicator} /> : "Update"}

                                </Button>
                            </>
                        }
                    </TabPanel>
                    <TabPanel value={activeTab} index={2} className={classes.individualWholeList}>
                        {loading ? <LoaderCenter /> :
                            <>
                                {editedData.map((item: any, index: any) => (
                                    <>
                                        <div className={classes.individualList} key={item.id}>
                                            <Tooltip title={getLocalizedText(item, 'gujarati')}>

                                                <input
                                                    className={classes.modalTextField}
                                                    value={getLocalizedText(item, 'gujarati')}
                                                    name="topic_name"
                                                    data-testid={"modal_input_topic_name"}
                                                    disabled={setDisableGujrati(getLocalizedText(item, 'gujarati'))}
                                                    onChange={(e: any) => setDisableGujrati(getLocalizedText(item, 'gujarati')) ? null : handleEditInputChange(item, index, e.target.value)}

                                                />
                                            </Tooltip>
                                            <img onClick={() => handleItemClick(item)} src={RemoveCircleOutlineIcon} width="20px" height="20px" alt='removeIcon' />
                                        </div>
                                        {keyName === 'faq category' &&
                                            <>
                                                <Box className={classes.upload_container}>
                                                    <Box component="form" style={webStyle.secondaryRootBox}>
                                                        <Typography
                                                            variant="subtitle2"
                                                            style={webStyle.modalCardContentEditModalTypography}
                                                        >
                                                            Upload Image
                                                        </Typography>
                                                        <FormControl
                                                            fullWidth
                                                            style={webStyle.modalFormControl}
                                                        >
                                                            <InputLabel htmlFor="my-input" />
                                                            {ImageFragment({ type: "api", index: index, attachment:updateEnabled?item?.image?.resized?.url: item?.image?.resized?.url + `?_=${Date.now()}`, file: item.image.file, event_type: "event" })}



                                                            <FormHelperText error id="accountId-error">
                                                                {handleImageErrorText("event")}
                                                            </FormHelperText>

                                                        </FormControl>
                                                    </Box>

                                                </Box >
                                                <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>

                                                    {validationMessages[index] === 'Please upload an image' && validationMessages[index]}
                                                </span>
                                            </>
                                        }
                                    </>
                                ))}
                                {section3InputsGujrati.map((value: any, index: any) => {
                                    const items = formData[index] || { url: "", file: "" };
                                    return (
                                        <div className={classes.individualList} key={index}>
                                            <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
                                                <div style={{ display: 'flex', alignItems: 'center', gap: "5%", width: "100%" }}>
                                                    <Tooltip title={value}>

                                                        <input
                                                            type="text"
                                                            value={value}
                                                            onChange={(e) => handleInputChange(3, index, e.target.value)}
                                                            className={classes.modalTextField}
                                                            placeholder="Add another option"
                                                            name="topic_name"
                                                            data-testid={"modal_input_topic_name"}
                                                        />
                                                    </Tooltip>
                                                    {index === section3InputsGujrati.length - 1 ? (
                                                        <img src={AddCircleOutlineOutlinedIcon} width="20px" height="20px" alt='removeIcon' onClick={() => handleAddInputLocal(3)} />
                                                    ) : (
                                                        <img src={RemoveCircleOutlineIcon} width="20px" height="20px" alt='removeIcon' onClick={() => handleRemoveInputLocal(3, index)} />
                                                    )}

                                                </div>
                                                <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>
                                                    {validationMessagesGujrati[index] === 'Field cannot be empty' && validationMessagesGujrati[index]}
                                                </span>
                                                {keyName === 'faq category' &&
                                                    <>
                                                        <Box className={classes.upload_container}>
                                                            <Box component="form" style={webStyle.secondaryRootBox}>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    style={webStyle.modalCardContentEditModalTypography}
                                                                >
                                                                    Upload Image
                                                                </Typography>
                                                                <FormControl
                                                                    fullWidth
                                                                    style={webStyle.modalFormControl}
                                                                >
                                                                    <InputLabel htmlFor="my-input" />
                                                                    {ImageFragment({ type: "input", index: index, attachment: items.url, file: items.file, event_type: "event" })}



                                                                    <FormHelperText error id="accountId-error">
                                                                        {handleImageErrorText("event")}
                                                                    </FormHelperText>

                                                                </FormControl>
                                                            </Box>

                                                        </Box >
                                                        <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>

                                                            {validationMessages[index] === 'Please upload an image' && validationMessages[index]}
                                                        </span>
                                                    </>
                                                }
                                                {!show &&
                                                    <div className={classes.formcontrol_container}>
                                                        {(keyName === "District" || keyName === "Village" || keyName === "Taluka") &&
                                                            <FormControl className={classes.select_formcontrol}>
                                                                <Select
                                                                    className="selectInputCustom"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    inputProps={{ "aria-label": "Select State" }}
                                                                    style={{ height: "30px", marginTop: "8px" }}
                                                                    value={selectStateValuesGujrati[index] || 0}
                                                                    onChange={(e) => handleSelectChange('state', index, e.target.value, setSelectStateValuesGujrati, selectStateValuesGujrati)}
                                                                // ... other attributes
                                                                >
                                                                    <MenuItem value={0} disabled>
                                                                        <span className={classes.opcity_48}>Select State</span>
                                                                    </MenuItem>
                                                                    {stateList?.map((ech: any) => (
                                                                        <MenuItem
                                                                            key={ech.id}
                                                                            value={ech.id}
                                                                            data-testid={`filterStateSubTestid-${ech.id}`}
                                                                        >
                                                                            {ech.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>
                                                                    {validationMessagesGujrati[index] === 'Please select a state' && validationMessagesGujrati[index]}
                                                                </span>
                                                            </FormControl>
                                                        }
                                                        {(keyName === "Village" || keyName === "Taluka") &&
                                                            <FormControl className={classes.select_formcontrol}>
                                                                <Select
                                                                    className="selectInputCustom"
                                                                    value={selectDistrictValuesGujrati[index] || 0}
                                                                    onChange={(e) => handleSelectChange('district', index, e.target.value, setSelectDistrictValuesGujrati, selectDistrictValuesGujrati)}
                                                                    // ... other attributes
                                                                    data-testid="tableFilterDistrictInputTestid"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    displayEmpty
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                    style={{ height: "30px", marginTop: "8px" }}
                                                                >
                                                                    <MenuItem
                                                                        value={0}
                                                                        className="filterMenuDropdown"
                                                                        disabled
                                                                    >
                                                                        <span className={classes.opcity_48}>Select District</span>
                                                                    </MenuItem>
                                                                    {
                                                                        districtList?.filter((item: any) => item.state_id === selectStateValuesHindi[index])?.map(
                                                                            (ech: any) => (
                                                                                <MenuItem
                                                                                    key={ech.id}
                                                                                    value={ech?.id}
                                                                                    data-testid={`distSubTestid-${ech.id}`}
                                                                                >
                                                                                    {ech?.name}
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                </Select>
                                                                <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>
                                                                    {validationMessagesGujrati[index] === 'Please select a district' && validationMessagesGujrati[index]}
                                                                </span>
                                                            </FormControl>
                                                        }
                                                        {keyName === "Village" &&
                                                            <FormControl className={classes.select_formcontrol}>
                                                                <Select
                                                                    className="selectInputCustom"
                                                                    value={selectTalukaValuesGujrati[index] || 0}
                                                                    onChange={(e) => handleSelectChange('taluka', index, e.target.value, setSelectTalukaValuesGujrati, selectTalukaValuesGujrati)}
                                                                    // ... other attributes
                                                                    data-testid="tableFilterTalukaInputTestid"
                                                                    variant="outlined"
                                                                    color="secondary"
                                                                    inputProps={{ "aria-label": "Without label" }}
                                                                    style={{ height: "30px", marginTop: "8px" }}
                                                                >
                                                                    <MenuItem value={0} disabled>
                                                                        <span className={classes.opcity_48}>Select Taluka</span>
                                                                    </MenuItem>
                                                                    {
                                                                        talukaList?.filter((item: any) => item.district_id === selectDistrictValuesHindi[index])?.map((ech: any) => (
                                                                            <MenuItem
                                                                                key={ech.id}
                                                                                value={ech.id}
                                                                                data-testid={`talukaSubTestid-${ech.id}`}
                                                                            >
                                                                                {ech.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                </Select>
                                                                <span style={{ color: 'red', fontSize: "12px", width: "100%", padding: "2% 0 0 4%" }}>
                                                                    {validationMessagesGujrati[index] === 'Please select a taluka' && validationMessagesGujrati[index]}
                                                                </span>
                                                            </FormControl>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })}
                                <Button
                                    variant="contained"
                                    className={classes.individualButton}
                                    onClick={() => handleSubmit()}
                                    data-testid="addUserModalBtn"
                                    disabled={loadingIndicator}
                                >
                                    {loadingIndicator ? <Loader isLoading={loadingIndicator} /> : "Update"}

                                </Button>
                            </>
                        }
                    </TabPanel>

                </Box>
            </Box>            
            <CustomModal
                open={error.length > 0}
                title={errorType}
                description={error}
                onClose={closeSnackbar}
            />
        </>
    );
};

const Loader = ({ isLoading }: { isLoading: boolean }) => {
    if (isLoading)
        return <Box sx={{ display: 'flex', justifyContent: "center" }}>
            <CircularProgress
                style={{ color: "#df5200",width:"20px", height:"20px" }}
            />
        </Box>
    return <></>
}
const TabPanel = (props: any) => {
    const { children, value, index } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
            {value === index && <Box style={{ padding: '4px 16px 16px 16px' }}>{children}</Box>}
        </div>
    );
};
const webStyle = {
    lable: {
        fontSize: '14px',
        color: '#1C2324',
        opacity: 0.64,
        lineHeight: '1.5vw',
        marginBottom: '8px',
        marginTop: '16px',
    },
    hide: {
        display: "none"
    },
    badgeButton: {
        backgroundColor: "rgba(28, 35, 36, .56)",
        padding: "0.375rem",
    },
    badgeCardRoot: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#FCFAF7",
        border: "1px solid #F0EBE0",
        borderRadius: "0.75rem",
        height: '200px'
    },
    formImageContainer: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#FCFAF7",
        border: "1px solid #F0EBE0",
        borderRadius: "0.75rem",
        width: "100%",
        height: "200px"
    },
    photoCamera: {
        width: 60,
        height: 60,
        color: "#1C2324",
        opacity: .5
    },
    modalFormControl: {
        height: "100%"
    } as React.CSSProperties,
    modalCardContentEditModalTypography: {
        paddingBlockEnd: 1,
        fontSize: ".875rem",
        marginTop: "16px",
        marginBottom: "8px",
        fontWeight: 400,
        color: "#1C2324",
        opacity: 0.64
    } as React.CSSProperties,
    secondaryRootBox: { width: "100%" },
    lable1: {
        fontSize: '14px',
        color: '#1C2324',
        opacity: 0.64,
        lineHeight: '1.5vw',
        marginBottom: '0.6vw',
        marginTop: '1.5vw',
    },
    shareText: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '1.5rem',
        color: '#006A77',
    },
    secondaryUploadImageBox: { display: 'flex', gap: "1rem", },
};
const StyledBadge = styled(Badge)(() => ({
    "& .MuiBadge-badge": {
        right: "10%",
        top: "15%",
        // borderRadius: "2px",
        width: "25px",
        height: "20px",
        padding: ".8rem",
    },
}));
const useStyles = makeStyles({
    "@media (min-width: 1100px) and (max-width: 1199px)": {
        tabName: {
            fontSize: "10px !important"
        },

    },
    "@media (min-width: 1200px)": {
        tabName: {
            fontSize: "12px !important"
        },

    },
    "@media (min-width: 960px) and (max-width: 1099px)": {
        tabName: {
            fontSize: "10px !important"
        },
        fontStyles: {
            fontSize: '14px !important',
        },
    },
    select_formcontrol: {
        width: "86%",
        alignSelf: "center"
    },
    formcontrol_container: {
        display: "flex",
        flexDirection: "column",
        width: "88%"
    },
    upload_container: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    opcity_48: {
        color: "#1C2324 !important",
    },
    "@media (max-width: 400px)": {
        individualList: {
            width: "calc(100% - 2rem)"
        },
    },
    individualList: {
        gap: "1.2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    individualButton: {
        borderRadius: "28px",
        fontWeight: 700,
        lineHeight: "28px",
        display: "flex",
        alignItems: "center",
        background: "#DF5200",
        fontSize: "18px",
        color: "#fff",
        padding: "14px 32px",
        width: "100%",
        height: "40px",
        marginTop: "7%",
        boxShadow:"none"
    },
    individualWholeList: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-between",
    },

    modalTextField: {
        width: "100%",
        borderRadius: "0.75rem",
        paddingBlock: "1rem",
        paddingInline: "1.2rem",
        marginTop: "8px",
        fontSize: "1rem",
        resize: "none",
        backgroundColor: '#FCFAF7',
        border: "1px solid #F0EBE0",
        '::placeholder': {
            color: "red",
            fontWeight: 300
        },
    },
    customTab: {
        minWidth: 50,
        width: "33.3% !important",
        borderBottom:"2px solid rgba(28, 35, 36, 0.32)",
        padding:"6px 12px 2px 12px",
        minHeight:0
    },
    tabName: {
        fontSize: "12px",        
    },
    selectedTab: {
        opacity:1,
        color: '#DF5200 !important', // Set the text color for the selected tab
    },
    customIndicator: {
        backgroundColor: '#DF5200', // Set the indicator color here
    },
    root: {
        display: "flex",
        padding: "1rem 3rem 1rem 1rem",
        background: "#F8F6F1",
        borderRadius: "12px",
        marginBottom: ".5rem",
        position: "relative",
        transition: "all 2s",
        alignItems: "center",
        flexDirection: (isOpen) => (isOpen ? "column" : "row"),
    },
    rootSub: {
        display: "flex",
        borderRadius: "12px",
        marginBottom: ".2rem",
        position: "relative",
        transition: "all 2s",
        width: "100%",
        flexDirection: (isOpen) => (isOpen ? "column" : "row"),
    },
    subDropdownTitle: {
        color: "#1C2324",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "22px"
    },
    subDropdownSubTitle: {
        color: "#006A77",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "18px"
    },
    subIconContainer: {
        height: "20px",
        width: "20px"
    },
    container: {
        flexGrow: (isOpen) => (isOpen ? 0 : 0.1),
    },
    boxContainer: {
        width: "100%",
        padding: (isOpen) => (isOpen ? "0 0px" : "0 6px"),
    },
    boxContainerSub: {
        width: "98%",
        padding: (isOpen) => (isOpen ? "0 0px" : "0 6px"),
    },
    img: {
        borderRadius: "6px",
        transition: "all 0.2s",
        width: (isOpen) => (isOpen ? "100%" : "82px"),
        height: (isOpen) => (isOpen ? "180px" : "82px"),
    },
    between: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: 'center'
    },
    fontStyles: {
        fontFamily: "IBM Plex Sans",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2324",
        margin: (isOpen) => (isOpen ? "4px 0px" : "0px"),
    },
    dateContainer: {
        color: "#006A77",
        fontSize: "14px",
        padding: ".4rem 0",
    },
    dateIcon: {
        color: "#006A77",
        fontSize: "14px",
        marginRight: "5px",
    },
    collapseCardLoc: {
        fontSize: "15px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineClamp: 1,
        boxOrient: "vertical",
        width: "60px",
        height: '20px',
    },
    font15: {
        fontSize: "15px",
    },
    writerSection: {
        color: '#108B88',
    },
    btnContainer: {
        display: "flex",
        flexGrow: 0.1,
        justifyContent: "space-between",
        alignItem: "center",
        gap: "8px",
    },
    shareBtn: {
        color: "#006A77",
        fontSize: "18px",
    },
    editBtn: { color: "#EB8F06", fontSize: "18px" },
    deleteBtn: { color: "#B3261E", fontSize: "18px" },
    expandBtn: {
        position: "absolute",
        right: "10px",
    },
    expandSubBtn: {

        fontSize: "14px",
    },
    dividerAccordion: {
        marginBottom: "4px",
        marginTop: "10px",
        height: "2px",
        background: "#F0EBE0",
        width: "102%",
    },
    expandSubParent: {
        position: "absolute",
        right: "10px",
        width: "25px",
        height: "25px",
        backgroundColor: "#E4DDC9",
        borderRadius: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    location: {
        fontFamily: "IBM Plex Sans",
        fontWeight: 400,
        fontSize: "14px",
        color: "rgba(28, 35, 36, 0.8)",
        width: '100%',
        marginBottom: '8px',
    },
    boxIconsContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: (isOpen) => (isOpen ? "4px 0px" : "0px"),
    },
    boxIcons: {
        display: "flex",
        alignItems: "center",
    },
    fontSmall: {
        fontSize: "13px",
        color: '#006A77',
    },
    locationTextOverflow: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        fontSize: "13px",
        textOverflow: "ellipsis",
        lineClamp: 1,
        boxOrient: "vertical",
        width: "60px",
        height: '20px',
        color: '#1C2324',
    },
    whiteColor: {
        color: "#fff",
    },
    ellipsis: {
        overflow: "hidden",
        lineClamp: 1,
        boxOrient: "vertical",
        maxWidth: "80%",
        display: "-webkit-inline-box",
    },
    btnSection: {
        display: "flex",
        float: "right",
        gap: '10px',
        paddingTop: '7px',
        marginRight: '10px',
    },
    locationExpand: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    font18: {
        fontSize: "14px",
    },
    fw500: {
        fontWeight: 500,
    },
    dateStyleClose: {
        color: "#1C2324",
        fontWeight: 450,
        fontSize: "12px",
    },
    ellipsisNews: {
        fontWeight: 400,
        fontSize: "13px",
        // height: "64px",
        overflow: "hidden",
        maxWidth: "80%",
        textOverflow: "ellipsis",
        boxOrient: "vertical",
        lineClamp: 3,
        color: "#1c2324c7"
    },

});
export default LanguageTabs;
